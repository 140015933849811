import { useContext } from 'react'
import cx from 'classnames'

import { useGetFormattedPrice } from '@lib/helpers'
import { StringsContext } from '@lib/strings'

interface CartSubtotalProps {
  price: number
  className?: string
}

const CartSubtotal = ({ price, className }: CartSubtotalProps) => {
  const getFormattedPrice = useGetFormattedPrice()
  const strings = useContext(StringsContext)

  return (
    <div className={cx('flex justify-between', className)}>
      <span>{strings.cartSubtotal}</span>
      <span>{getFormattedPrice(price)}</span>
    </div>
  )
}

export default CartSubtotal
