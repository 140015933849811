import { HTMLAttributes, ReactNode } from 'react'
import NextLink from 'next/link'

interface SimpleLinkProps {
  href: string
  className?: string
  children?: ReactNode
}

const SimpleLink = ({
  href,
  onClick,
  onBeforeInput,
  tabIndex,
  children,
  className,
}: SimpleLinkProps & HTMLAttributes<HTMLAnchorElement>) => (
  <NextLink
    href={href}
    className={className}
    onClick={onClick}
    onBeforeInput={onBeforeInput}
    tabIndex={tabIndex}
    role="link"
  >
    {children}
  </NextLink>
)

export default SimpleLink
