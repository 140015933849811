import { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import queryString from 'query-string'

import { parseRequiredParameter } from './helpers'

export interface UrlParameter {
  name: string
  value: string | string[] | null
}

export const variantUrlParameterName = 'variant'
export const colorUrlParameterName = 'color'

/**
 * Gets and updates multiple URL parameters.
 */
export const useUrlParameters = (parameters: UrlParameter[]) => {
  const router = useRouter()

  const currentParameters = useMemo(() => {
    if (Object.keys(router.query).length === 0) {
      return parameters
    }

    // If query parameters are present, update current parameters
    return parameters.map((parameter) => {
      if (!router.query[parameter.name]) {
        return parameter
      }

      const newParameter: UrlParameter = {
        ...parameter,
        value: parseRequiredParameter(router.query[parameter.name]) ?? '',
      }

      return newParameter
    })
  }, [parameters, router.query])

  // Update query parameters on change
  const setCurrentParameters = useCallback(
    (newParameters: UrlParameter[]) => {
      // Get currently open URL
      const currentPath = router.asPath.split('?')[0]

      // Remove parameters that match initial parameters
      const filteredParameters = newParameters.filter(
        ({ name, value }) =>
          value !==
          parameters.find((parameter) => parameter.name === name)?.value
      )

      const urlParameters = filteredParameters.reduce<
        Record<string, string[] | undefined>
      >((result, { name, value }) => {
        result[name] = value && Array.isArray(value) ? value : value?.split(',')

        return result
      }, {})
      const urlQueryString = queryString.stringify(urlParameters, {
        arrayFormat: 'comma',
      })

      // Replace current URL with new parameters
      const newUrl = `${currentPath}${
        urlQueryString ? `?${urlQueryString}` : ''
      }`

      router.replace(newUrl, undefined, { shallow: true, scroll: false })
    },
    [parameters, router]
  )

  return [currentParameters, setCurrentParameters] as const
}
