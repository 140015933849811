type ColorClassMap = Record<string, string>

const colorClasses: ColorClassMap = {
  white: 'text-white',
  black: 'text-black',
  yellow: 'text-yellow',
}

interface ColoredTextProps {
  mark: Record<string, string>
  children: string[]
}

const ColoredText = ({ mark, children }: ColoredTextProps) => {
  return <span className={colorClasses[mark.color]}>{children[0]}</span>
}

ColoredText.displayName = 'ColoredText'

export default ColoredText
