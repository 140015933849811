import { Dispatch, SetStateAction, useContext, useEffect, useMemo } from 'react'

import { CartContext } from '@lib/cart'
import { compareStrings, ErrorMessages } from '@lib/helpers'
import { SchoolContext } from '@lib/school'
import { StringsContext } from '@lib/strings'

import InputSearchDropdown from '@components/input-search-dropdown'

interface SchoolFieldProps {
  errorMessages: ErrorMessages
  setErrorMessages: Dispatch<SetStateAction<ErrorMessages>>
  className?: string
}

const SchoolField = ({
  errorMessages,
  setErrorMessages,
  className,
}: SchoolFieldProps) => {
  const strings = useContext(StringsContext)

  const { shopSchoolId } = useContext(CartContext)
  const { schools, schoolId, setSchoolId } = useContext(SchoolContext)

  // Remove school ID error on school change
  useEffect(() => {
    if (!!schoolId) {
      setErrorMessages((messages) => ({
        ...messages,
        schoolId: '',
      }))
    }
  }, [setErrorMessages, schoolId])

  const schoolOptions = useMemo(
    () =>
      schools
        .map((school) => ({
          value: school.id,
          label: school.name,
        }))
        .sort((school1, school2) =>
          compareStrings(school1.label, school2.label)
        ),
    [schools]
  )

  const handleSchoolChange = (value: string) => setSchoolId(value)

  // Do not show school field if cart contains a line item from a private shop
  if (!!shopSchoolId) {
    return null
  }

  return (
    <InputSearchDropdown
      className={className}
      id="school-dropdown"
      label={strings.cartSchoolInputLabel}
      placeholder={strings.cartSchoolInputPlaceholder}
      value={schoolId}
      errorMessage={errorMessages.schoolId}
      options={schoolOptions}
      onChange={handleSchoolChange}
    />
  )
}

export default SchoolField
