import { useState, useContext, useCallback, KeyboardEvent } from 'react'
import FocusTrap from 'focus-trap-react'
import { motion } from 'framer-motion'
import cx from 'classnames'

import { SanityCart } from '@data/sanity/queries/types/site'
import { CartContext } from '@lib/cart'
import { ErrorMessages } from '@lib/helpers'
import { StringsContext } from '@lib/strings'

import Icon from '@components/icon'
import CartToggle from '@components/cart-toggle'
import DrawerBackdrop from '@components/drawer/backdrop'
import CommentField from './comment-field'
import CartEmpty from './empty'
import CartItems from './items'
import SchoolField from './school-field'
import ShippingTypeField from './shipping-type-field'
import CartSubmit from './submit'
import CartSummary from './summary'
import VatIdField from './vat-id-field'

interface CartModalProps {
  cartSettings: SanityCart
}

const CartModal = ({ cartSettings }: CartModalProps) => {
  const {
    cart,
    isCartOpen,
    isCartUpdating,
    isCartSubmitting,
    openCartInModal,
    toggleCart,
  } = useContext(CartContext)
  const strings = useContext(StringsContext)

  const [hasFocus, setHasFocus] = useState(false)
  const [vatId, setVatId] = useState('')
  const [comment, setComment] = useState('')
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})

  const handleVatIdChange = useCallback(
    (newValue: string) => setVatId(newValue),
    []
  )

  const handleCommentChange = useCallback(
    (newValue: string) => setComment(newValue),
    []
  )

  if (!openCartInModal) {
    return null
  }

  const handleKeyup = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      toggleCart(false)
    }
  }

  const lineItems = cart?.lineItems ?? []

  return (
    <>
      <FocusTrap
        active={isCartOpen && hasFocus}
        focusTrapOptions={{ allowOutsideClick: true }}
      >
        <motion.div
          initial="hide"
          animate={isCartOpen ? 'show' : 'hide'}
          variants={{
            show: { x: '0%' },
            hide: { x: '100%' },
          }}
          transition={{ duration: 0.8, ease: [0.16, 1, 0.3, 1] }}
          onKeyUp={(event) => handleKeyup(event)}
          onAnimationComplete={() => setHasFocus(isCartOpen)}
          className={cx(
            'fixed top-0 right-0 w-full h-screen max-w-3xl z-90 bg-pageBG pointer-events-none transition-visibility',
            {
              invisible: !isCartOpen,
              'pointer-events-auto transition delay-[0s]': isCartOpen,
              'cursor-wait': isCartUpdating || isCartSubmitting,
            }
          )}
        >
          <div className="flex flex-col relative h-full w-full">
            <div
              className={cx(
                'flex items-center justify-between relative px-4 py-3 sm:px-8 sm:py-6 text-sm leading-normal font-semibold with-border',
                'after:absolute after:inset-x-0 after:bottom-0 '
              )}
            >
              <p className="is-h1 font-serif">{strings.cartTitle}</p>

              <CartToggle className="uppercase flex items-center">
                <span className="mr-3 text-xl font-normal">
                  {strings.cartClose}
                </span>
                <Icon name="Cross" id="close-cart" className="text-2xl" />
              </CartToggle>
            </div>

            <div className="border-b flex-1 flex flex-col overflow-y-scroll no-scrollbar p-4 sm:p-8">
              {lineItems.length > 0 && <CartItems items={lineItems} />}

              {lineItems.length === 0 && <CartEmpty />}
            </div>

            {lineItems.length > 0 && (
              <div className="relative px-4 pb-4 sm:px-8 sm:pb-8">
                {cartSettings.showVatId && (
                  <VatIdField
                    inModal
                    errorMessages={errorMessages}
                    setErrorMessages={setErrorMessages}
                    onChange={handleVatIdChange}
                  />
                )}

                {cartSettings.showComment && (
                  <CommentField inModal onChange={handleCommentChange} />
                )}

                <SchoolField
                  className="mt-6 sm:mt-8"
                  errorMessages={errorMessages}
                  setErrorMessages={setErrorMessages}
                />

                <ShippingTypeField
                  className="mt-6 sm:mt-8"
                  hasSchoolPickup={cartSettings.shipping?.schoolPickup}
                />

                <CartSummary className="mt-6 sm:mt-8" />

                <CartSubmit
                  className="mt-6 sm:mt-8"
                  terms={cartSettings.terms}
                  storeUrl={cartSettings.storeURL}
                  setErrorMessages={setErrorMessages}
                  vatId={vatId}
                  comment={comment}
                />

                {cartSettings.message && (
                  <p className="mt-4 text-center text-sm font-semibold">
                    {cartSettings.message}
                  </p>
                )}
              </div>
            )}
          </div>
        </motion.div>
      </FocusTrap>

      <DrawerBackdrop isOpen={isCartOpen} onClick={() => toggleCart(false)} />
    </>
  )
}

export default CartModal
