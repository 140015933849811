import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useRouter } from 'next/router'

import { SanitySiteFragment } from '@data/sanity/queries/types/site'
import {
  compareNumbers,
  enumToObject,
  filterDuplicates,
  useLocalStorageState,
} from './helpers'
import { getPageUrl, PageType } from './routes'
import { SiteContext } from './site'

export enum Locale {
  DANISH = 'da',
}

export const defaultLocale = Locale.DANISH

export type LocaleValues<T> = {
  [P in Locale]: T
}

export const localeNames: LocaleValues<string> = {
  [Locale.DANISH]: 'Dansk',
}

interface LanguageContextProps {
  locale: Locale
  publicLocales: Locale[]
  changeLanguage: (newLocale: Locale) => void
}

const initialContext: LanguageContextProps = {
  locale: defaultLocale,
  publicLocales: [],
  changeLanguage: () => null,
}

export const LanguageContext =
  createContext<LanguageContextProps>(initialContext)

interface LanguageContextProviderProps {
  locale: Locale
  site: SanitySiteFragment
  children: ReactNode
}

export const LanguageContextProvider = ({
  locale,
  site,
  children,
}: LanguageContextProviderProps) => {
  const router = useRouter()
  const { toggleMegaNavigation, toggleMobileMenu } = useContext(SiteContext)

  // Handle language change
  const changeLanguage = useCallback(
    (newLocale: Locale) => {
      toggleMegaNavigation(false)
      toggleMobileMenu(false)

      if (locale !== newLocale) {
        const homeUrl = getPageUrl(PageType.HOME_PAGE)
        router.push(homeUrl, homeUrl, { locale: newLocale })
      }
    },
    [locale, router, toggleMegaNavigation, toggleMobileMenu]
  )

  return (
    <LanguageContext.Provider
      value={{
        locale,
        publicLocales: site.publicLocales as Locale[],
        changeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

/**
 * Gets all locales.
 */
export const getAllLocales = () =>
  Object.entries(enumToObject(Locale)).map((value) => value[1] as Locale)

/*  ------------------------------ */
/*  Environment variables
/*  ------------------------------ */

type EnvironmentVariableName =
  | 'SANITY_PROJECT_ID'
  | 'SANITY_PROJECT_DATASET'
  | 'SANITY_API_TOKEN'
  | 'SANITY_PREVIEW_TOKEN'
  | 'SHOPIFY_STORE_ID'
  | 'SHOPIFY_API_TOKEN'
  | 'SHOPIFY_API_PASSWORD'
  | 'SHOPIFY_WEBHOOK_INTEGRITY'
  | 'SHOPIFY_CARRIER_SERVICE_TOKEN'
  | 'RECHARGE_API_TOKEN'
  | 'REPORT_URI_SUBDOMAIN'
  | 'MAILCHIMP_API_KEY'
  | 'MAILCHIMP_SERVER'
  | 'FACEBOOK_CONVERSIONS_API_TOKEN'

type EnvironmentVariableMap = Record<
  Locale | 'default',
  Record<EnvironmentVariableName, string | undefined>
>

const environmentVariables: EnvironmentVariableMap = {
  default: {
    SANITY_PROJECT_ID: process.env.SANITY_PROJECT_ID,
    SANITY_PROJECT_DATASET: process.env.SANITY_PROJECT_DATASET,
    SANITY_API_TOKEN: process.env.SANITY_API_TOKEN,
    SANITY_PREVIEW_TOKEN: process.env.SANITY_PREVIEW_TOKEN,
    SHOPIFY_STORE_ID: process.env.SHOPIFY_STORE_ID,
    SHOPIFY_API_TOKEN: process.env.SHOPIFY_API_TOKEN,
    SHOPIFY_API_PASSWORD: process.env.SHOPIFY_API_PASSWORD,
    SHOPIFY_WEBHOOK_INTEGRITY: process.env.SHOPIFY_WEBHOOK_INTEGRITY,
    SHOPIFY_CARRIER_SERVICE_TOKEN: process.env.SHOPIFY_CARRIER_SERVICE_TOKEN,
    RECHARGE_API_TOKEN: process.env.RECHARGE_API_TOKEN,
    REPORT_URI_SUBDOMAIN: process.env.REPORT_URI_SUBDOMAIN,
    MAILCHIMP_API_KEY: process.env.MAILCHIMP_API_KEY,
    MAILCHIMP_SERVER: process.env.MAILCHIMP_SERVER,
    FACEBOOK_CONVERSIONS_API_TOKEN: process.env.FACEBOOK_CONVERSIONS_API_TOKEN,
  },
  da: {
    SANITY_PROJECT_ID: process.env.SANITY_PROJECT_ID_DA,
    SANITY_PROJECT_DATASET: process.env.SANITY_PROJECT_DATASET_DA,
    SANITY_API_TOKEN: process.env.SANITY_API_TOKEN_DA,
    SANITY_PREVIEW_TOKEN: process.env.SANITY_PREVIEW_TOKEN_DA,
    SHOPIFY_STORE_ID: process.env.SHOPIFY_STORE_ID_DA,
    SHOPIFY_API_TOKEN: process.env.SHOPIFY_API_TOKEN_DA,
    SHOPIFY_API_PASSWORD: process.env.SHOPIFY_API_PASSWORD_DA,
    SHOPIFY_WEBHOOK_INTEGRITY: process.env.SHOPIFY_WEBHOOK_INTEGRITY_DA,
    SHOPIFY_CARRIER_SERVICE_TOKEN: process.env.SHOPIFY_CARRIER_SERVICE_TOKEN_DA,
    RECHARGE_API_TOKEN: process.env.RECHARGE_API_TOKEN_DA,
    REPORT_URI_SUBDOMAIN: process.env.REPORT_URI_SUBDOMAIN_DA,
    MAILCHIMP_API_KEY: process.env.MAILCHIMP_API_KEY_DA,
    MAILCHIMP_SERVER: process.env.MAILCHIMP_SERVER_DA,
    FACEBOOK_CONVERSIONS_API_TOKEN:
      process.env.FACEBOOK_CONVERSIONS_API_TOKEN_DA,
  },
}

/**
 * Gets environment variable for specific locale.
 */
export const getLocaleVariable = (
  locale: Locale,
  key: EnvironmentVariableName
) => environmentVariables[locale]?.[key] ?? environmentVariables.default[key]

/**
 * Gets all locales that use a Shopify store.
 */
export const getShopifyStoreLocales = (shopifyStoreId: string) => {
  const locales = getAllLocales()

  return locales.filter(
    (locale) => getLocaleVariable(locale, 'SHOPIFY_STORE_ID') === shopifyStoreId
  )
}

/*  ------------------------------ */
/*  Locale detection
/*  ------------------------------ */

/**
 * Returns user's locales as IETF language tags, based on all available sources.
 * Source: https://github.com/wojtekmaj/get-user-locale
 */
const getBrowserLocales = () => {
  if (typeof window === 'undefined') {
    return [defaultLocale]
  }

  let locales: string[] = []

  if (window.navigator.languages) {
    locales = locales.concat(window.navigator.languages)
  }

  if (window.navigator.language) {
    locales.push(window.navigator.language)
  }

  if (window.navigator.userLanguage) {
    locales.push(window.navigator.userLanguage)
  }

  if (window.navigator.browserLanguage) {
    locales.push(window.navigator.browserLanguage)
  }

  if (window.navigator.systemLanguage) {
    locales.push(window.navigator.systemLanguage)
  }

  locales.push(defaultLocale)

  return locales
    .filter((locale, index, array) => filterDuplicates(locale, index, array))
    .map((locale) => getNormalizedLocale(locale))
}

/**
 * Gets normalized locale by fixing capitalization.
 */
export const getNormalizedLocale = (locale: string) => {
  if (
    !locale ||
    locale.indexOf('-') === -1 ||
    locale.toLowerCase() !== locale
  ) {
    return locale
  }

  const localeParts = locale.split('-')

  return `${localeParts[0]}-${localeParts[1].toUpperCase()}`
}

/**
 * Finds the best matching site locale from all site and browser locales.
 */
export const getMatchingSiteLocale = (
  siteLocales: Locale[],
  browserLocales: string[],
  defaultSiteLocale: Locale
) => {
  const matches = siteLocales
    .map((siteLocale) => {
      const exactIndex = browserLocales.indexOf(siteLocale)
      const partialIndex = browserLocales
        .map((locale) => locale.split('-')[0])
        .indexOf(siteLocale.split('-')[0])

      return {
        locale: siteLocale,
        index: exactIndex > -1 ? exactIndex : partialIndex,
      }
    })
    .filter(({ index }) => index > -1)
    .sort((match1, match2) => compareNumbers(match1.index, match2.index))

  if (matches.length > 0) {
    return matches[0].locale
  }

  return defaultSiteLocale
}

/**
 * Visitor's preferred locale detection hook.
 * Returns preferred locale (if it doesn't match site's locale) and a method to confirm current locale.
 */
export const usePreferredLocale = (locale: Locale) => {
  const [preferredLocale, setPreferredLocale] = useState<Locale | null>(null)
  const [browserLocales, setBrowserLocales] = useState<string[]>([])
  const [isLocaleConfirmed, setIsLocaleConfirmed] =
    useLocalStorageState<boolean>('is_locale_confirmed', false)

  // Detect browser locales
  useEffect(() => {
    if (isLocaleConfirmed) {
      return
    }

    const newBrowserLocales = getBrowserLocales()

    setBrowserLocales(newBrowserLocales)
  }, [isLocaleConfirmed])

  // Find best matching site locale based on browser locales
  useEffect(() => {
    if (isLocaleConfirmed || browserLocales.length === 0) {
      setPreferredLocale(null)

      return
    }

    const siteLocales = getAllLocales()
    const matchingSiteLocale = getMatchingSiteLocale(
      siteLocales,
      browserLocales,
      defaultLocale
    )

    // Save preferred locale if it's not already being used
    setPreferredLocale(
      matchingSiteLocale !== locale ? matchingSiteLocale : null
    )
  }, [browserLocales, isLocaleConfirmed, locale])

  const confirmLocale = useCallback(() => {
    if (!isLocaleConfirmed) {
      setIsLocaleConfirmed(true)
    }
  }, [isLocaleConfirmed, setIsLocaleConfirmed])

  return [preferredLocale, confirmLocale] as const
}

export enum CountryCode {
  GQ = 'GQ',
  AF = 'AF',
  AL = 'AL',
  DZ = 'DZ',
  AS = 'AS',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AQ = 'AQ',
  AG = 'AG',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AZ = 'AZ',
  AU = 'AU',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BA = 'BA',
  BW = 'BW',
  BV = 'BV',
  BR = 'BR',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  CI = 'CI',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  KY = 'KY',
  CL = 'CL',
  CC = 'CC',
  CO = 'CO',
  KM = 'KM',
  CG = 'CG',
  CK = 'CK',
  CR = 'CR',
  CU = 'CU',
  CY = 'CY',
  DK = 'DK',
  VI = 'VI',
  VG = 'VG',
  AE = 'AE',
  TF = 'TF',
  UM = 'UM',
  AN = 'AN',
  CF = 'CF',
  DO = 'DO',
  MK = 'MK',
  IO = 'IO',
  GB = 'GB',
  DJ = 'DJ',
  DM = 'DM',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  ER = 'ER',
  EE = 'EE',
  ET = 'ET',
  FO = 'FO',
  FK = 'FK',
  FJ = 'FJ',
  PH = 'PH',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  PF = 'PF',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  GH = 'GH',
  GI = 'GI',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GP = 'GP',
  GU = 'GU',
  GT = 'GT',
  GN = 'GN',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HM = 'HM',
  HN = 'HN',
  HK = 'HK',
  IN = 'IN',
  ID = 'ID',
  IQ = 'IQ',
  IR = 'IR',
  IE = 'IE',
  IS = 'IS',
  IL = 'IL',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  JO = 'JO',
  YU = 'YU',
  CX = 'CX',
  CV = 'CV',
  KZ = 'KZ',
  KE = 'KE',
  CN = 'CN',
  KG = 'KG',
  KI = 'KI',
  HR = 'HR',
  KW = 'KW',
  LA = 'LA',
  LS = 'LS',
  LV = 'LV',
  LB = 'LB',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MG = 'MG',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  MA = 'MA',
  MH = 'MH',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  YT = 'YT',
  MX = 'MX',
  FM = 'FM',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  MS = 'MS',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NL = 'NL',
  NP = 'NP',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  KP = 'KP',
  MP = 'MP',
  NF = 'NF',
  NO = 'NO',
  NC = 'NC',
  OM = 'OM',
  AT = 'AT',
  TL = 'TL',
  PK = 'PK',
  PW = 'PW',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PN = 'PN',
  PL = 'PL',
  PT = 'PT',
  PR = 'PR',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  ST = 'ST',
  SH = 'SH',
  KN = 'KN',
  LC = 'LC',
  PM = 'PM',
  VC = 'VC',
  SB = 'SB',
  WS = 'WS',
  SM = 'SM',
  SA = 'SA',
  CH = 'CH',
  SN = 'SN',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SK = 'SK',
  SI = 'SI',
  SO = 'SO',
  GS = 'GS',
  ES = 'ES',
  LK = 'LK',
  SD = 'SD',
  SR = 'SR',
  SJ = 'SJ',
  SE = 'SE',
  SZ = 'SZ',
  ZA = 'ZA',
  KR = 'KR',
  SY = 'SY',
  TJ = 'TJ',
  TW = 'TW',
  TZ = 'TZ',
  TD = 'TD',
  TH = 'TH',
  CZ = 'CZ',
  CD = 'CD',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TT = 'TT',
  TN = 'TN',
  TM = 'TM',
  TC = 'TC',
  TV = 'TV',
  TR = 'TR',
  DE = 'DE',
  US = 'US',
  UG = 'UG',
  UA = 'UA',
  HU = 'HU',
  UY = 'UY',
  UZ = 'UZ',
  VU = 'VU',
  VA = 'VA',
  VE = 'VE',
  EH = 'EH',
  VN = 'VN',
  WF = 'WF',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW',
}

export const defaultCountryCodes: LocaleValues<CountryCode> = {
  [Locale.DANISH]: CountryCode.DK,
}

type CountryNames = Record<CountryCode, string>

export const countryNames: LocaleValues<CountryNames> = {
  [Locale.DANISH]: {
    GQ: 'Ækvatorialguinea',
    AF: 'Afghanistan',
    AL: 'Albanien',
    DZ: 'Algeriet',
    AS: 'Amerikansk Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarktis',
    AG: 'Antigua og Barbuda',
    AR: 'Argentina',
    AM: 'Armenien',
    AW: 'Aruba',
    AZ: 'Aserbajdsjan',
    AU: 'Australien',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgien',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnien-Hercegovina',
    BW: 'Botswana',
    BV: 'Bouvetø',
    BR: 'Brasilien',
    BN: 'Brunei',
    BG: 'Bulgarien',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CI: `Côte d'Ivoire`,
    KH: 'Cambodja',
    CM: 'Cameroun',
    CA: 'Canada',
    KY: 'Caymanøerne',
    CL: 'Chile',
    CC: 'Cocosøerne (Keelingøerne)',
    CO: 'Colombia',
    KM: 'Comorerne',
    CG: 'Congo',
    CK: 'Cookøerne',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CY: 'Cypern',
    DK: 'Danmark',
    VI: 'De Amerikanske Jomfruøer',
    VG: 'De Britiske Jomfruøer',
    AE: 'De Forenede Arabiske Emirater',
    TF: 'De Franske Besiddelser i Det Sydlige Indiske Ocean',
    UM: 'De Mindre Amerikanske Oversøiske Øer',
    AN: 'De Nederlandske Antiller',
    CF: 'Den Centralafrikanske Republik',
    DO: 'Den Dominikanske Republik',
    MK: 'Den Tidligere Jugoslaviske Republik Makedonien',
    IO: 'Det Britiske Territorium i Det Indiske Ocean',
    GB: 'Det Forenede Kongerige',
    DJ: 'Djibouti',
    DM: 'Dominica',
    EC: 'Ecuador',
    EG: 'Egypten',
    SV: 'El Salvador',
    ER: 'Eritrea',
    EE: 'Estland',
    ET: 'Etiopien',
    FO: 'Færøerne (Føroyar)',
    FK: 'Falklandsøerne',
    FJ: 'Fiji',
    PH: 'Filippinerne',
    FI: 'Finland',
    FR: 'Frankrig',
    GF: 'Fransk Guyana',
    PF: 'Fransk Polynesien',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgien',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Grækenland',
    GL: 'Grønland (Kalaallit Nunaat)',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard- og McDonaldøerne',
    HN: 'Honduras',
    HK: 'Hongkong',
    IN: 'Indien',
    ID: 'Indonesien',
    IQ: 'Irak',
    IR: 'Iran',
    IE: 'Irland',
    IS: 'Island',
    IL: 'Israel',
    IT: 'Italien',
    JM: 'Jamaica',
    JP: 'Japan',
    JO: 'Jordan',
    YU: 'Jugoslavien',
    CX: 'Juleøen',
    CV: 'Kap Verde',
    KZ: 'Kasakhstan',
    KE: 'Kenya',
    CN: 'Kina',
    KG: 'Kirgisistan',
    KI: 'Kiribati',
    HR: 'Kroatien',
    KW: 'Kuwait',
    LA: 'Laos',
    LS: 'Lesotho',
    LV: 'Letland',
    LB: 'Libanon',
    LR: 'Liberia',
    LY: 'Libyen',
    LI: 'Liechtenstein',
    LT: 'Litauen',
    LU: 'Luxembourg',
    MO: 'Macao',
    MG: 'Madagaskar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldiverne',
    ML: 'Mali',
    MT: 'Malta',
    MA: 'Marokko',
    MH: 'Marshalløerne',
    MQ: 'Martinique',
    MR: 'Mauretanien',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Mikronesien',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongoliet',
    MS: 'Montserrat',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NL: 'Nederlandene',
    NP: 'Nepal',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    KP: 'Nordkorea',
    MP: 'Nordmarianerne',
    NF: 'Norfolk Island',
    NO: 'Norge',
    NC: 'Ny Kaledonien',
    OM: 'Oman',
    AT: 'Østrig',
    TL: 'Østtimor',
    PK: 'Pakistan',
    PW: 'Palau',
    PA: 'Panama',
    PG: 'Papua Ny Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PN: 'Pitcairn',
    PL: 'Polen',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Rumænien',
    RU: 'Rusland',
    RW: 'Rwanda',
    ST: 'São Tomé og Príncipe',
    SH: 'Saint Helena',
    KN: 'Saint Kitts og Nevis',
    LC: 'Saint Lucia',
    PM: 'Saint Pierre og Miquelon',
    VC: 'Saint Vincent og Grenadinerne',
    SB: 'Salomonøerne',
    WS: 'Samoa',
    SM: 'San Marino',
    SA: 'Saudi-Arabien',
    CH: 'Schweiz',
    SN: 'Senegal',
    SC: 'Seychellerne',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SK: 'Slovakiet',
    SI: 'Slovenien',
    SO: 'Somalia',
    GS: 'South Georgia og De Sydlige Sandwichøer',
    ES: 'Spanien',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Surinam',
    SJ: 'Svalbard og Jan Mayen',
    SE: 'Sverige',
    SZ: 'Swaziland',
    ZA: 'Sydafrika',
    KR: 'Sydkorea',
    SY: 'Syrien',
    TJ: 'Tadsjikistan',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    TD: 'Tchad',
    TH: 'Thailand',
    CZ: 'Tjekkiet',
    CD: 'Demokratiske Republik Congo',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad og Tobago',
    TN: 'Tunesien',
    TM: 'Turkmenistan',
    TC: 'Turks- og Caicosøerne',
    TV: 'Tuvalu',
    TR: 'Tyrkiet',
    DE: 'Tyskland',
    US: 'USA',
    UG: 'Uganda',
    UA: 'Ukraine',
    HU: 'Ungarn',
    UY: 'Uruguay',
    UZ: 'Usbekistan',
    VU: 'Vanuatu',
    VA: 'Vatikanstaten',
    VE: 'Venezuela',
    EH: 'Vestsahara',
    VN: 'Vietnam',
    WF: 'Wallis og Futunaøerne',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
}

export const englishCountryNames: CountryNames = {
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  VG: 'British Virgin Islands',
  BN: 'Brunei',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CI: `Côte d'Ivoire`,
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  HR: 'Croatia',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  CD: 'Democratic Republic of the Congo',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  TL: 'East Timor',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FO: 'Faeroe Islands',
  FK: 'Falkland Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  MK: 'Former Yugoslav Republic of Macedonia',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: 'Ireland',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: 'Laos',
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macau',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  AN: 'Netherlands Antilles',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  KP: 'North Korea',
  MP: 'Northern Marianas',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn Islands',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Réunion',
  RO: 'Romania',
  RU: 'Russia',
  RW: 'Rwanda',
  ST: 'São Tomé and Príncipe',
  SH: 'Saint Helena',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  KR: 'South Korea',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syria',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  BS: 'The Bahamas',
  GM: 'The Gambia',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  VI: 'US Virgin Islands',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UM: 'United States Minor Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VA: 'Vatican City',
  VE: 'Venezuela',
  VN: 'Vietnam',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  YU: 'Yugoslavia',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
}
