import { useState, useContext } from 'react'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'

import { SanityProductVariantFragment } from '@data/sanity/queries/types/product'
import { SanityContentBlockButtonStyle } from '@data/sanity/queries/types/content'
import { serializers } from '@lib/serializers'
import { StringsContext } from '@lib/strings'

import AddToCartButton from '@components/buttons/add-to-cart-button'
import Alert from '@components/alert'
import {
  getButtonColor,
  getButtonIconAlignment,
  getButtonSize,
  getButtonVariant,
} from '@components/buttons/button'

interface AddToCartButtonCardProps {
  node: {
    text?: string
    style: SanityContentBlockButtonStyle
    productVariant: SanityProductVariantFragment
  }
}

const AddToCartButtonCard = ({ node }: AddToCartButtonCardProps) => {
  const [isAddToCartError, setIsAddToCartError] = useState(false)
  const strings = useContext(StringsContext)

  const {
    productVariant,
    text,
    style: { variant, size, color, icon, iconAlignment, isFullWidth },
  } = node

  const handleAddToCartError = () => {
    setIsAddToCartError(true)
  }

  return (
    <>
      <AddToCartButton
        productVariant={productVariant}
        variant={getButtonVariant(variant)}
        size={getButtonSize(size)}
        color={getButtonColor(color)}
        icon={icon}
        iconAlignment={getButtonIconAlignment(iconAlignment)}
        className={cx('btn', { 'w-full': isFullWidth })}
        onAddToCartError={handleAddToCartError}
      >
        {text}
      </AddToCartButton>

      {isAddToCartError && (
        <div key="error" className="mt-2">
          <Alert error>
            <BlockContent
              renderContainerOnSingleChild
              className="rc rc-error"
              blocks={strings.cartAddToCartErrorMessage}
              serializers={serializers}
            />
          </Alert>
        </div>
      )}
    </>
  )
}

AddToCartButtonCard.displayName = 'AddToCartButtonCard'

export default AddToCartButtonCard
