import axios from 'axios'
import { DateTime } from 'luxon'
import { useRouter } from 'next/router'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  CartLineInput,
  CartLineUpdateInput,
  CheckoutCreateInput,
} from '@data/shopify/storefront/types'
import {
  SanityProductType,
  SanityProductVariantFragment,
  SanityProductVariantQuery,
} from '@data/sanity/queries/types/product'
import { SanitySiteFragment } from '@data/sanity/queries/types/site'
import { SanitySiteStrings } from '@data/sanity/queries/types/strings'
import {
  addLineItemsToShopifyCart,
  createShopifyCart,
  removeLineItemsFromShopifyCart,
  updateLineItemsInShopifyCart,
} from './shopify/graphql/cart'
import { createCheckout } from './shopify/graphql/checkout'
import { ShopifyShop } from './shopify/graphql/shop'
import {
  CartLineItemAttribute,
  getIsCartCompleted,
  getShippingTypeAttributes,
  getTaxCartAttributes,
  parseShopifyCart,
  validateLineItemShippingTypeAttributes,
} from './shopify/cart'
import {
  getCheckoutCartAttributes,
  getCheckoutNote,
  getCheckoutShippingAddress,
  parseCheckoutLineItems,
} from './shopify/checkout'
import { getShopifyGlobalId } from './shopify/client'
import {
  getSchoolAttributes,
  validateLineItemSchoolAttributes,
} from './shopify/school'
import { getProductVariants, shippableProductTypes } from './sanity/product'
import { getSchoolById } from './sanity/school'
import { getCheckoutUrlStorageKey } from './checkout'
import { DiscountContext } from './discount'
import { triggerAddToCartFacebookEvent } from './facebook'
import { ErrorMessages, useLocalStorageState } from './helpers'
import { LanguageContext, Locale } from './language'
import { getPartnerAdsAttributes } from './partner-ads'
import { getPageUrl, PageType } from './routes'
import { SchoolContext } from './school'
import { ShopContext } from './shop'
import { StringsContext } from './strings'

import { ValidateVatIdResult } from '@pages/api/tax/validate-vat-id'

export enum ShippingType {
  PICKUP_SCHOOL = 'school-pickup',
  SHIP_TO_ADDRESS = 'ship-to-address',
}

export interface CartFormValues {
  vatId: string
  comment: string
  schoolId: string
}

interface ValidateCartProps {
  errors: ErrorMessages
  vatIdCountryCode: string | null
}

interface CartTotals {
  subTotal: number
  totalDiscount: number
  total: number
}

export interface LineItemAttribute {
  key: string
  value: string
}

export interface LineItem extends SanityProductVariantQuery {
  lineId: string
  merchandiseId: string
  quantity: number
  attributes?: LineItemAttribute[]
}

export interface VariantLineItem {
  id: number
  price: number
  quantity: number
}

export interface CartLineItem {
  id: number
  quantity: number
  attributes?: LineItemAttribute[]
}

interface CartUpdateLineItem {
  id: string
  quantity: number
  attributes?: LineItemAttribute[]
}

interface AutomaticDiscount {
  title: string
  amount: number
}

export interface Cart {
  id: string
  webUrl: string
  note: string
  lineItems: LineItem[]
  subTotal: number
  total: number
  automaticDiscount: AutomaticDiscount
  discountCodes: string[]
}

export interface CartDiscountUpdateResponse {
  error?: string
  cart?: Cart
}

interface SubmitCartResult {
  errors: ErrorMessages
  checkoutUrl: string
}

interface CartContextProps {
  cart: Cart
  isCartOpen: boolean
  isCartUpdating: boolean
  isCartProductAdding: boolean
  isCartSubmitting: boolean
  shopSchoolId: string | null
  shippingType: ShippingType
  toggleCart: (newState: boolean) => void
  addItemsToCart: (
    getVariantLineItems: () => Promise<CartLineItem[]>
  ) => Promise<boolean>
  updateCartItems: (variantLineItems: CartUpdateLineItem[]) => Promise<boolean>
  removeItemsFromCart: (ids: string[]) => Promise<boolean>
  submitCart: (values: CartFormValues) => Promise<SubmitCartResult>
  setShippingType: Dispatch<SetStateAction<ShippingType>>
  openCartInModal?: boolean
}

const emptyCart: Cart = {
  id: '',
  webUrl: '',
  note: '',
  lineItems: [],
  subTotal: 0,
  total: 0,
  automaticDiscount: {
    title: '',
    amount: 0,
  },
  discountCodes: [],
}

interface CartContextProviderProps {
  site: SanitySiteFragment
  shop: ShopifyShop | null
  children: ReactNode
}

export const CartContext = createContext<CartContextProps>({
  cart: emptyCart,
  isCartOpen: false,
  isCartUpdating: false,
  isCartProductAdding: false,
  isCartSubmitting: false,
  shopSchoolId: null,
  shippingType: ShippingType.SHIP_TO_ADDRESS,
  toggleCart: () => null,
  addItemsToCart: async () => false,
  updateCartItems: async () => false,
  removeItemsFromCart: async () => false,
  submitCart: async () => ({
    errors: {},
    checkoutUrl: '',
  }),
  setShippingType: () => null,
})

/**
 * Validates cart form.
 */
const validateCart = async (
  strings: SanitySiteStrings,
  values: CartFormValues
) => {
  const results: ValidateCartProps = {
    errors: {},
    vatIdCountryCode: null,
  }

  if (values.vatId) {
    const validationResult = await validateVatId(values.vatId)
    results.vatIdCountryCode = validationResult?.countryCode ?? null

    if (!validationResult?.isValid) {
      results.errors.vatId = !validationResult
        ? strings.cartVatIdError
        : strings.cartInvalidVatIdError
    }
  }

  // Require school
  if (!values.schoolId) {
    results.errors.schoolId = strings.cartSchoolIdError
  }

  return results
}

/**
 * Calls API route that handles cart discount updating.
 */
const updateCartDiscount = async (
  locale: Locale,
  cartId: string
): Promise<Cart | undefined> => {
  try {
    const discountUpdateResult = await axios.get<CartDiscountUpdateResponse>(
      '/api/shopify/cart-discount-update',
      {
        params: {
          cart_id: cartId,
        },
        headers: {
          'Content-Type': 'application/json',
          'X-Locale': locale,
        },
      }
    )

    if (discountUpdateResult.data?.error || !discountUpdateResult.data?.cart) {
      throw new Error(discountUpdateResult.data.error ?? 'Unknown error')
    }

    return discountUpdateResult.data.cart
  } catch (error) {
    console.log(error)
  }
}

/**
 * Gets cart ID local storage key.
 */
export const getCartIdStorageKey = (locale: Locale) => `cart_id_${locale}`

/**
 * Gets shipping type local storage key.
 */
export const getShippingTypeStorageKey = (locale: Locale) =>
  `shipping_type_${locale}`

/**
 * The cart context provider.
 */
export const CartContextProvider = ({
  site,
  shop,
  children,
}: CartContextProviderProps) => {
  const router = useRouter()
  const { locale } = useContext(LanguageContext)
  const { countryCode, shopifyStorefrontClient } = useContext(ShopContext)
  const strings = useContext(StringsContext)
  const { schoolId, schools } = useContext(SchoolContext)

  const [cart, setCart] = useState<Cart>(emptyCart)
  const [openCartInModal, setOpenCartInModal] = useState(
    !site.cart?.openInSeparatePage
  )
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [isCartUpdating, setIsCartUpdating] = useState(false)
  const [isCartProductAdding, setIsCartProductAdding] = useState(false)
  const [isCartSubmitting, setIsCartSubmitting] = useState(false)
  const [localeInitialised, setLocaleInitialised] = useState('')
  const [shippingType, setShippingType] = useLocalStorageState<ShippingType>(
    getShippingTypeStorageKey(locale),
    site.cart.shipping?.schoolPickup
      ? ShippingType.PICKUP_SCHOOL
      : ShippingType.SHIP_TO_ADDRESS
  )

  const deliveryDate =
    site.cart.shipping?.orderProcessingDay &&
    site.cart.shipping?.deliveryTimeInDays
      ? getEstimatedDeliveryDate(
          site.cart.shipping?.orderProcessingDay,
          site.cart.shipping?.deliveryTimeInDays
        ).toFormat('dd/MM/yyyy')
      : null

  const shopSchoolId = useMemo<string | null>(() => {
    const shopSchoolIds = cart.lineItems
      // Filter line items that are created from a private shop
      .filter(
        ({ attributes }) =>
          !!attributes?.find(
            (attribute) => attribute.key === `_${CartLineItemAttribute.SHOP_ID}`
          )?.value
      )
      // Map line items to school IDs from attributes
      .map(
        ({ attributes }) =>
          attributes?.find(
            (attribute) =>
              attribute.key === `_${CartLineItemAttribute.SCHOOL_ID}`
          )?.value ?? null
      )
      .filter(Boolean) as string[]

    return shopSchoolIds[0] ?? null
  }, [cart.lineItems])

  // Update callbacks
  const saveCart = useCallback((locale: Locale, cart?: Cart) => {
    if (!cart) {
      return
    }

    setCart(cart)

    if (typeof window !== `undefined`) {
      localStorage.setItem(getCartIdStorageKey(locale), cart.id)
    }
  }, [])

  const toggleCart = useCallback(
    (newState: boolean) => {
      if (!openCartInModal) {
        if (newState) {
          router.push(getPageUrl(PageType.CART_PAGE))
        }

        return
      }

      if (isCartOpen !== newState) {
        setIsCartOpen(newState)
      }
    },
    [isCartOpen, openCartInModal, router]
  )

  const addItemsToCart = useCallback(
    async (
      getVariantLineItems: () => Promise<CartLineItem[]>
    ): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setIsCartProductAdding(true)
      setIsCartUpdating(true)

      // Get variant details
      const variantLineItems = await getVariantLineItems()
      const variantIds = variantLineItems.map(({ id }) => id)
      const productVariants = await getProductVariants(locale, variantIds)

      const cartPartnerAdsAttributes = getPartnerAdsAttributes(true)
      const lines: CartLineInput[] = variantLineItems.map(
        ({ id, quantity, attributes }) => {
          const variant = productVariants.find((variant) => variant.id === id)

          return {
            merchandiseId: getShopifyGlobalId('ProductVariant', id),
            sellingPlanId: variant?.sellingPlanId
              ? getShopifyGlobalId('SellingPlan', variant.sellingPlanId)
              : null,
            quantity,
            attributes: [...(attributes ?? []), ...cartPartnerAdsAttributes],
          }
        }
      )
      const cartResponse = await addLineItemsToShopifyCart(
        shopifyStorefrontClient,
        cart.id,
        lines
      )

      if (cartResponse.error) {
        return false
      }

      if (site.settings.facebookEvents) {
        productVariants.forEach(async (variant) => {
          await triggerAddToCartFacebookEvent(locale, variant)
        })
      }

      // Update cart discount codes
      const newCart = await updateCartDiscount(locale, cart.id)

      if (!newCart) {
        return false
      }

      saveCart(locale, newCart)

      setIsCartProductAdding(false)
      setIsCartUpdating(false)
      toggleCart(false)

      return !!newCart
    },
    [
      cart.id,
      locale,
      saveCart,
      shopifyStorefrontClient,
      site.settings.facebookEvents,
      toggleCart,
    ]
  )

  const updateCartItems = useCallback(
    async (variantLineItems: CartUpdateLineItem[]): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setIsCartUpdating(true)

      // Update cart line items
      const cartPartnerAdsAttributes = getPartnerAdsAttributes(true)
      const lines: CartLineUpdateInput[] = variantLineItems.map(
        ({ id, quantity, attributes }) => ({
          id,
          quantity,
          attributes: [...(attributes ?? []), ...cartPartnerAdsAttributes],
        })
      )
      const cartResponse = await updateLineItemsInShopifyCart(
        shopifyStorefrontClient,
        cart.id,
        lines
      )

      if (cartResponse.error) {
        return false
      }

      // Update cart discount codes
      const newCart = await updateCartDiscount(locale, cart.id)

      if (!newCart) {
        return false
      }

      saveCart(locale, newCart)

      setIsCartUpdating(false)

      return !!newCart
    },
    [cart.id, locale, saveCart, shopifyStorefrontClient]
  )

  const removeItemsFromCart = useCallback(
    async (ids: string[]): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setIsCartUpdating(true)

      // Remove line item from Shopify cart
      const cartResponse = await removeLineItemsFromShopifyCart(
        shopifyStorefrontClient,
        cart.id,
        ids
      )

      if (cartResponse.error) {
        return false
      }

      // Update cart discount codes
      const newCart = await updateCartDiscount(locale, cart.id)

      if (!newCart) {
        return false
      }

      saveCart(locale, newCart)

      setIsCartUpdating(false)

      return !!newCart
    },
    [cart.id, locale, saveCart, shopifyStorefrontClient]
  )

  const submitCart = useCallback(
    async (values: CartFormValues): Promise<SubmitCartResult> => {
      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setIsCartSubmitting(true)

      // Validate cart form
      const { errors, vatIdCountryCode } = await validateCart(strings, values)
      const errorList = Object.entries(errors)

      if (errorList.length > 0) {
        setIsCartSubmitting(false)
        return {
          errors,
          checkoutUrl: '',
        }
      }

      const currentSchoolId = shopSchoolId ?? schoolId
      const school = await getSchoolById(currentSchoolId)

      // Get checkout note
      const checkoutNote = `${getCheckoutNote(strings, values.comment, school)}
      Planned delivery: ${deliveryDate}`

      // Get checkout attributes
      const partnerAdsAttributes = getPartnerAdsAttributes()
      const taxAttributes = getTaxCartAttributes(
        values,
        vatIdCountryCode !== countryCode
      )
      const checkoutCartAttributes = getCheckoutCartAttributes(cart.id)
      const checkoutAttributes = [
        ...partnerAdsAttributes,
        ...taxAttributes,
        ...checkoutCartAttributes,
      ]

      // Create new checkout
      const checkoutInput: CheckoutCreateInput = {
        allowPartialAddresses: true,
        customAttributes: checkoutAttributes,
        lineItems: await parseCheckoutLineItems(
          locale,
          strings,
          cart.lineItems
        ),
        note: checkoutNote,
      }

      // Get checkout shipping address
      const shippingAddress = getCheckoutShippingAddress(
        locale,
        cart.lineItems,
        shippingType,
        school
      )

      if (shippingAddress) {
        checkoutInput.shippingAddress = shippingAddress
      }

      const createCheckoutResult = await createCheckout(
        shopifyStorefrontClient,
        checkoutInput
      )
      const checkoutUserErrors =
        createCheckoutResult?.checkoutCreate?.checkoutUserErrors ?? []

      setIsCartSubmitting(false)

      if (checkoutUserErrors.length > 0) {
        return {
          errors: {
            checkout: checkoutUserErrors[0].message,
          },
          checkoutUrl: '',
        }
      }

      return {
        errors,
        checkoutUrl:
          createCheckoutResult?.checkoutCreate?.checkout?.webUrl ?? '',
      }
    },
    [
      cart.id,
      cart.lineItems,
      countryCode,
      locale,
      schoolId,
      shippingType,
      shopifyStorefrontClient,
      shopSchoolId,
      strings,
      deliveryDate,
    ]
  )

  // Load initial cart from Shopify
  useEffect(() => {
    if (!shop || localeInitialised === locale) {
      return
    }

    if (!shopifyStorefrontClient) {
      throw new Error('Shopify Storefront API client missing')
    }

    setLocaleInitialised(locale)

    const loadCart = async () => {
      const shopCart = 'cart' in shop && shop.cart ? shop.cart : null
      const isCartCompleted = getIsCartCompleted(shopCart)

      if (shopCart && !isCartCompleted) {
        const currentCart = await parseShopifyCart(locale, shopCart)

        if (currentCart) {
          saveCart(locale, currentCart)
          return
        }
      }

      // Delete saved cart and checkout URL, if cart was not found or is completed
      localStorage.removeItem(getCartIdStorageKey(locale))
      localStorage.removeItem(getCheckoutUrlStorageKey(locale))

      // Create a new cart
      const createShopifyCartResponse = await createShopifyCart(
        shopifyStorefrontClient,
        {
          note: '',
        }
      )
      const createdCart = await parseShopifyCart(
        locale,
        createShopifyCartResponse.cart
      )

      saveCart(locale, createdCart)
    }
    loadCart()
  }, [locale, localeInitialised, saveCart, shop, shopifyStorefrontClient])

  // Load cart settings (update when switching language)
  useEffect(
    () => setOpenCartInModal(!site.cart?.openInSeparatePage),
    [site.cart?.openInSeparatePage]
  )

  // Validate and update cart line item attributes
  useEffect(() => {
    // Wait for cart and line items to load
    if (!cart.id || !cart.lineItems || cart.lineItems.length === 0) {
      return
    }

    // Validate line item school IDs and school names
    const currentSchoolId = shopSchoolId ?? schoolId
    const isSchoolValid =
      !currentSchoolId ||
      validateLineItemSchoolAttributes(cart.lineItems, currentSchoolId)

    // Validate line item shipping types
    const isShippingTypeValid = validateLineItemShippingTypeAttributes(
      cart.lineItems,
      shippingType
    )

    if (isSchoolValid && isShippingTypeValid) {
      return
    }

    // Get new school attributes
    const school = schools.find(({ id }) => id === currentSchoolId)
    const schoolAttributes = school
      ? getSchoolAttributes(school.id, school.name)
      : []

    // Get new shipping type attributes
    const lineItemProductTypes = cart.lineItems.map(
      ({ product }) => product.type
    )
    const hasShippableProducts = lineItemProductTypes.some((productType) =>
      shippableProductTypes.includes(productType)
    )
    const shippingTypeAttributes = getShippingTypeAttributes(shippingType)

    // Get new attributes and their keys
    // Note: do not add shipping type attribute value, if cart has no shippable products, but do add its key to remove
    // it from current attributes.
    const newAttributes = [
      ...schoolAttributes,
      ...shippingTypeAttributes,
    ].filter(
      ({ key }) =>
        hasShippableProducts ||
        key !== `_${CartLineItemAttribute.SHIPPING_TYPE}`
    )
    const newAttributesKeys = [
      ...schoolAttributes,
      ...shippingTypeAttributes,
    ].map(({ key }) => key)

    // Replace line item attributes
    const newLineItems: CartUpdateLineItem[] = cart.lineItems.map(
      ({ lineId, quantity, attributes }) => {
        // Get current attributes without new attribute keys
        const currentAttributes =
          attributes?.filter(({ key }) => !newAttributesKeys.includes(key)) ??
          []

        return {
          id: lineId,
          quantity,
          attributes: [...currentAttributes, ...newAttributes],
        }
      }
    )

    updateCartItems(newLineItems)
  }, [cart, schoolId, schools, shippingType, shopSchoolId, updateCartItems])

  return (
    <CartContext.Provider
      value={{
        cart,
        isCartOpen,
        isCartUpdating,
        isCartProductAdding,
        isCartSubmitting,
        shopSchoolId,
        shippingType,
        toggleCart,
        addItemsToCart,
        updateCartItems,
        removeItemsFromCart,
        submitCart,
        setShippingType,
        openCartInModal,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

/**
 * Returns cart item count.
 */
export const useCartItemCount = () => {
  const { cart } = useContext(CartContext)

  return useMemo(
    () =>
      cart?.lineItems
        ?.filter(({ product }) => product.type !== SanityProductType.FEE)
        ?.reduce((total, { quantity }) => total + quantity, 0) ?? 0,
    [cart?.lineItems]
  )
}

/**
 * Returns cart totals.
 */
export const useCartTotals = (): CartTotals => {
  const { cart } = useContext(CartContext)
  const { cartDiscountItems } = useContext(DiscountContext)

  return useMemo(
    () => ({
      subTotal: cart?.subTotal ?? 0,
      totalDiscount:
        cartDiscountItems?.reduce(
          (total, { amount, quantity }) => total + amount * quantity,
          0
        ) ?? 0,
      total: cart?.total ?? 0,
    }),
    [cart?.subTotal, cart?.total, cartDiscountItems]
  )
}

/**
 * Validates VAT ID using API route.
 */
export const validateVatId = async (vatId: CartFormValues['vatId']) => {
  try {
    const validationResult = await axios.get<ValidateVatIdResult>(
      '/api/tax/validate-vat-id',
      {
        params: {
          id: vatId,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    return validationResult.data
  } catch (_) {
    return
  }
}

/**
 * Get fee product cart line items.
 */
export const getFeeLineItems = (
  productVariant: SanityProductVariantFragment,
  quantity: number
): CartLineItem[] =>
  productVariant.fees?.map((fee) => ({
    id: fee.id,
    quantity,
    attributes: [
      {
        key: `_${CartLineItemAttribute.PRODUCT_VARIANT_ID}`,
        value: productVariant.id.toString(),
      },
    ],
  })) ?? []

/**
 * Returns boolean whether given attributes exist
 */
export const lineItemAttributesExist = (
  lineItem: CartLineItem,
  attributeKeys: CartLineItemAttribute[]
) => {
  return attributeKeys.every((attributeKey) =>
    lineItem.attributes?.map(({ key }) => key).includes(`_${attributeKey}`)
  )
}

/**
 * Get order processing date based on current day and order processing day.
 */
export const getOrderProcessingDate = (orderProcessingDay: number) => {
  const now = DateTime.now()

  const orderProcessingDate =
    now.weekday >= orderProcessingDay
      ? now
          .plus({
            days: 7,
          })
          .set({
            weekday: orderProcessingDay,
          }) // Next week's order processing date
      : now.set({
          weekday: orderProcessingDay,
        }) // Current week's order processing date

  return orderProcessingDate
}

/**
 * Get last order date based on order processing date.
 */
export const getLastOrderDate = (orderProcessingDate: DateTime) =>
  orderProcessingDate.minus({
    days: 1,
  })

/**
 * Get estimated delivery date based on the order processing day and delivery time in days.
 */
export const getEstimatedDeliveryDate = (
  orderProcessingDay: number,
  deliveryTimeInDays: number
) => {
  const orderProcessingDate = getOrderProcessingDate(orderProcessingDay)
  // TODO: Exclude weekends?
  const deliveryDate = orderProcessingDate.plus({
    days: deliveryTimeInDays,
  })

  return deliveryDate
}
