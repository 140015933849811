import { useCallback, useContext, useMemo } from 'react'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'

import { CartContext, LineItem, lineItemAttributesExist } from '@lib/cart'
import { hasObject } from '@lib/helpers'
import { getPageUrl, PageType } from '@lib/routes'
import { StringsContext } from '@lib/strings'
import { serializers } from '@lib/serializers'

import Icon from '@components/icon'
import Photo from '@components/photo'
import ProductPrice from '@blocks/product/product-price'
import ProductCounter from '@blocks/product/product-counter'
import SimpleLink from '@components/simple-link'
import { CartLineItemAttribute } from '@lib/shopify/cart'

interface CartItemProps {
  item: LineItem
  feeItems?: LineItem[]
  className?: string
}

const freetextAttributeKeys = [
  CartLineItemAttribute.FREETEXT,
  CartLineItemAttribute.FREETEXT_COLOR,
]

const assetAttributeKeys = [
  CartLineItemAttribute.LOGO_ASSET_TITLE,
  CartLineItemAttribute.IMAGE_ASSET_TITLE,
]

const CartItem = ({ item, feeItems, className }: CartItemProps) => {
  const strings = useContext(StringsContext)
  const { toggleCart, updateCartItems, removeItemsFromCart } =
    useContext(CartContext)

  const productUrl = getPageUrl(PageType.PRODUCT, item.product.slug, {
    parameters: { variant: `${item.id}` },
  })

  const photo = useMemo(() => {
    const defaultPhoto = item.photos.cart?.find(({ forOption }) => !forOption)
    const variantPhoto = item.photos.cart?.find(({ forOption }) => {
      if (!forOption) {
        return false
      }

      const option = {
        name: forOption.split(':')[0],
        value: forOption.split(':')[1],
      }

      return option.value && hasObject(item.options, option)
    })

    return variantPhoto ?? defaultPhoto
  }, [item.options, item.photos.cart])

  const changeQuantity = useCallback(
    async (quantity: number) =>
      await updateCartItems([
        {
          id: item.lineId,
          quantity,
          attributes: item.attributes,
        },
        ...(feeItems?.map((feeItem) => ({
          id: feeItem.lineId,
          quantity,
          attributes: feeItem.attributes,
        })) ?? []),
      ]),
    [item.lineId, item.attributes, updateCartItems, feeItems]
  )

  const handleRemove = () => {
    removeItemsFromCart([
      item.lineId,
      ...(feeItems?.map((feeItem) => feeItem.lineId) ?? []),
    ])
  }

  return (
    <div className={cx('flex relative', className)}>
      {photo && (
        <Photo
          image={photo.default}
          layout="responsive"
          sizes="(min-width: 768px) 400px, 35vw"
          className="flex-shrink-0 relative m-0 w-1/4 sm:w-1/3 max-w-[350px] mr-4"
        />
      )}

      <div className="flex flex-col flex-grow justify-between">
        <div className="mb-6">
          <div className="mb-4 flex flex-col xs:flex-row flex-wrap justify-between">
            <div className="mb-1 mr-4">
              <h4 className="font-semibold">
                <SimpleLink
                  href={productUrl}
                  className="block text-current after:block after:absolute after:inset-0 after:z-10"
                  onClick={() => toggleCart(false)}
                  onBeforeInput={() => toggleCart(false)}
                  tabIndex={0}
                  role="link"
                >
                  {item.product.title}
                </SimpleLink>
              </h4>
              <p className="uppercase">{item.title}</p>
              {item.product.cartDescription && (
                <div className="mt-2">
                  <BlockContent
                    renderContainerOnSingleChild
                    className="rc rc-cart-item-description"
                    blocks={item.product.cartDescription}
                    serializers={serializers}
                  />
                </div>
              )}
            </div>
            <ProductPrice price={item.price} inCartItem />
          </div>

          <div>
            {feeItems?.map((feeItem, index) => (
              <div
                key={`${index}-${item.id}-${feeItem.id}`}
                className="flex flex-col xs:flex-row flex-wrap justify-between mb-2"
              >
                <div className="mr-2">
                  <p className="text-lg">{feeItem.title}</p>

                  {lineItemAttributesExist(feeItem, freetextAttributeKeys) && (
                    <p className="text-sm">
                      {freetextAttributeKeys
                        .map(
                          (key) =>
                            feeItem.attributes?.find(
                              (attribute) => attribute.key === `_${key}`
                            )?.value
                        )
                        .join(' / ')}
                    </p>
                  )}

                  {assetAttributeKeys.map((key) => {
                    return lineItemAttributesExist(feeItem, [key]) ? (
                      <p className="text-sm" key={`${feeItem.id}${key}`}>
                        {
                          feeItem.attributes?.find(
                            (attribute) => attribute.key === `_${key}`
                          )?.value
                        }
                      </p>
                    ) : null
                  })}
                </div>
                <ProductPrice price={feeItem.price} className="text-lg" />
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-center relative z-10 justify-between mb-14">
          <ProductCounter
            id={`${item.id}`}
            defaultCount={item.quantity}
            onUpdate={changeQuantity}
            className="mr-6"
          />
          <button
            onClick={handleRemove}
            className="text-base md:text-lg text-red inline-flex items-center"
          >
            <Icon
              id="delete-icon"
              name="Trash"
              className="text-lg md:text-2xl mr-1"
            />
            {strings.buttonRemove}
          </button>
        </div>
      </div>
    </div>
  )
}

export default CartItem
