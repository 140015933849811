import { LineItem } from '@lib/cart'

import { SanityProductType } from '@data/sanity/queries/types/product'
import { CartLineItemAttribute } from '@lib/shopify/cart'

import CartItem from '@blocks/shop/cart-item'

interface CartItemsProps {
  items: LineItem[]
  itemClassName?: string
}

const CartItems = ({ items, itemClassName }: CartItemsProps) => {
  const feeItems = items.filter(
    (item) => item.product.type === SanityProductType.FEE
  )

  return (
    <div>
      {items
        .filter((item) => item.product.type !== SanityProductType.FEE)
        .map((item, i) => (
          <CartItem
            key={`${i}-${item.id}`}
            item={item}
            feeItems={feeItems.filter((feeItem) => {
              if (!feeItem.attributes) {
                return false
              }

              return feeItem.attributes.some(
                ({ key, value }) =>
                  key.includes(CartLineItemAttribute.PRODUCT_VARIANT_ID) &&
                  value === item.id.toString()
              )
            })}
            className={itemClassName}
          />
        ))}
    </div>
  )
}

export default CartItems
