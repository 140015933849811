import {
  getProductPageQuery,
  getPrintProductPageQuery,
  productCatalogueQuery,
  productVariantQuery,
  productVariantsQuery,
} from '@data/sanity/queries/product'
import {
  SanityLocaleQueryParameter,
  SanityShopifyProductVariantIdQueryParameter,
  SanityShopifyProductVariantIdsQueryParameter,
  SanitySlugQueryParameter,
} from '@data/sanity/queries/types/parameters'
import {
  SanityProductCatalogueQuery,
  SanityProductPageQuery,
  SanityPrintProductPageQuery,
  SanityProductVariantQuery,
  SanityProductVariantsQuery,
  SanityProductType,
} from '@data/sanity/queries/types/product'
import { fetchQuery, Preview } from './client'
import { Locale } from '@lib/language'

export const basicProductTypes = [
  SanityProductType.BASIC,
  SanityProductType.BASIC_DIGITAL,
]

export const shippableProductTypes = [
  SanityProductType.BASIC,
  SanityProductType.PRINT,
]

/**
 * Fetches product page data based on product slug.
 */
export const getProductPage = async (
  locale: Locale,
  slug: string,
  preview: Preview
) =>
  fetchQuery<
    SanityProductPageQuery,
    SanitySlugQueryParameter & SanityLocaleQueryParameter
  >(getProductPageQuery(preview), { slug, locale }, preview)

/**
 * Fetches print product page data based on product slug and shop id.
 */
export const getPrintProductPage = async (
  locale: Locale,
  shopId: string,
  slug: string,
  preview: Preview
) =>
  fetchQuery<
    SanityPrintProductPageQuery,
    SanitySlugQueryParameter & SanityLocaleQueryParameter
  >(getPrintProductPageQuery(preview, shopId), { slug, locale }, preview)

/**
 * Fetches a product variant based on its ID.
 */
export const getProductVariant = async (
  locale: Locale,
  shopifyProductVariantId: number
) =>
  fetchQuery<
    SanityProductVariantQuery | null,
    SanityLocaleQueryParameter & SanityShopifyProductVariantIdQueryParameter
  >(productVariantQuery, { locale, shopifyProductVariantId })

/**
 * Fetches product variants based on a list of ID.
 */
export const getProductVariants = async (
  locale: Locale,
  shopifyProductVariantIds: number[]
) =>
  fetchQuery<
    SanityProductVariantsQuery,
    SanityLocaleQueryParameter & SanityShopifyProductVariantIdsQueryParameter
  >(productVariantsQuery, { locale, shopifyProductVariantIds })

/**
 * Gets all product catalogue.
 */
export const getProductCatalogue = async (locale: Locale) =>
  fetchQuery<SanityProductCatalogueQuery, SanityLocaleQueryParameter>(
    productCatalogueQuery,
    { locale }
  )
