import groq from 'groq'

import { imageFragment } from './image'

const schoolPrintTypesFragment = groq`
  logo,
  back,
  freetext,
`

const schoolPermissionFragment = groq`
  apparel,
  yearbook,
  festival,
  teamapparel,
  merch,
  photobook,
`

export const schoolPrintFragment = groq`
  title,
  type,
  printSku,
  printColor,
  "printImage": print {
    ${imageFragment}
  },
`

export const schoolFragment = groq`
  "slug": slug.current,
  name,
  id,
  address,
  city,
  postalCode,
  description,
  domain,
  email,
  phone,
  grades,
  numberOfStudents,
  prints[] {
    ${schoolPrintFragment}
  },
  permission {
    ${schoolPermissionFragment}
  },
  unavailablePrintTypes {
    ${schoolPrintTypesFragment}
  },
`

export const schoolItemFragment = groq`
  "id": _id,
  name,
  unavailablePrintTypes {
    ${schoolPrintTypesFragment}
  },
`

export const getSchoolByIdQuery = groq`
  *[_type == "school" && _id == $id][0] {
    ${schoolFragment}
  }
`
