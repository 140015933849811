import { ReactNode, useContext } from 'react'
import cx from 'classnames'

import { useCartItemCount, CartContext } from '@lib/cart'

import Icon from '@components/icon'

interface CartToggleProps {
  className?: string
  children?: ReactNode
  showCartCount?: boolean
  showCartIcon?: boolean
}

const CartToggle = ({
  className,
  children,
  showCartCount,
  showCartIcon,
}: CartToggleProps) => {
  const cartCount = useCartItemCount()
  const { isCartOpen, toggleCart } = useContext(CartContext)

  return (
    <button
      onClick={() => toggleCart(!isCartOpen)}
      className={cx(
        'appearance-none no-underline cursor-pointer font-inherit flex items-center relative bg-transparent',
        className
      )}
    >
      {showCartIcon && (
        <Icon
          id="cart"
          name="Cart"
          className={cx('text-xl', {
            'mr-2': children,
          })}
        />
      )}
      {children} {showCartCount && `(${cartCount})`}
    </button>
  )
}

export default CartToggle
