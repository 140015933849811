import { AriaRole, createContext, ReactElement, ReactNode } from 'react'
import flattenChildren from 'react-keyed-flatten-children'

export interface RadioGroupContextProps {
  value: string
  items: ReactElement[]
  onChange: (value: string) => void
}

// Create radio contexts
export const RadioGroupContext = createContext<RadioGroupContextProps>({
  value: '',
  items: [],
  onChange: () => null,
})

export const RadioItemContext = createContext<number>(0)

interface RadioGroupProps {
  value: string
  onChange: (value: string) => void
  children: ReactNode
  className?: string
  role?: AriaRole
}

const RadioGroup = ({
  value,
  onChange,
  children,
  className,
  role = 'radiogroup',
}: RadioGroupProps) => {
  const items = flattenChildren(children) as ReactElement[]

  return (
    <RadioGroupContext.Provider value={{ value, items, onChange }}>
      <div role={role} className={className}>
        {items.map((item, index) => (
          <RadioItemContext.Provider key={item.key} value={index}>
            {item}
          </RadioItemContext.Provider>
        ))}
      </div>
    </RadioGroupContext.Provider>
  )
}

export default RadioGroup
