import { gql } from '@apollo/client'

import {
  CHECKOUT_FRAGMENT,
  MAILING_ADDRESS_FRAGMENT,
} from '../queries/fragments'

export const CHECKOUT_CREATE = gql`
  ${CHECKOUT_FRAGMENT}
  ${MAILING_ADDRESS_FRAGMENT}

  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`

export const CHECKOUT_SHIPPING_ADDRESS_UPDATE = gql`
  ${CHECKOUT_FRAGMENT}
  ${MAILING_ADDRESS_FRAGMENT}

  mutation checkoutShippingAddressUpdate(
    $checkoutId: ID!
    $shippingAddress: MailingAddressInput!
  ) {
    checkoutShippingAddressUpdateV2(
      checkoutId: $checkoutId
      shippingAddress: $shippingAddress
    ) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`
