import { ButtonHTMLAttributes, useContext, useState } from 'react'
import cx from 'classnames'

import { SanityProductVariantFragment } from '@data/sanity/queries/types/product'
import { CartContext, getFeeLineItems } from '@lib/cart'
import { useGetFormattedPrice } from '@lib/helpers'
import { StringsContext } from '@lib/strings'

import Button, { ButtonProps, ButtonSize } from './button'

interface AddToCartButtonProps {
  productVariant: SanityProductVariantFragment
  onAddToCartError?: () => void
}

const AddToCartButton = ({
  variant,
  size,
  color,
  icon,
  iconAlignment,
  children,
  className,
  productVariant,
  onAddToCartError,
}: AddToCartButtonProps &
  ButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { cart, addItemsToCart } = useContext(CartContext)
  const strings = useContext(StringsContext)
  const getFormattedPrice = useGetFormattedPrice()
  const [isAddingToCart, setIsAddingToCart] = useState(false)
  const [isAddToCartError, setIsAddToCartError] = useState(false)

  const handleAddToCart = async () => {
    setIsAddToCartError(false)
    setIsAddingToCart(true)

    const feeLineItems = getFeeLineItems(productVariant, 1)

    const isSuccessful = await addItemsToCart(async () => [
      { id: productVariant.id, quantity: 1 },
      ...feeLineItems,
    ])

    if (!isSuccessful && onAddToCartError) {
      onAddToCartError()
    }

    setIsAddingToCart(false)
  }

  return (
    <span className={cx('inline-flex items-center', className)}>
      <span
        className={cx('font-serif font-medium mr-5', {
          'text-lg': size === ButtonSize.SMALL,
          'text-3xl': size === ButtonSize.NORMAL,
          'text-4xl': size === ButtonSize.LARGE,
        })}
      >
        {getFormattedPrice(productVariant.price)}
      </span>

      <Button
        id="product-add-to-cart-button"
        variant={variant}
        size={size}
        color={color}
        icon={icon}
        iconAlignment={iconAlignment}
        onClick={handleAddToCart}
        disabled={
          !cart || !productVariant.inStock || isAddingToCart || isAddToCartError
        }
      >
        {!cart && strings.buttonUnavailable}
        {isAddingToCart && strings.buttonAdding}
        {!productVariant.inStock && strings.productOutOfStock}
        {cart &&
          productVariant.inStock &&
          !isAddingToCart &&
          !isAddToCartError &&
          children}
      </Button>
    </span>
  )
}

export default AddToCartButton
