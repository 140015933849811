import {
  Product,
  ProductBundle,
  ProductBundleGallery,
  ProductBundleSlot,
  ProductBundleVariantMap,
  ProductOption,
  ProductOptionName,
  ProductOptionSettings,
  ProductOptionValue,
  ProductVariant,
  Seo,
} from '@data/sanity/schema'
import { SanityContentFragment } from './content'
import { SanityFeeProductVariantFragment } from './fee'
import { SanityImageFragment } from './image'
import {
  HasContent,
  HasPrivateContent,
  HasRawContent,
  SanityHasSlug,
} from './page'
import {
  SanityPrintFeeSettings,
  SanityPrintPermissions,
  SanityProductPrintConfiguration,
} from './print'
import { SanityPrintShop } from './shop'
import { SanitySiteFragment } from './site'
import { SanityPrintConfiguratorStrings } from './strings'

export interface SanityProductGalleryPhoto {
  forOption: string
  photos: Array<SanityImageFragment>
}

export interface SanityProductListingPhoto {
  forOption: string
  default: SanityImageFragment
  hover?: SanityImageFragment
}

export interface SanityProductPhotosFragment {
  main?: Array<SanityProductGalleryPhoto>
  listing?: Array<SanityProductListingPhoto>
}

export type SanityProductVariantOption = Pick<
  ProductOptionValue,
  'name' | 'position' | 'value'
>

export type SanityProductVariantFragment = Pick<
  ProductVariant,
  | 'sku'
  | 'barcode'
  | 'title'
  | 'price'
  | 'comparePrice'
  | 'inStock'
  | 'lowStock'
  | 'seo'
> & {
  id: SanityProductVariantId
  options: Array<SanityProductVariantOption>
  fees?: Array<SanityFeeProductVariantFragment>
}

export type SanityProductOption = Pick<
  ProductOption,
  'name' | 'position' | 'values'
>

export interface SanityColor {
  hex: string
}

export type SanityProductOptionSetting = Pick<
  ProductOptionSettings,
  'forOption'
> & {
  color?: SanityColor
}

export type SanityProductOptionName = Pick<
  ProductOptionName,
  'forOption' | 'name'
>

export enum SanityProductType {
  BASIC = 'basic',
  BASIC_DIGITAL = 'basicDigital',
  PRINT = 'print',
  FEE = 'fee',
}

interface SanityProductFilter {
  forOption: string
  slug: string
}

export type SanityProductFragment = Pick<
  Product,
  | 'sku'
  | 'barcode'
  | 'title'
  | 'price'
  | 'comparePrice'
  | 'inStock'
  | 'lowStock'
  | 'useGallery'
> &
  SanityHasSlug & {
    type: SanityProductType
    createdAt: string
    fees?: Array<SanityFeeProductVariantFragment>
    description?: Array<SanityContentFragment>
    cartDescription?: Array<SanityContentFragment>
    surfaceOption?: string
    options: Array<SanityProductOption>
    optionSettings?: Array<SanityProductOptionSetting>
    optionNames?: Array<SanityProductOptionName>
    id: SanityProductId
    photos: SanityProductPhotosFragment
    variants?: Array<SanityProductVariantFragment>
    filters?: Array<SanityProductFilter>
    teamShop?: Array<SanityProductPrintConfiguration>
    yearShop?: Array<SanityProductPrintConfiguration>
  }

export type SanityProductId = Product['productID']

export type SanityProductVariantId = ProductVariant['variantID']

export type SanityProductPage = Pick<
  Product,
  'hasTransparentHeader' | 'seo' | 'noIndex'
> &
  HasContent &
  HasPrivateContent & {
    product: SanityProductFragment
    featuredProductIds?: Array<SanityProductId>
  }

export interface SanityProductPageQuery {
  page: SanityProductPage | null
  site: SanitySiteFragment
}

export interface SanityPrintProductPage extends SanityProductPage {
  printShop?: SanityPrintShop
  printPermissions?: SanityPrintPermissions
  printFeeSettings?: SanityPrintFeeSettings
  printConfiguratorStrings: SanityPrintConfiguratorStrings
}

export interface SanityPrintProductPageQuery {
  page: SanityPrintProductPage | null
  site: SanitySiteFragment
}

export type SanityProductSeo = Omit<Seo, '_type'>

export type SanityProduct = Omit<
  Product,
  '_id' | '_type' | '_createdAt' | '_updatedAt' | '_rev'
>

export type SanityProductByShopifyId = Pick<
  ProductVariant,
  '_id' | 'locale'
> & {
  description?: Array<SanityContentFragment>
} & HasRawContent

export type SanityProductsByShopifyIdQuery = Array<SanityProductByShopifyId>

export type SanityProductVariantProduct = Pick<Product, 'title'> &
  SanityHasSlug & {
    id: Product['productID']
    type: SanityProductType
    cartDescription?: Array<SanityContentFragment>
  }

export interface SanityProductVariantPhotosCart {
  forOption: string
  default: SanityImageFragment
}

export interface SanityProductVariantPhotos {
  cart?: Array<SanityProductVariantPhotosCart>
}

export type SanityProductVariant = Omit<
  ProductVariant,
  '_id' | '_type' | '_createdAt' | '_updatedAt' | '_rev'
>

export type ProductVariantOption = ProductVariant['options'][number]

export type SanityProductVariantQuery = Pick<
  ProductVariant,
  'title' | 'price' | 'sellingPlanId'
> & {
  id: SanityProductVariantId
  product: SanityProductVariantProduct
  photos: SanityProductVariantPhotos
  options: Array<SanityProductVariantOption>
}

export type SanityProductVariantsQuery = Array<SanityProductVariantQuery>

export type SanityProductVariantsByShopifyIdQuery = Array<
  Pick<ProductVariant, '_id' | 'variantID'>
>

export type SanityProductCatalogueItem = Pick<Product, 'type' | 'title'> &
  SanityHasSlug & {
    id: Product['productID']
    subscriptionProductIds?: Product['productID'][]
  }

export type SanityProductCatalogueQuery = Array<SanityProductCatalogueItem>

export type SanityProductExistsQuery = Pick<Product, '_id'>

export type SanityProductBundleSlotProduct = SanityProductFragment

export type SanityProductBundleSlot = Pick<
  ProductBundleSlot,
  'name' | 'showSelection' | 'label'
> & {
  selectedOptionNames: Array<{
    productId: string
    list: string[]
  }>
  products: Array<SanityProductBundleSlotProduct>
}

export interface SanityProductBundleVariantMapProduct {
  id: string
  variantIds: Array<string>
}

export type SanityProductBundleVariantMap = Pick<
  ProductBundleVariantMap,
  'name'
> & {
  products: Array<SanityProductBundleVariantMapProduct>
}

export type SanityProductBundleGallery = Pick<
  ProductBundleGallery,
  'variantCombination'
> & {
  photos: Array<SanityImageFragment>
}

export type SanityProductBundle = Pick<ProductBundle, 'name'> & {
  slots: Array<SanityProductBundleSlot>
  variantMap: Array<SanityProductBundleVariantMap>
  gallery: Array<SanityProductBundleGallery> | null
}
