import {
  CheckoutCreateInput,
  CheckoutCreateMutation,
  CheckoutCreateMutationVariables,
  CheckoutShippingAddressUpdateMutation,
  CheckoutShippingAddressUpdateMutationVariables,
  MailingAddressInput,
} from '@data/shopify/storefront/types'
import {
  CHECKOUT_CREATE,
  CHECKOUT_SHIPPING_ADDRESS_UPDATE,
} from '@data/shopify/storefront/mutations/checkout'
import { ShopifyClient } from './client'

/**
 * Creates a new Shopify checkout.
 */
export const createCheckout = async (
  shopifyStorefrontClient: ShopifyClient,
  checkoutInput: CheckoutCreateInput
) => {
  try {
    const checkoutCreateResult = await shopifyStorefrontClient.mutate<
      CheckoutCreateMutation,
      CheckoutCreateMutationVariables
    >({
      mutation: CHECKOUT_CREATE,
      variables: {
        input: checkoutInput,
      },
    })

    return checkoutCreateResult.data
  } catch (error) {
    console.log(error)

    return null
  }
}

/**
 * Updates Shopify checkout shipping address.
 */
export const updateShippingAddress = async (
  shopifyStorefrontClient: ShopifyClient,
  checkoutId: string,
  shippingAddress: MailingAddressInput
) => {
  try {
    const checkoutShippingAddressUpdateResult =
      await shopifyStorefrontClient.mutate<
        CheckoutShippingAddressUpdateMutation,
        CheckoutShippingAddressUpdateMutationVariables
      >({
        mutation: CHECKOUT_SHIPPING_ADDRESS_UPDATE,
        variables: {
          checkoutId,
          shippingAddress,
        },
      })

    return checkoutShippingAddressUpdateResult.data
  } catch (error) {
    console.log(error)

    return null
  }
}
