import cx from 'classnames'

import RadioGroup from '@components/radio-group'
import RadioItem from '@components/radio-item'

export interface RadioItem {
  value: string
  label?: string
}

export enum RadioItemSize {
  NORMAL = 'normal',
  LARGE = 'large',
}

export enum RadioItemLayout {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

interface RadioProps {
  value: string
  items: RadioItem[]
  onChange: (value: string) => void
  size?: RadioItemSize
  className?: string
  layout?: RadioItemLayout
}

const Radio = ({
  value,
  items,
  size = RadioItemSize.NORMAL,
  onChange,
  className,
  layout = RadioItemLayout.HORIZONTAL,
}: RadioProps) => {
  return (
    <RadioGroup
      className={cx(
        'flex',
        {
          'items-center': layout === RadioItemLayout.HORIZONTAL,
          'flex-col': layout === RadioItemLayout.VERTICAL,
        },
        className
      )}
      value={value}
      onChange={(value) => onChange(value)}
    >
      {items.map((item) => (
        <RadioItem
          key={item.value}
          value={item.value}
          className={cx('flex items-center text-left', {
            'ml-8 first:ml-0': layout === RadioItemLayout.HORIZONTAL,
            'mb-2': layout === RadioItemLayout.VERTICAL,
          })}
        >
          <span
            className={cx(
              'rounded-full border flex items-center justify-center mr-2',
              {
                'min-w-[25px] min-h-[25px]': size === RadioItemSize.NORMAL,
                'min-w-[35px] min-h-[35px]': size === RadioItemSize.LARGE,
              }
            )}
          >
            {item.value === value && (
              <span
                className={cx('rounded-full bg-black', {
                  'min-w-[13px] min-h-[13px]': size === RadioItemSize.NORMAL,
                  'min-w-[23px] min-h-[23px]': size === RadioItemSize.LARGE,
                })}
              />
            )}
          </span>
          <p>{item.label}</p>
        </RadioItem>
      ))}
    </RadioGroup>
  )
}

export default Radio
