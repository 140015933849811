import { CSSProperties } from 'react'
import cx from 'classnames'
import Select, { Options, GroupBase, StylesConfig } from 'react-select'

import { colors } from '@lib/theme'
import Icon from './icon'

export interface SearchDropdownOption {
  label: string
  value: string
}

export interface StyleOverrides {
  control?: CSSProperties
  valueContainer?: CSSProperties
  singleValue?: CSSProperties
  placeholder?: CSSProperties
  input?: CSSProperties
  menu?: CSSProperties
  indicatorSeparator?: CSSProperties
}

interface InputSearchDropdownProps {
  id: string
  options: Options<SearchDropdownOption>
  value: string
  onChange: (value: string) => void
  errorMessage?: string
  label?: string
  placeholder?: string
  className?: string
  hasBorder?: boolean
  styles?: StyleOverrides
}

const InputSearchDropdown = ({
  id,
  options,
  value,
  onChange = () => null,
  errorMessage,
  label,
  placeholder,
  className,
  hasBorder = true,
  styles,
}: InputSearchDropdownProps) => {
  return (
    <div className={cx('grid', className)}>
      <div className={cx('flex flex-col relative text-left')}>
        {label && (
          <label htmlFor={id} className="text-sm mb-1">
            {label}
          </label>
        )}

        <Select
          instanceId={id}
          placeholder={placeholder}
          aria-label={label}
          styles={getStyles(!!errorMessage, hasBorder, styles)}
          options={options}
          value={options.find((option) => option.value === value)}
          components={{ DropdownIndicator: InputSearchDropdownIndicator }}
          onChange={(option) => onChange(option?.value ?? '')}
        />

        {errorMessage && (
          <span role="alert" className="mt-2 text-xs text-error">
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  )
}

const getStyles = (
  hasError: boolean,
  hasBorder: boolean,
  styleOverrides?: StyleOverrides
): StylesConfig<
  SearchDropdownOption,
  false,
  GroupBase<SearchDropdownOption>
> => ({
  control: () => ({
    display: 'flex',
    borderRadius: 0,
    background: 'transparent',
    color: colors.current,
    padding: '.5rem 1rem',
    ...(hasBorder && {
      border: hasError
        ? `1px solid ${colors.error.DEFAULT}`
        : `1px solid currentColor`,
    }),
    ...(!hasBorder && {
      paddingLeft: 0,
      paddingRight: 0,
    }),

    ...styleOverrides?.control,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    color: colors.current,

    ...(!hasBorder && {
      paddingRight: '.5rem',
    }),

    ...styleOverrides?.valueContainer,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: colors.current,
    textOverflow: 'ellipsis',

    ...styleOverrides?.singleValue,
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: 0,
    color: colors.current,
    opacity: 0.5,

    ...styleOverrides?.placeholder,
  }),
  input: (provided) => ({
    ...provided,
    margin: '0 1rem 0 0',
    color: colors.current,
    padding: 0,

    ...styleOverrides?.input,
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    minWidth: 200,
    color: colors.black,
    background: colors.white,

    ...styleOverrides?.menu,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',

    ...styleOverrides?.indicatorSeparator,
  }),
})

const InputSearchDropdownIndicator = () => (
  <Icon id="dropdown-indicator" name="ChevronDown" />
)

export default InputSearchDropdown
