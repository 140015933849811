import { AttributeInput } from '@data/shopify/storefront/types'
import { LineItem } from '@lib/cart'
import { CartLineItemAttribute } from './cart'

/**
 * Gets school-related attributes.
 */
export const getSchoolAttributes = (
  schoolId: string,
  schoolName: string
): AttributeInput[] => [
  {
    key: `_${CartLineItemAttribute.SCHOOL_ID}`,
    value: schoolId,
  },
  {
    key: `_${CartLineItemAttribute.SCHOOL_NAME}`,
    value: schoolName,
  },
]

/**
 * Checks if all line items have correct school ID and name attributes.
 */
export const validateLineItemSchoolAttributes = (
  lineItems: LineItem[],
  schoolId: string
) =>
  lineItems.every(({ attributes }) => {
    const lineItemSchoolId = attributes?.find(
      ({ key }) => key === `_${CartLineItemAttribute.SCHOOL_ID}`
    )?.value
    const lineItemSchoolName = attributes?.find(
      ({ key }) => key === `_${CartLineItemAttribute.SCHOOL_NAME}`
    )?.value

    return lineItemSchoolId === schoolId && !!lineItemSchoolName
  })
