import '../public/global.css'

import { useState, useEffect, useContext, useRef } from 'react'
import { AppProps } from 'next/app'
import Error from 'next/error'
import Head from 'next/head'
import { Router } from 'next/router'
import { LazyMotion, domAnimation, AnimatePresence } from 'framer-motion'

import { SanityAnyPage } from '@data/sanity/queries/types/page'
import { SanityProductCatalogueQuery } from '@data/sanity/queries/types/product'
import {
  PublicSiteSettings,
  SanitySiteFragment,
} from '@data/sanity/queries/types/site'
import { triggerPageviewEvent } from '@lib/analytics'
import { pageTransitionSpeed } from '@lib/animate'
import { CartContext } from '@lib/cart'
import { getNonce } from '@lib/dom'
import facebookPixel from '@lib/facebook-pixel'
import googleAnalytics from '@lib/google-analytics'
import googleTagManager from '@lib/google-tag-manager'
import { isBrowser } from '@lib/helpers'
import { LanguageContextProvider, Locale } from '@lib/language'
import { MetadataContextProvider } from '@lib/metadata'
import { partnerAdsLoad } from '@lib/partner-ads'
import { Reviews } from '@lib/review'
import { getPageUrl, PageType } from '@lib/routes'
import { ShopContext, ShopContextProvider } from '@lib/shop'
import { SiteContext, SiteContextProvider } from '@lib/site'
import { StringsContextProvider } from '@lib/strings'

import RouteChangeProgressBar from '@components/route-change-progress-bar'
import CartModal from '@modules/shop/cart/modal'

interface TransitionOptions {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
}

interface NextHistoryState {
  url: string
  as: string
  options: TransitionOptions
}

interface AppPageProps {
  locale: Locale
  site: SanitySiteFragment
  page: SanityAnyPage
  productCatalogue?: SanityProductCatalogueQuery
  shopifyRedirect?: boolean
  reviews?: Reviews
}

type DefaultAppProps = Pick<AppProps, 'Component' | 'router'>

type CustomAppProps = DefaultAppProps & {
  pageProps?: AppPageProps
}

type SiteProps = DefaultAppProps & {
  pageProps: AppPageProps
}

/**
 * Google Tag Manager, Google Analytics, Facebook Pixel & Partner Ads script hook.
 */
const useExternalScripts = (router: Router, settings: PublicSiteSettings) => {
  const { shopifyDomain, shopifyPrimaryDomain } = useContext(ShopContext)

  // Load Google Tag Manager, Google Analytics & Facebook Pixel
  const [gtmContainerIdLoaded, setGtmContainerIdLoaded] = useState('')
  const [analyticsIdLoaded, setAnalyticsIdLoaded] = useState('')
  const [facebookPixelIdLoaded, setFacebookPixelIdLoaded] = useState('')

  // Load Google Tag Manager
  useEffect(() => {
    const nonce = getNonce()

    if (
      settings?.gtmContainerId &&
      settings.gtmContainerId !== gtmContainerIdLoaded
    ) {
      googleTagManager(settings.gtmContainerId, nonce)
      setGtmContainerIdLoaded(settings.gtmContainerId)
    }
  }, [gtmContainerIdLoaded, settings?.gtmContainerId])

  // Load Google Analytics
  useEffect(() => {
    const nonce = getNonce()

    if (settings?.analyticsId && settings.analyticsId !== analyticsIdLoaded) {
      const linkedDomains = [shopifyPrimaryDomain]

      if (shopifyDomain !== shopifyPrimaryDomain) {
        linkedDomains.push(shopifyDomain)
      }

      googleAnalytics(settings.analyticsId, linkedDomains, nonce)
      setAnalyticsIdLoaded(settings.analyticsId)
    }
  }, [
    analyticsIdLoaded,
    settings?.analyticsId,
    shopifyDomain,
    shopifyPrimaryDomain,
  ])

  // Load Facebook Pixel
  useEffect(() => {
    const nonce = getNonce()

    if (
      settings?.facebookPixelId &&
      settings.facebookPixelId !== facebookPixelIdLoaded
    ) {
      facebookPixel(settings.facebookPixelId, nonce)
      setFacebookPixelIdLoaded(settings.facebookPixelId)
    }
  }, [facebookPixelIdLoaded, settings?.facebookPixelId])

  // Load Partner Ads
  useEffect(() => {
    if (settings?.partnerAdsTracking) {
      partnerAdsLoad()
    }
  }, [settings?.partnerAdsTracking])

  // Trigger pageview on page load, if this is the first render and there's no query string
  useEffect(() => {
    if (!router.asPath.includes('?')) {
      triggerPageviewEvent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

/**
 * Add new position to scroll positions.
 */
const addScrollPosition = (
  positions: Record<string, number>,
  locale: Locale,
  url: string,
  position: number
) => {
  const key = `${locale}:${url}`
  const alternativeKey = `${locale}:/${locale}${url.replace(/\/+$/g, '')}`

  return {
    ...positions,
    [key]: position,
    [alternativeKey]: position,
  }
}

/**
 * Router event handler hook.
 */
const useRouterEvents = (router: Router, locale: Locale) => {
  const { toggleCart } = useContext(CartContext)
  const { togglePageTransition, toggleMobileMenu } = useContext(SiteContext)

  const scrollPositions = useRef<Record<string, number>>({})
  const shouldScrollRestore = useRef(false)
  const isInitialLoad = useRef(true)

  useEffect(() => {
    // Prevent browser scroll restoration
    window.history.scrollRestoration = 'manual'
  }, [])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!isInitialLoad.current) {
        // Save scroll position
        scrollPositions.current = addScrollPosition(
          scrollPositions.current,
          locale,
          router.asPath,
          window.scrollY
        )
      }

      delete event['returnValue']
    }

    const handleRouteChangeStart = (
      _: string,
      { shallow }: TransitionOptions
    ) => {
      toggleMobileMenu(false)
      toggleCart(false)

      if (!isInitialLoad.current) {
        // Save scroll position
        scrollPositions.current = addScrollPosition(
          scrollPositions.current,
          locale,
          router.asPath,
          window.scrollY
        )
      }

      // Check if URL is changing
      if (!shallow) {
        togglePageTransition(true)
      }
    }

    const handleRouteChangeComplete = (
      url: string,
      { shallow }: TransitionOptions
    ) => {
      // Wait for page transition to complete
      setTimeout(() => togglePageTransition(false), pageTransitionSpeed)

      // Check if URL is changing
      if (!isInitialLoad.current && !shallow) {
        // Restore scroll position after route change completes
        const position = scrollPositions.current[`${locale}:${url}`]
        const top = position && shouldScrollRestore.current ? position : 0

        // Restore scroll position or set it to 0
        setTimeout(
          () => requestAnimationFrame(() => window.scrollTo({ top })),
          pageTransitionSpeed + 100
        )

        shouldScrollRestore.current = false
      }

      // Wait for document title to update
      setTimeout(() => triggerPageviewEvent(), pageTransitionSpeed + 101)

      isInitialLoad.current = false
    }

    const handleRouteChangeError = () => {
      togglePageTransition(false)
    }

    const handleBeforePopState = ({ options }: NextHistoryState): boolean => {
      // Allow scroll position restoring
      shouldScrollRestore.current = true
      options.scroll = false

      return true
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeError)
    router.beforePopState(handleBeforePopState)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeError)
      router.beforePopState(() => true)
    }
  }, [locale, router, toggleCart, toggleMobileMenu, togglePageTransition])
}

/**
 * Shopify redirect handler hook.
 */
const useShopifyRedirect = (
  router: Router,
  locale: Locale,
  shopifyRedirect?: boolean
) => {
  // Shopify redirect handler
  useEffect(() => {
    if (!shopifyRedirect) {
      return
    }

    // Redirect to homepage
    router.push(getPageUrl(PageType.HOME_PAGE))
  }, [locale, shopifyRedirect, router])
}

/**
 * Keyboard focus state handler hook.
 */
const useKeyboardListener = () => {
  useEffect(() => {
    const handleKeyDown = ({ key }: KeyboardEvent) => {
      // Check if "tab" key was pressed
      if (key === 'Tab' && isBrowser) {
        document.body.classList.add('is-tabbing')
        window.removeEventListener('keydown', handleKeyDown)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])
}

const Site = ({ Component, pageProps, router }: SiteProps) => {
  const { locale, page, site, shopifyRedirect, reviews } = pageProps
  const { isPageTransition } = useContext(SiteContext)

  // Handle special Shopify redirect page
  useShopifyRedirect(router, locale, shopifyRedirect)

  // Handle router events & scroll position restoration
  useRouterEvents(router, locale)

  // Handle keyboard navigation
  useKeyboardListener()

  // Load external scripts
  useExternalScripts(router, site.settings)

  return (
    <LazyMotion features={domAnimation}>
      {isPageTransition && (
        <Head>
          {site.siteStrings?.loadingPageTitle && (
            <title>{site.siteStrings.loadingPageTitle}</title>
          )}
        </Head>
      )}

      <RouteChangeProgressBar />

      <AnimatePresence
        mode="wait"
        onExitComplete={() => document.body.classList.remove('overflow-hidden')}
      >
        <Component
          key={router.asPath.split('?')[0]}
          page={page}
          site={site}
          locale={locale}
          reviews={reviews}
        />
      </AnimatePresence>

      <CartModal cartSettings={site.cart} />
    </LazyMotion>
  )
}

const CustomApp = ({ Component, pageProps, router }: CustomAppProps) => {
  // If site configuration is missing, don't show the full layout
  if (!pageProps?.site) {
    // If status code is provided, show error component
    if (pageProps && 'statusCode' in pageProps) {
      return <Error statusCode={Number(pageProps.statusCode)} />
    }

    return <Component />
  }

  return (
    <StringsContextProvider site={pageProps.site}>
      <SiteContextProvider site={pageProps.site}>
        <MetadataContextProvider>
          <ShopContextProvider
            locale={pageProps.locale}
            site={pageProps.site}
            productCatalogue={pageProps.productCatalogue ?? []}
          >
            <LanguageContextProvider
              locale={pageProps.locale}
              site={pageProps.site}
            >
              <Site
                Component={Component}
                pageProps={pageProps}
                router={router}
              />
            </LanguageContextProvider>
          </ShopContextProvider>
        </MetadataContextProvider>
      </SiteContextProvider>
    </StringsContextProvider>
  )
}

export default CustomApp
