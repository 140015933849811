import { ReactNode } from 'react'
import cx from 'classnames'

import { SanityContentQuote } from '@data/sanity/queries/types/content'

import Icon from '@components/icon'

type QuoteProps = Pick<SanityContentQuote, 'author' | 'alignment'> & {
  children: ReactNode
}

const Quote = ({ children, author, alignment }: QuoteProps) => {
  return (
    <div className={cx({ [`text-${alignment}`]: alignment })}>
      <Icon
        id="quote-icon"
        name="Quote"
        className="fill-current text-pageText w-[29px] inline"
      />
      <p className="text-2xl !text-pageText leading-relaxed mt-8">{children}</p>
      {author && (
        <p className="!text-pageText opacity-60 text-base mt-8">{author}</p>
      )}
    </div>
  )
}

export default Quote
