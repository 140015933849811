import {
  OrderFinancialStatus,
  OrderFulfillmentStatus,
} from '@data/shopify/storefront/types'

export interface AddressFormValues {
  firstName: string
  lastName: string
  company: string
  address1: string
  address2: string
  city: string
  country: string
  zip: string
  phone: string
  isDefault: boolean
}

export interface UserAddress {
  id: string
  globalId?: number
  isDefault: boolean
  formatted: string[]
  values: AddressFormValues
}

export interface UserOrder {
  id: string
  date: string
  paymentStatus: OrderFinancialStatus | null
  fulfillmentStatus: OrderFulfillmentStatus
  total: number
  url: string
}

export interface UserProduct {
  id: number
  title: string
  slug: string
}

export const USER_LIST_PAGE_LIMIT = 10
