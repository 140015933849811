import { parseRequiredParameter } from '@lib/helpers'
import { getLocaleVariable, Locale } from '@lib/language'

type ShopifyHeaderKey = 'Content-Type' | 'X-Shopify-Access-Token'

export type ShopifyHeaders = Record<ShopifyHeaderKey, string>

type RechargeHeaderKey = 'Content-Type' | 'X-Recharge-Access-Token'

export type RechargeHeaders = Record<RechargeHeaderKey, string>

export const SHOPIFY_API_VERSION = '2023-07'

/**
 * Gets Recharge request headers.
 */
export const getRechargeHeaders = (locale: Locale): RechargeHeaders => {
  const rechargeToken = getLocaleVariable(locale, 'RECHARGE_API_TOKEN') ?? ''

  return {
    'Content-Type': 'application/json',
    'X-Recharge-Access-Token': rechargeToken,
  }
}

/**
 * Gets Shopify domain.
 */
export const getShopifyDomain = (locales: Locale[] | Locale): string => {
  const locale = parseRequiredParameter<Locale>(locales)
  const storeId = getLocaleVariable(locale, 'SHOPIFY_STORE_ID')

  return `${storeId}.myshopify.com`
}

/**
 * Gets Shopify Storefront API URL.
 */
export const getShopifyStorefrontApiUrl = (shopifyDomain: string): string =>
  `https://${shopifyDomain}/api/${SHOPIFY_API_VERSION}`

/**
 * Gets Shopify Admin API URL.
 */
export const getShopifyAdminApiUrl = (shopifyDomain: string): string =>
  `https://${shopifyDomain}/admin/api/${SHOPIFY_API_VERSION}`

/**
 * Gets a Shopify global ID.
 */
export const getShopifyGlobalId = (type: string, id: string | number) =>
  `gid://shopify/${type}/${id}`

/**
 * Gets Shopify ID from Shopify global ID.
 */
export const parseShopifyGlobalId = (shopifyGlobalId: string) => {
  try {
    return Number(shopifyGlobalId.split('?')[0].split('/').slice(-1)[0])
  } catch (_) {
    return
  }
}

/**
 * Gets Shopify headers with access token.
 */
export const getShopifyHeaders = (
  locales: Locale | Locale[]
): ShopifyHeaders => {
  const locale = parseRequiredParameter<Locale>(locales)
  const apiPassword = getLocaleVariable(locale, 'SHOPIFY_API_PASSWORD')

  return {
    'Content-Type': 'application/json',
    'X-Shopify-Access-Token': apiPassword ?? '',
  }
}
