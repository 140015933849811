import { useContext } from 'react'
import cx from 'classnames'

import { useGetFormattedPrice } from '@lib/helpers'
import { StringsContext } from '@lib/strings'

interface CartTotalProps {
  price: number
  className?: string
}

const CartTotal = ({ price, className }: CartTotalProps) => {
  const getFormattedPrice = useGetFormattedPrice()
  const strings = useContext(StringsContext)

  return (
    <div
      className={cx('flex justify-between text-xl font-semibold', className)}
    >
      <span>{strings.cartTotal}</span>
      <span>{getFormattedPrice(price)}</span>
    </div>
  )
}

export default CartTotal
