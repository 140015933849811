const googleAnalytics = (analyticsId, linkedDomains, nonce) => {
  function b(i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r
    i[r] =
      i[r] ||
      function () {
        i[r].q = i[r].q || []
        i[r].q.push(arguments)
      }
    i[r].l = 1 * new Date()
    a = s.createElement(o)
    m = s.getElementsByTagName(o)[0]
    a.async = 1
    a.src = g
    a.setAttribute('nonce', nonce)
    m.parentNode.insertBefore(a, m)
  }
  b(
    window,
    document,
    'script',
    'https://www.google-analytics.com/analytics.js',
    'ga'
  )

  window.ga('create', analyticsId, 'auto', { allowLinker: true })
  window.ga('require', 'linker')
  window.ga('linker:autoLink', linkedDomains)

  window.ga('send', 'pageview')
}

export default googleAnalytics
