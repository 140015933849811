import axios from 'axios'

import { Locale } from '@lib/language'

interface Asset {
  url: string
  mimeType: string
  filename: string
}

export type UploadFileResponse =
  | {
      error: string
    }
  | {
      fileId: string
    }

export type GetFileResponse =
  | {
      error: string
    }
  | {
      fileUrl: string
    }

interface UploadFileParams {
  url: string
  mime_type: string
  filename: string
}

interface GetFileParams {
  file_id: string
}

/**
 * Uploads the given asset to Shopify.
 */
export const uploadAssetToShopify = async (locale: Locale, asset: Asset) => {
  try {
    const params: UploadFileParams = {
      url: asset.url,
      mime_type: asset.mimeType,
      filename: asset.filename,
    }
    const uploadFileResult = await axios.get<UploadFileResponse>(
      '/api/shopify/upload-file',
      {
        params,
        headers: {
          'Content-Type': 'application/json',
          'X-Locale': locale,
        },
      }
    )

    if (!('fileId' in uploadFileResult.data)) {
      throw new Error(uploadFileResult.data?.error ?? 'Unknown error')
    }

    return uploadFileResult.data.fileId
  } catch (error) {
    console.log(error)
  }
}

/**
 * Gets an asset from Shopify by ID.
 */
export const getAssetFromShopify = async (locale: Locale, fileId: string) => {
  try {
    const params: GetFileParams = {
      file_id: fileId,
    }
    const getFileResult = await axios.get<GetFileResponse>(
      '/api/shopify/get-file',
      {
        params,
        headers: {
          'Content-Type': 'application/json',
          'X-Locale': locale,
        },
      }
    )

    if (!('fileUrl' in getFileResult.data)) {
      throw new Error(getFileResult.data?.error ?? 'Unknown error')
    }

    return getFileResult.data.fileUrl
  } catch (error) {
    console.log(error)
  }
}
