import { SanityImageFragment } from '@data/sanity/queries/types/image'

import Photo from '@components/photo'

interface FigureProps {
  node: SanityImageFragment
}

const Figure = ({ node }: FigureProps) => <Photo image={node} />

Figure.displayName = 'Figure'

export default Figure
