import { createElement, ReactNode } from 'react'
import cx from 'classnames'

import BlockContent from '@sanity/block-content-to-react'

import { SanityContentBlock } from '@data/sanity/queries/types/content'

interface BlockProps {
  node: SanityContentBlock
  children: ReactNode
}

const Block = (props: BlockProps) => {
  const { style = 'normal' } = props.node

  const isSerif = style.includes('serif')

  const fontClass = { '!font-serif': isSerif }

  if (style.includes('normal')) {
    return <p className={cx(fontClass)}>{props.children}</p>
  }

  if (style.includes('preamble')) {
    return <p className={cx('text-2xl !mb-6', fontClass)}>{props.children}</p>
  }

  // build our mock header styles

  if (style.includes('mock')) {
    return (
      <p
        className={cx(
          {
            'is-h1': style.includes('h1'),
            'is-h2': style.includes('h2'),
            'is-h3': style.includes('h3'),
            'is-h4': style.includes('h4'),
          },
          fontClass
        )}
      >
        {props.children}
      </p>
    )
  }

  // go through our remaing, true header styles

  if (/^h\d(serif)?/.test(style)) {
    return createElement(
      isSerif ? style.replace('serif', '') : style,
      {
        className: cx(fontClass),
      },
      props.children
    )
  }

  // Handle all other blocks with the default serializer
  return BlockContent.defaultSerializers.types.block(props)
}

Block.displayName = 'Block'

export default Block
