import {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
  useContext,
  useEffect,
} from 'react'

import { SanityProductVariantFragment } from '@data/sanity/queries/types/product'
import { SanityBlogSettings } from '@data/sanity/queries/types/blog'
import { LanguageContext, Locale } from './language'
import { getPageUrl, PageType } from './routes'
import { getSanityImageUrl } from './image'
import { ProductVariantSelectionContext } from './product-variant-selection-context'

interface Metadata {
  metaTitle?: string
  metaDescription?: string
  shareTitle?: string
  shareDescription?: string
  shareGraphicUrl?: string
}

interface MetadataContextProps {
  metadata: Metadata
  setMetadata: Dispatch<SetStateAction<Metadata>>
  metadataOverride: Metadata
  setMetadataOverride: Dispatch<SetStateAction<Metadata>>
}

export const MetadataContext = createContext<MetadataContextProps>({
  metadata: {},
  setMetadata: () => null,
  metadataOverride: {},
  setMetadataOverride: () => null,
})

interface MetadataContextProviderProps {
  children: ReactNode
}

export const MetadataContextProvider = ({
  children,
}: MetadataContextProviderProps) => {
  const [metadata, setMetadata] = useState<Metadata>({})
  const [metadataOverride, setMetadataOverride] = useState<Metadata>({})

  return (
    <MetadataContext.Provider
      value={{
        metadata,
        setMetadata,
        metadataOverride,
        setMetadataOverride,
      }}
    >
      {children}
    </MetadataContext.Provider>
  )
}

/**
 * Blog canonical URL hook.
 */
export const useBlogCanonicalUrl = (
  pageType: PageType,
  slug: string,
  blogSettings: SanityBlogSettings | null
) => {
  const { locale } = useContext(LanguageContext)

  const [canonicalUrl, setCanonicalUrl] = useState<string>()

  useEffect(() => {
    // Check if blog inheritance is set
    if (
      typeof window === 'undefined' ||
      !blogSettings?.inheritContent ||
      !blogSettings?.sourceLocale
    ) {
      return
    }

    const origin = window.location.origin
    const pageUrl = getPageUrl(pageType, slug, {
      locale,
      newLocale: blogSettings.sourceLocale as Locale,
    })

    setCanonicalUrl(`${origin}${pageUrl}`)
  }, [locale, pageType, slug, blogSettings])

  return canonicalUrl
}

/**
 * Gets page metadata from product variant.
 */
export const getVariantMetadata = (variant?: SanityProductVariantFragment) => ({
  metaTitle: variant?.seo?.metaTitle,
  metaDescription: variant?.seo?.metaDesc,
  shareTitle: variant?.seo?.shareTitle,
  shareDescription: variant?.seo?.shareDesc,
  shareGraphicUrl: getSanityImageUrl(variant?.seo?.shareGraphic, {
    width: 1200,
    height: 630,
  }),
})

/**
 * Product page variant metadata hook.
 */
export const useVariantMetadata = () => {
  const { setMetadataOverride } = useContext(MetadataContext)
  const { selectedVariant } = useContext(ProductVariantSelectionContext)

  // Set metadata override
  useEffect(() => {
    const variantMetadata = getVariantMetadata(selectedVariant)
    setMetadataOverride(variantMetadata)

    return () => setMetadataOverride({})
  }, [selectedVariant, setMetadataOverride])
}
