import { useState, ReactNode } from 'react'
import { motion, Variants } from 'framer-motion'
import cx from 'classnames'

import Icon from '@components/icon'

interface AccordionProps {
  id: string
  title?: ReactNode
  isOpen?: boolean
  isControlled?: boolean
  onToggle?: (id: string, isOpen: boolean) => void
  className?: string
  innerClassName?: string
  children?: ReactNode
}

const accordionContentVariants: Variants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  closed: {
    opacity: 0,
    height: 0,
  },
}

const Accordion = ({
  id,
  title,
  isOpen = false,
  isControlled = false,
  onToggle = () => null,
  className,
  innerClassName,
  children,
}: AccordionProps) => {
  const [hasFocus, setHasFocus] = useState(isOpen)

  return (
    <div
      className={cx(
        'relative',
        'after:absolute after:left-0 after-right-0 after:bottom-0 after:border-t after:border-current after:w-full after:opacity-[.15]', // Border with opacity
        className
      )}
    >
      {!isControlled && (
        <button
          onClick={() => onToggle(id, !isOpen)}
          aria-expanded={isOpen}
          aria-controls={`accordion-${id}`}
          className="text-left flex justify-between items-center py-5 px-4 w-full bg-transparent text-xl leading-snug"
        >
          {title}
          <Icon
            id={`accordion-icon-${id}`}
            name="PlusCircle"
            className={cx(
              'text-2xl transition-transform duration-300 ease-custom-1',
              {
                'transform rotate-45': isOpen,
              }
            )}
          />
        </button>
      )}

      <motion.div
        id={`accordion-${id}`}
        className="overflow-hidden"
        initial={isOpen ? 'open' : 'closed'}
        animate={isOpen ? 'open' : 'closed'}
        variants={accordionContentVariants}
        transition={{ duration: 0.5, ease: [0.19, 1.0, 0.22, 1.0] }}
        onAnimationComplete={(definition) => setHasFocus(definition === 'open')}
      >
        <div
          className={cx('m-0 pb-12 px-4', innerClassName)}
          hidden={!isOpen && !hasFocus}
        >
          {children}
        </div>
      </motion.div>
    </div>
  )
}

export default Accordion
