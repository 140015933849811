import {
  createContext,
  useEffect,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useReducer,
  useCallback,
  useContext,
} from 'react'
import axios, { AxiosResponse } from 'axios'
import { useRouter } from 'next/router'

import {
  SanityPrintSlotPermission,
  SanityPrintSlotType,
} from '@data/sanity/queries/types/print'
import { SanityProductCatalogueQuery } from '@data/sanity/queries/types/product'
import {
  SanityPrintColor,
  SanityPrintType,
  SanityPrintShop,
  SanityPrintShopType,
  SanityUserShop,
  SanityPrintShopBase,
} from '@data/sanity/queries/types/shop'
import { SanitySiteFragment } from '@data/sanity/queries/types/site'
import {
  getShopifyStorefrontClient,
  ShopifyClient,
} from './shopify/graphql/client'
import { getShopifyShopAndCart, ShopifyShop } from './shopify/graphql/shop'
import { getShopifyDomain } from './shopify/client'
import { CartContextProvider, getCartIdStorageKey } from './cart'
import { deleteCookie, getCookie, setCookie } from './cookie'
import { DiscountContextProvider } from './discount'
import { ErrorMessages } from './helpers'
import { Locale } from './language'
import {
  maxPrintFileSize,
  parseShopPrints,
  ShopPrint,
  uploadPrints,
} from './print'
import { SchoolContextProvider } from './school'
import { ShopFormStringsContext } from './strings'
import { getPageUrl, PageType, PageUrlOptions } from './routes'
import { variantUrlParameterName } from './url'

export interface CreateShopFormValues {
  email: string
  phone: string
  type: SanityPrintShopType
  name: string
  schoolId: string
}

interface CreateShopResponse {
  shop?: SanityUserShop
  prints?: ShopPrint[]
  error?: string
}

interface DeactivateShopInput {
  shopId: string
}

interface DeactivateShopResponse {
  error?: string
}

export interface ShopItem {
  shopId: string
  id: string
  name: string
  schoolName?: string
  date: string
  isActive: boolean
}

interface ShopUser {
  isLoggedIn?: boolean
  firstName?: string
  lastName?: string
  phone?: string
  email?: string
  password?: string
}

export interface ShopState {
  type: SanityPrintShopType
  shopId?: string
  id?: string
  name?: string
  schoolId?: string
  user?: ShopUser
  prints?: ShopPrint[]
}

export enum ShopActionType {
  UPDATE_SHOP_DETAILS = 'updateShopDetails',
  UPDATE_USER = 'updateUser',
  ADD_PRINT = 'addPrint',
  EDIT_PRINT = 'editPrint',
  REMOVE_PRINT = 'removePrint',
}

interface UpdateShopDetailsShopAction {
  action: ShopActionType.UPDATE_SHOP_DETAILS
  shopId: string
  id: string
  name: string
  schoolId: string
}

interface UpdateUserIdShopAction {
  action: ShopActionType.UPDATE_USER
  user: ShopUser
}

export interface AddPrintShopAction
  extends Pick<
    ShopPrint,
    | 'id'
    | 'image'
    | 'filename'
    | 'title'
    | 'slotTypes'
    | 'type'
    | 'color'
    | 'isOptimized'
  > {
  action: ShopActionType.ADD_PRINT
}

interface EditPrintShopAction extends Pick<ShopPrint, 'id'> {
  action: ShopActionType.EDIT_PRINT
  title: string
}

interface RemovePrintShopAction extends Pick<ShopPrint, 'id'> {
  action: ShopActionType.REMOVE_PRINT
}

export type ShopAction =
  | UpdateShopDetailsShopAction
  | UpdateUserIdShopAction
  | AddPrintShopAction
  | EditPrintShopAction
  | RemovePrintShopAction

interface ShopContextProps {
  taxRate: number
  currency?: string
  currencyCode?: string
  countryCode?: string
  shopifyDomain: string
  shopifyPrimaryDomain: string
  productCatalogue: SanityProductCatalogueQuery
  shopifyStorefrontClient?: ShopifyClient
  printShops: SanityPrintShopBase[]
  printShop: SanityPrintShopBase | null
  setPrintShop: (printShop: SanityPrintShopBase | null) => void
}

interface ShopContextProviderProps {
  locale: Locale
  site: SanitySiteFragment
  productCatalogue: SanityProductCatalogueQuery
  children: ReactNode
}

interface ShopFormContextProps {
  printPermissions: SanityPrintSlotPermission[]
  step: number
  setStep: Dispatch<SetStateAction<number>>
  shopState: ShopState
  dispatchShopState: Dispatch<ShopAction>
}

interface ShopFormContextProviderProps {
  initialStep: number
  shopType: SanityPrintShopType
  printPermissions: SanityPrintSlotPermission[]
  shop?: SanityPrintShop
  children?: ReactNode
}

interface PrintFile {
  id: string
  uploadAreaId: string
  locale: Locale
  shopId: string
  file: File
  type: SanityPrintType
  slotTypes: SanityPrintSlotType[]
  isOptimized: boolean
  color?: SanityPrintColor
}

interface ShopFormUploadContextProps {
  uploadQueue: PrintFile[]
  uploadErrors: ErrorMessages
  addToUploadQueue: (file: PrintFile) => void
}

interface ShopFormUploadContextProviderProps {
  children?: ReactNode
}

export const ShopContext = createContext<ShopContextProps>({
  taxRate: 0,
  shopifyDomain: '',
  shopifyPrimaryDomain: '',
  productCatalogue: [],
  printShops: [],
  printShop: null,
  setPrintShop: () => null,
})

export const ShopContextProvider = ({
  locale,
  site,
  productCatalogue,
  children,
}: ShopContextProviderProps) => {
  const router = useRouter()
  const shopifyDomain = getShopifyDomain(locale)

  const [localeInitialised, setLocaleInitialised] = useState<Locale>()
  const [currency, setCurrency] = useState<string>()
  const [currencyCode, setCurrencyCode] = useState<string>()
  const [countryCode, setCountryCode] = useState<string>()
  const [shopifyPrimaryDomain, setShopifyPrimaryDomain] =
    useState<string>(shopifyDomain)
  const [shop, setShop] = useState<ShopifyShop | null>(null)

  const [printShop, setPrintShop] = useState<SanityPrintShopBase | null>(null)

  useEffect(() => {
    // Load print shop from cookie
    const printShopCookie = getPrintShopCookie()

    if (!printShopCookie) {
      return
    }

    setPrintShop(printShopCookie)
  }, [])

  useEffect(() => {
    // Make sure that print shop cookie matches currently open print shop
    if (!printShop) {
      removePrintShopCookie()
      return
    }

    const printShopCookie = getPrintShopCookie()

    if (printShopCookie && printShopCookie.id === printShop.id) {
      return
    }

    setPrintShopCookie(printShop)

    // Update current URL when print shop changes
    const id = router.query.id as string | undefined
    const slug = router.query.slug as string | undefined
    // Note: Next.js router query does not always contain URL parameter values on page load, they are sometimes
    // filled in later, so it is safer to take them from path instead.
    const origin =
      typeof window !== 'undefined' && window.location.origin
        ? window.location.origin
        : 'https://example.com'
    const url = new URL(`${origin}${router.asPath}`)
    const variant = url.searchParams.get(variantUrlParameterName)
    const urlOptions: PageUrlOptions = {
      parameters: {
        [variantUrlParameterName]: variant ?? '',
      },
    }

    // Is shop product print page
    if (
      (router.route.startsWith('/shop/year') ||
        router.route.startsWith('/shop/team')) &&
      id &&
      slug &&
      router.route.includes('/print')
    ) {
      const shopProductPrintUrl = getPageUrl(
        PageType.SHOP_PRODUCT_PRINT,
        [printShop.type, printShop.id, slug],
        urlOptions
      )

      if (shopProductPrintUrl !== router.asPath) {
        router.push(shopProductPrintUrl)
      }
      return
    }

    // Is shop product page
    if (
      (router.route.startsWith('/shop/year') ||
        router.route.startsWith('/shop/team')) &&
      id &&
      slug &&
      !router.route.includes('/print')
    ) {
      const shopProductUrl = getPageUrl(
        PageType.SHOP_PRODUCT,
        [printShop.type, printShop.id, slug],
        urlOptions
      )

      if (shopProductUrl !== router.asPath) {
        router.push(shopProductUrl)
      }
      return
    }

    // Is shop page
    if (
      (router.route.startsWith('/shop/year') ||
        router.route.startsWith('/shop/team')) &&
      id &&
      !slug &&
      !router.route.includes('/print')
    ) {
      const shopUrl = getPageUrl(PageType.PRIVATE_SHOP_PAGE, [
        printShop.type,
        printShop.id,
      ])

      if (shopUrl !== router.asPath) {
        router.push(shopUrl)
      }
      return
    }
  }, [printShop, router])

  const shopifyStorefrontClient = getShopifyStorefrontClient(locale)

  // Get Shopify shop and cart information (update when switching language)
  useEffect(() => {
    if (localeInitialised === locale) {
      return
    }

    if (!shopifyStorefrontClient) {
      throw new Error('Shopify Storefront API client missing')
    }

    setLocaleInitialised(locale)

    const loadShop = async () => {
      const cartId = localStorage.getItem(getCartIdStorageKey(locale))
      const shopifyShop = await getShopifyShopAndCart(
        shopifyStorefrontClient,
        cartId
      )
      setShop(shopifyShop ?? null)
    }
    loadShop()
  }, [localeInitialised, locale, shopifyStorefrontClient])

  useEffect(() => {
    if (!shop) {
      return
    }

    // Remove HTML tags and value placeholder "{{amount}}" from currency format string
    setCurrency(
      shop?.shop?.moneyFormat
        ?.replace(/<\/?[^>]+(>|$)/g, '')
        ?.replace(/\s*[{]+.*?[}]+\s*/g, '')
    )
    setCurrencyCode(shop?.shop?.paymentSettings?.currencyCode)
    setCountryCode(shop?.shop?.paymentSettings?.countryCode)
    setShopifyPrimaryDomain(shop?.shop?.primaryDomain?.host ?? shopifyDomain)
  }, [shop, shopifyDomain])

  return (
    <ShopContext.Provider
      value={{
        taxRate: site.cart.taxRate ?? 0,
        currency,
        currencyCode,
        countryCode,
        shopifyDomain,
        shopifyPrimaryDomain,
        productCatalogue,
        shopifyStorefrontClient,
        printShops: site.shops,
        printShop,
        setPrintShop,
      }}
    >
      <SchoolContextProvider locale={locale} schools={site.schools}>
        <CartContextProvider shop={shop} site={site}>
          <DiscountContextProvider site={site}>
            {children}
          </DiscountContextProvider>
        </CartContextProvider>
      </SchoolContextProvider>
    </ShopContext.Provider>
  )
}

/**
 * Updates shop state using a shop action.
 */
export const shopStateReducer = (
  state: ShopState,
  action: ShopAction
): ShopState => {
  switch (action.action) {
    case ShopActionType.UPDATE_SHOP_DETAILS: {
      return {
        ...state,
        shopId: action.shopId,
        id: action.id,
        name: action.name,
        schoolId: action.schoolId,
      }
    }

    case ShopActionType.UPDATE_USER: {
      return {
        ...state,
        user: action.user,
      }
    }

    case ShopActionType.ADD_PRINT: {
      return {
        ...state,
        prints: [
          ...(state.prints ?? []),
          {
            id: action.id,
            image: action.image,
            filename: action.filename,
            title: action.title,
            slotTypes: action.slotTypes,
            type: action.type,
            color: action.color,
            isOptimized: action.isOptimized,
          },
        ],
      }
    }

    case ShopActionType.EDIT_PRINT: {
      return {
        ...state,
        prints: state.prints?.map((print) => {
          if (print.id === action.id) {
            return { ...print, title: action.title }
          }

          return print
        }),
      }
    }

    case ShopActionType.REMOVE_PRINT: {
      return {
        ...state,
        prints: state.prints?.filter((print) => print.id !== action.id),
      }
    }
  }
}

/**
 * Sends a request to create a new private shop.
 */
export const createPrivateShop = async (
  locale: Locale,
  values: CreateShopFormValues
) => {
  try {
    const shop = await axios.post<
      CreateShopResponse,
      AxiosResponse<CreateShopResponse>,
      CreateShopFormValues
    >('/api/shop/create', values, {
      headers: {
        'X-Locale': locale,
      },
    })

    return [shop.data.shop ?? null, shop.data.prints ?? null] as const
  } catch (error) {
    console.log(error)

    return [null, null] as const
  }
}

/**
 * Sends a request to create a new private shop.
 */
export const deactivatePrivateShop = async (locale: Locale, shopId: string) => {
  try {
    const response = await axios.post<
      DeactivateShopResponse,
      AxiosResponse<DeactivateShopResponse>,
      DeactivateShopInput
    >('/api/shop/deactivate', { shopId }, { headers: { 'X-Locale': locale } })

    return response.data
  } catch (error) {
    console.log(error)
    return
  }
}

export const ShopFormContext = createContext<ShopFormContextProps>({
  printPermissions: [],
  step: 1,
  setStep: () => null,
  shopState: { type: SanityPrintShopType.TEAM },
  dispatchShopState: () => null,
})

/**
 * The shop form context provider.
 */
export const ShopFormContextProvider = ({
  initialStep,
  shopType,
  printPermissions,
  shop,
  children,
}: ShopFormContextProviderProps) => {
  const [step, setStep] = useState(initialStep)
  const [shopState, dispatchShopState] = useReducer(shopStateReducer, {
    type: shopType,
    shopId: shop?.shopId,
    id: shop?.id,
    name: shop?.name,
    schoolId: shop?.school.id,
    prints: parseShopPrints(shop?.prints),
  })

  return (
    <ShopFormContext.Provider
      value={{
        printPermissions,
        step,
        setStep,
        shopState,
        dispatchShopState,
      }}
    >
      {children}
    </ShopFormContext.Provider>
  )
}

export const ShopFormUploadContext = createContext<ShopFormUploadContextProps>({
  uploadQueue: [],
  uploadErrors: {},
  addToUploadQueue: () => null,
})

/**
 * The shop form upload context provider.
 */
export const ShopFormUploadContextProvider = ({
  children,
}: ShopFormUploadContextProviderProps) => {
  const { dispatchShopState, setStep } = useContext(ShopFormContext)
  const { shopFormStrings } = useContext(ShopFormStringsContext)

  const [uploadErrors, setUploadErrors] = useState<ErrorMessages>({})
  const [uploadQueue, setUploadQueue] = useState<PrintFile[]>([])
  const [currentUploadItem, setCurrentUploadItem] = useState<PrintFile | null>(
    null
  )

  const addToUploadQueue = useCallback(
    (file: PrintFile) => {
      setUploadQueue((currentUploadQueue) => [...currentUploadQueue, file])

      // Go to step 2 when uploading any files
      setStep(2)
    },
    [setStep]
  )

  useEffect(() => {
    // Upload files one at a time
    if (currentUploadItem || uploadQueue.length === 0) {
      return
    }

    const processFile = async () => {
      const newCurrentUploadItem = uploadQueue[0]
      setCurrentUploadItem(newCurrentUploadItem)

      // Remove upload error
      const uploadAreaId = newCurrentUploadItem.uploadAreaId
      setUploadErrors((currentUploadErrors) => ({
        ...currentUploadErrors,
        [uploadAreaId]: '',
      }))

      // Add values and image file to form data
      const formData = new FormData()
      formData.append('shopId', newCurrentUploadItem.shopId)
      formData.append('slotTypes', newCurrentUploadItem.slotTypes.join(','))
      formData.append('type', newCurrentUploadItem.type)
      if (newCurrentUploadItem.color) {
        formData.append('color', newCurrentUploadItem.color)
      }
      if (newCurrentUploadItem.isOptimized) {
        formData.append('isOptimized', 'yes')
      }
      formData.append('file', newCurrentUploadItem.file)

      const uploadPrintResult = await uploadPrints(
        newCurrentUploadItem.locale,
        formData,
        shopFormStrings.shopFormUploadPrintError,
        shopFormStrings.shopFormUploadPrintSizeError?.replace(
          /{maximum_size}/gi,
          `${Math.round(maxPrintFileSize / 1024 / 1024)}MB`
        )
      )

      // Add successfully uploaded prints to the list
      uploadPrintResult.prints.forEach((uploadedPrint) =>
        dispatchShopState({
          action: ShopActionType.ADD_PRINT,
          id: uploadedPrint.id,
          image: uploadedPrint.image,
          filename: newCurrentUploadItem.file.name,
          title: uploadedPrint.title,
          slotTypes: newCurrentUploadItem.slotTypes,
          type: newCurrentUploadItem.type,
          color: newCurrentUploadItem.color,
          isOptimized: newCurrentUploadItem.isOptimized,
        })
      )

      // Set upload error
      setUploadErrors((currentUploadErrors) => ({
        ...currentUploadErrors,
        [uploadAreaId]: uploadPrintResult.errorMessage ?? '',
      }))

      // Remove file from the queue
      setUploadQueue((currentUploadQueue) =>
        currentUploadQueue.filter((item) => item.id !== newCurrentUploadItem.id)
      )
      setCurrentUploadItem(null)
    }
    processFile()
  }, [currentUploadItem, dispatchShopState, shopFormStrings, uploadQueue])

  return (
    <ShopFormUploadContext.Provider
      value={{
        uploadQueue,
        uploadErrors,
        addToUploadQueue,
      }}
    >
      {children}
    </ShopFormUploadContext.Provider>
  )
}

const PRINT_SHOP_COOKIE_KEY = 'printShop'

export const getPrintShopCookie = () => {
  const printShopCookie = getCookie(PRINT_SHOP_COOKIE_KEY)

  return printShopCookie ? JSON.parse(printShopCookie as string) : null
}

export const setPrintShopCookie = (printShop: SanityPrintShopBase) =>
  setCookie(PRINT_SHOP_COOKIE_KEY, printShop, {
    expires: new Date(Date.now() + 31 * 24 * 60 * 60 * 1000), // Month
  })

export const removePrintShopCookie = () => deleteCookie(PRINT_SHOP_COOKIE_KEY)

export const useSetPrintShop = (newPrintShop?: SanityPrintShopBase) => {
  const { setPrintShop } = useContext(ShopContext)

  useEffect(() => {
    if (newPrintShop) {
      setPrintShop(newPrintShop)
      setPrintShopCookie(newPrintShop)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
