import {
  AttributeInput,
  CheckoutLineItemInput,
  MailingAddressInput,
} from '@data/shopify/storefront/types'
import { SanitySchool } from '@data/sanity/queries/types/school'
import { SanitySiteStrings } from '@data/sanity/queries/types/strings'
import { shippableProductTypes } from '@lib/sanity/product'
import { LineItem, LineItemAttribute, ShippingType } from '@lib/cart'
import { compareNumbers } from '@lib/helpers'
import { defaultCountryCodes, englishCountryNames, Locale } from '@lib/language'
import { trimUnderscores } from '@lib/strings'
import { ShopifyAddress } from './address'
import { CartLineItemAttribute } from './cart'
import { ShopifyCustomer } from './customer'
import { getAssetFromShopify } from './file'

export interface ShopifyCheckoutNoteAttribute {
  name: string
  value: string
}

interface ShopifyCheckoutLineItemProperty {
  name: string
  value: string
}

interface ShopifyCheckoutLineItemUnitPriceMeasurement {
  measured_type?: string
  quantity_value?: string
  quantity_unit?: string
  reference_value?: string
  reference_unit?: string
}

export interface ShopifyCheckoutLineItem {
  applied_discounts: unknown[]
  discount_allocations: unknown[]
  key: string
  destination_location_id?: number
  fulfillment_service: string
  gift_card: boolean
  grams: number
  origin_location_id: number
  presentment_title: string
  presentment_variant_title: string
  product_id: number
  properties?: ShopifyCheckoutLineItemProperty[]
  quantity: number
  requires_shipping: boolean
  sku: string
  tax_lines: unknown[]
  taxable: boolean
  title: string
  variant_id?: number
  variant_title?: string
  variant_price?: string
  vendor?: string
  user_id?: string
  unit_price_measurement?: ShopifyCheckoutLineItemUnitPriceMeasurement
  rank?: string
  compare_at_price?: string
  line_price: string
  price: string
}

export interface ShopifyCheckout {
  id: number
  token: string
  cart_token: string
  email?: string
  gateway?: string
  buyer_accepts_marketing: boolean
  created_at: string
  updated_at: string
  landing_site?: string
  note?: string
  note_attributes?: ShopifyCheckoutNoteAttribute[]
  referring_site?: string
  shipping_lines: unknown[]
  taxes_included: boolean
  total_weight: number
  currency: string
  completed_at?: string
  closed_at?: string
  user_id?: string
  location_id?: string
  source_identifier?: string
  source_url?: string
  device_id?: string
  phone?: string
  customer_locale?: string
  line_items: ShopifyCheckoutLineItem[]
  name: string
  source?: string
  abandoned_checkout_url: string
  discount_codes: unknown[]
  tax_lines: unknown[]
  source_name: string
  presentment_currency: string
  buyer_accepts_sms_marketing: boolean
  sms_marketing_phone?: string
  total_discounts: string
  total_line_items_price: string
  total_price: string
  total_tax: string
  subtotal_price: string
  total_duties?: string
  billing_address?: ShopifyAddress
  shipping_address?: ShopifyAddress
  customer?: ShopifyCustomer
}

/**
 * Gets cart attributes for checkout.
 */
export const getCheckoutCartAttributes = (cartId: string): AttributeInput[] => [
  {
    key: `_${CartLineItemAttribute.CART_ID}`,
    value: cartId,
  },
]

/**
 * Gets checkout line item attribute names.
 */
const getCheckoutLineItemAttributeNames = (
  strings: SanitySiteStrings
): Record<string, string | undefined> => ({
  // Private names
  [CartLineItemAttribute.IMAGE_ASSET_URL]: `_${CartLineItemAttribute.ASSET_URL}`,
  [CartLineItemAttribute.LOGO_ASSET_URL]: `_${CartLineItemAttribute.ASSET_URL}`,
  // Public names
  [CartLineItemAttribute.ASSET_FILENAME]:
    strings.cartAssetFilenameAttributeName,
  [CartLineItemAttribute.FREETEXT]: strings.cartFreetextAttributeName,
  [CartLineItemAttribute.FREETEXT_COLOR]:
    strings.cartFreetextColorAttributeName,
  [CartLineItemAttribute.IMAGE_ASSET_COLOR]:
    strings.cartAssetColorAttributeName,
  [CartLineItemAttribute.IMAGE_ASSET_TITLE]:
    strings.cartAssetTitleAttributeName,
  [CartLineItemAttribute.LOGO_ASSET_COLOR]: strings.cartAssetColorAttributeName,
  [CartLineItemAttribute.LOGO_ASSET_TITLE]: strings.cartAssetTitleAttributeName,
  [CartLineItemAttribute.PRODUCT_NAME]: strings.cartProductNameAttributeName,
  [CartLineItemAttribute.SHOP_ID]: strings.cartShopIdAttributeName,
})

/**
 * Determines whether item attributes indicate that it is a fee item.
 */
const getIsFeeProduct = (attributes: LineItemAttribute[]) =>
  attributes.some((attribute) =>
    (
      [
        CartLineItemAttribute.ASSET_FILENAME,
        CartLineItemAttribute.FREETEXT,
        CartLineItemAttribute.FREETEXT_COLOR,
        CartLineItemAttribute.IMAGE_ASSET_COLOR,
        CartLineItemAttribute.IMAGE_ASSET_TITLE,
        CartLineItemAttribute.LOGO_ASSET_COLOR,
        CartLineItemAttribute.LOGO_ASSET_TITLE,
      ] as string[]
    ).includes(trimUnderscores(attribute.key))
  )

/**
 * Gets product title for a fee line item.
 */
const getFeeItemProductTitle = (
  lineItems: LineItem[],
  attributes: LineItemAttribute[]
) => {
  const variantId = attributes.find(
    (attribute) =>
      trimUnderscores(attribute.key) ===
      CartLineItemAttribute.PRODUCT_VARIANT_ID
  )?.value

  if (!variantId) {
    return null
  }

  const lineItem = lineItems.find((lineItem) => `${lineItem.id}` === variantId)

  if (!lineItem) {
    return null
  }

  return lineItem.product.title
}

/**
 * Convert asset file ID attributes into asset URL attributes.
 */
const convertFileIdAttributes = async (
  locale: Locale,
  attributes: LineItemAttribute[]
) => {
  let convertedAttributes = [...attributes]

  // Find logo file ID
  const logoFileId = convertedAttributes.find(
    ({ key }) => key === `_${CartLineItemAttribute.LOGO_ASSET_FILE_ID}`
  )?.value
  const logoAssetUrl = logoFileId
    ? await getAssetFromShopify(locale, logoFileId)
    : null

  if (logoAssetUrl) {
    // Create or overwrite logo URL attribute
    convertedAttributes = convertedAttributes.filter(
      ({ key }) => key !== `_${CartLineItemAttribute.LOGO_ASSET_URL}`
    )
    convertedAttributes.push({
      key: `_${CartLineItemAttribute.LOGO_ASSET_URL}`,
      value: logoAssetUrl,
    })
  }

  // Find image file ID
  const imageFileId = convertedAttributes.find(
    ({ key }) => key === `_${CartLineItemAttribute.IMAGE_ASSET_FILE_ID}`
  )?.value
  const imageAssetUrl = imageFileId
    ? await getAssetFromShopify(locale, imageFileId)
    : null

  if (imageAssetUrl) {
    // Create or overwrite image URL attribute
    convertedAttributes = convertedAttributes.filter(
      ({ key }) => key !== `_${CartLineItemAttribute.IMAGE_ASSET_URL}`
    )
    convertedAttributes.push({
      key: `_${CartLineItemAttribute.IMAGE_ASSET_URL}`,
      value: imageAssetUrl,
    })
  }

  return convertedAttributes
}

/**
 * Gets product name line item attribute.
 */
const getProductNameAttribute = (
  lineItems: LineItem[],
  attributes: LineItemAttribute[]
): AttributeInput | null => {
  const productTitle = getFeeItemProductTitle(lineItems, attributes)

  if (!productTitle) {
    return null
  }

  return {
    key: `_${CartLineItemAttribute.PRODUCT_NAME}`,
    value: productTitle,
  }
}

/**
 * Filters checkout line item attrbiutes using whitelists.
 */
const filterCheckoutLineItemAttributes = (attribute: LineItemAttribute) => {
  const checkoutPublicLineItemAttributeWhitelist: string[] = [
    // All attributes that have a public name
    CartLineItemAttribute.ASSET_FILENAME,
    CartLineItemAttribute.FREETEXT,
    CartLineItemAttribute.FREETEXT_COLOR,
    CartLineItemAttribute.IMAGE_ASSET_COLOR,
    CartLineItemAttribute.IMAGE_ASSET_TITLE,
    CartLineItemAttribute.LOGO_ASSET_COLOR,
    CartLineItemAttribute.LOGO_ASSET_TITLE,
    CartLineItemAttribute.PRODUCT_NAME,
    CartLineItemAttribute.SHOP_ID,
  ]
  const checkoutPrivateLineItemAttributeWhitelist: string[] = [
    // Carrier service shipping information
    CartLineItemAttribute.SHIPPING_TYPE,
    CartLineItemAttribute.SCHOOL_ID,
    // Fee product URLs
    CartLineItemAttribute.IMAGE_ASSET_URL,
    CartLineItemAttribute.LOGO_ASSET_URL,
    // Fee product linking to print products for packing slip
    CartLineItemAttribute.PRODUCT_VARIANT_ID,
    // Customer type information
    CartLineItemAttribute.TAX_EXEMPT,
    CartLineItemAttribute.TAX_VAT_ID,
    // PartnerAds data
    CartLineItemAttribute.PARTNER_ADS_CLICK_ID,
    CartLineItemAttribute.PARTNER_ADS_CLICK_ID2,
    CartLineItemAttribute.PARTNER_ADS_ID,
  ]
  const key = trimUnderscores(attribute.key)

  return (
    checkoutPublicLineItemAttributeWhitelist.includes(key) ||
    checkoutPrivateLineItemAttributeWhitelist.includes(key)
  )
}

/**
 * Filters checkout line item fee product attrbiutes using a blacklist.
 */
const filterCheckoutLineItemFeeProductAttributes = (
  attribute: LineItemAttribute
) => {
  const checkoutPrivateFeeLineItemAttributeBlacklist: string[] = [
    CartLineItemAttribute.SCHOOL_ID,
    CartLineItemAttribute.SHIPPING_TYPE,
    CartLineItemAttribute.SHOP_ID,
  ]
  const key = trimUnderscores(attribute.key)

  return !checkoutPrivateFeeLineItemAttributeBlacklist.includes(key)
}

/**
 * Updates checkout line item attribute keys.
 */
const mapCheckoutLineItemAttribute = (
  strings: SanitySiteStrings,
  attribute: LineItemAttribute
): AttributeInput => {
  // Get attribute name from key
  const trimmedKey = trimUnderscores(attribute.key)
  const checkoutLineItemAttributeNames =
    getCheckoutLineItemAttributeNames(strings)
  const attributeName = checkoutLineItemAttributeNames[trimmedKey]

  return {
    key: attributeName ?? attribute.key,
    value: attribute.value,
  }
}

/**
 * Sorts attributes by privacy.
 */
const sortCheckoutLineItemAttributes = (
  attribute1: AttributeInput,
  attribute2: AttributeInput
) =>
  compareNumbers(
    attribute1.key[0] === '_' ? 1 : 0,
    attribute2.key[0] === '_' ? 1 : 0
  )

/**
 * Parses cart line item attributes into checkout line item attributes.
 */
const parseCheckoutLineItemAttributes = async (
  locale: Locale,
  strings: SanitySiteStrings,
  lineItems: LineItem[],
  attributes?: LineItemAttribute[]
) => {
  if (!attributes) {
    return null
  }

  // Convert file ID attributes to file URL attributes
  const convertedAttributes = await convertFileIdAttributes(locale, attributes)

  const isFeeProduct = getIsFeeProduct(convertedAttributes)
  const productNameAttribute = isFeeProduct
    ? getProductNameAttribute(lineItems, convertedAttributes)
    : null

  // Filter cart line item attributes
  let filteredAttributes = convertedAttributes.filter(
    (attribute) =>
      !isFeeProduct || filterCheckoutLineItemFeeProductAttributes(attribute)
  )
  filteredAttributes = filteredAttributes.filter((attribute) =>
    filterCheckoutLineItemAttributes(attribute)
  )

  if (productNameAttribute) {
    filteredAttributes = [productNameAttribute, ...filteredAttributes]
  }

  // Convert cart line item attributes to checkout line iten attributes
  const checkoutLineItemAttributes = await Promise.all(
    filteredAttributes.map(async (attribute) => {
      return await mapCheckoutLineItemAttribute(strings, attribute)
    })
  )

  return checkoutLineItemAttributes.sort((attribute1, attribute2) =>
    sortCheckoutLineItemAttributes(attribute1, attribute2)
  )
}

/**
 * Parses cart line items into checkout line items.
 */
export const parseCheckoutLineItems = async (
  locale: Locale,
  strings: SanitySiteStrings,
  lineItems: LineItem[]
): Promise<CheckoutLineItemInput[]> => {
  return await Promise.all(
    lineItems.map(async (lineItem) => ({
      variantId: lineItem.merchandiseId,
      quantity: lineItem.quantity,
      customAttributes: await parseCheckoutLineItemAttributes(
        locale,
        strings,
        lineItems,
        lineItem.attributes
      ),
    }))
  )
}

/**
 * Gets note value for checkout.
 */
export const getCheckoutNote = (
  strings: SanitySiteStrings,
  comment: string,
  school: SanitySchool | null
) => {
  const noteLines: string[] = []

  if (comment) {
    noteLines.push(strings.cartCommentLabel)
    noteLines.push(comment)
  }

  if (school) {
    noteLines.push(strings.cartSchoolLabel)
    noteLines.push(school.name)
    noteLines.push(school.address)
    noteLines.push(school.city)
    noteLines.push(`${school.postalCode}`)
  }

  return noteLines.join('\n')
}

/**
 * Gets shipping address for checkout.
 */
export const getCheckoutShippingAddress = (
  locale: Locale,
  lineItems: LineItem[],
  shippingType: ShippingType,
  school: SanitySchool | null
): MailingAddressInput | null => {
  const hasShippableProducts = lineItems.some(({ product }) =>
    shippableProductTypes.includes(product.type)
  )

  // Check if cart has any shippable products, shipping type is school pickup and a school is found
  if (
    !hasShippableProducts ||
    shippingType !== ShippingType.PICKUP_SCHOOL ||
    !school
  ) {
    return null
  }

  return {
    address1: school.address,
    company: school.name,
    city: school.city,
    country: englishCountryNames[defaultCountryCodes[locale]],
    zip: `${school.postalCode}`,
  }
}
