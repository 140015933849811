import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  SanityBundleDiscount,
  SanityDiscount,
  SanityDiscountProduct,
  SanityDiscountType,
  SanityQuantityDiscount,
  SanitySiteFragment,
} from '@data/sanity/queries/types/site'
import { CartContext, LineItem, VariantLineItem } from './cart'
import { compareNumbers, filterDuplicates } from './helpers'
import { sanityProductIdToShopifyId } from './product'

type ValueCounts = Record<string, number>

interface QuantityDiscount {
  id: string
  title: string
  discountValuePercent: number
  minimumQuantity: number
  maximumQuantity: number
}

interface BundleDiscount {
  id: string
  title: string
  discountValuePercent: number
  productQuantity: number
  doNotStackWithQuantityDiscounts: boolean
  variantGroups: string[]
}

interface BundleDiscountWithVariants extends BundleDiscount {
  variantIds: number[]
}

export interface DiscountItem {
  id: string
  type: SanityDiscountType
  title: string
  amount: number
  quantity: number
  discountValuePercent: number
}

export interface DiscountsByType {
  quantityDiscounts: QuantityDiscount[]
  bundleDiscounts: BundleDiscount[]
}

interface DiscountContextProps {
  discounts: DiscountsByType | null
  cartDiscountItems: DiscountItem[] | null
}

export const DiscountContext = createContext<DiscountContextProps>({
  discounts: null,
  cartDiscountItems: null,
})

interface DiscountContextProviderProps {
  site: SanitySiteFragment
  children: ReactNode
}

export const DiscountContextProvider = ({
  site,
  children,
}: DiscountContextProviderProps) => {
  const { cart } = useContext(CartContext)

  const discounts = useMemo<DiscountsByType>(
    () => ({
      quantityDiscounts: getQuantityDiscounts(site.discounts),
      bundleDiscounts: getBundleDiscounts(site.discounts),
    }),
    [site.discounts]
  )
  const [cartDiscountItems, setCartDiscountItems] = useState<
    DiscountItem[] | null
  >(null)

  // Calculate cart item discounts
  useEffect(() => {
    // Wait for cart to load
    if (!cart.id) {
      return
    }

    setCartDiscountItems(getCartDiscountItems(discounts, cart.lineItems))
  }, [cart.id, cart.lineItems, discounts])

  return (
    <DiscountContext.Provider value={{ discounts, cartDiscountItems }}>
      {children}
    </DiscountContext.Provider>
  )
}

/**
 * Gets quantity discounts from all discounts.
 */
export const getQuantityDiscounts = (
  discounts: SanityDiscount[]
): QuantityDiscount[] =>
  (
    discounts.filter(
      ({ type }) => type === SanityDiscountType.QUANTITY
    ) as SanityQuantityDiscount[]
  ).map(
    ({
      _id,
      title,
      discountValuePercent,
      minimumQuantity,
      maximumQuantity,
    }) => ({
      id: _id,
      title,
      discountValuePercent,
      minimumQuantity,
      maximumQuantity,
    })
  )

/**
 * Gets bundle discounts from all discounts.
 */
export const getBundleDiscounts = (
  discounts: SanityDiscount[]
): BundleDiscount[] =>
  (
    discounts.filter(
      ({ type }) => type === SanityDiscountType.BUNDLE
    ) as SanityBundleDiscount[]
  ).map(
    ({
      _id,
      title,
      discountValuePercent,
      products,
      doNotStackWithQuantityDiscounts,
    }) => ({
      id: _id,
      title,
      discountValuePercent,
      productQuantity: products.length,
      doNotStackWithQuantityDiscounts: !!doNotStackWithQuantityDiscounts,
      variantGroups: getBundleDiscountVariantGroups(products),
    })
  )

/**
 * Finds active quantity discount by quantity.
 */
const getActiveQuantityDiscount = (
  quantityDiscounts: QuantityDiscount[],
  quantity: number
): QuantityDiscount | null =>
  quantityDiscounts
    .filter(
      ({ minimumQuantity }) => !minimumQuantity || quantity >= minimumQuantity
    )
    .filter(
      ({ maximumQuantity }) => !maximumQuantity || quantity <= maximumQuantity
    )
    // Get the discount with the highest discount value
    .reduce<QuantityDiscount | null>(
      (maximumDiscount, discount) =>
        maximumDiscount &&
        maximumDiscount.discountValuePercent > discount.discountValuePercent
          ? maximumDiscount
          : discount,
      null
    )

/**
 * Gets groups of variant IDs joined in a string (e.g., ["v1,v2", "v1,v3", ...]).
 */
const getBundleDiscountVariantGroups = (
  products: SanityDiscountProduct[]
): string[] => {
  // Get variant IDs grouped by products
  const variantsByProduct: number[][] = []
  products.forEach(({ variantIds }) => {
    const variants: number[] = []

    variantIds.forEach((variantId) => {
      const productVariantShopifyId = sanityProductIdToShopifyId(variantId)

      if (productVariantShopifyId) {
        variants.push(productVariantShopifyId)
      }
    })

    variantsByProduct.push(variants)
  })

  // Create a group of all possible variant mappings
  const productQuantity = products.length ?? 0
  const getVariantGroups = (productIndex = 0): number[][] => {
    if (productIndex >= productQuantity - 1) {
      return variantsByProduct[productIndex].map((variantId) => [variantId])
    }

    const result: number[][] = []
    const variantGroups = getVariantGroups(productIndex + 1)

    variantsByProduct[productIndex].forEach((variantId) => {
      variantGroups.forEach((variantIds) => {
        result.push([variantId, ...variantIds])
      })
    })

    return result
  }

  // Sort and merge variant IDs in the group
  return getVariantGroups().map((variantIds) =>
    variantIds.sort(compareNumbers).join(',')
  )
}

/**
 * Gets all possible variant variations with given quantity of items.
 */
const getAllVariantGroups = (
  unusedVariantIdCounts: ValueCounts,
  quantity: number
): string[] => {
  const getVariantGroups = (
    availableValueCounts: ValueCounts,
    values: number[] = [],
    index = 0
  ): number[][] => {
    const availableValues = Object.entries(availableValueCounts)
      .filter((availableValueCount) => availableValueCount[1] > 0)
      .map((availableValueCount) => Number(availableValueCount[0]))

    // Check if this is the last index
    if (index >= quantity - 1) {
      const results: number[][] = []

      availableValues.forEach((value) => results.push([value]))

      return results
    }

    const mergedResults: number[][] = []

    availableValues.forEach((value) => {
      const newAvailableValueCounts = { ...availableValueCounts }
      const newValues = [...values]

      newAvailableValueCounts[`${value}`] -= 1
      newValues.push(value)

      getVariantGroups(newAvailableValueCounts, newValues, index + 1).forEach(
        (valueVariation) => mergedResults.push([value, ...valueVariation])
      )
    })

    return mergedResults
  }

  // Merge values into a string, filter only unique results
  return getVariantGroups(unusedVariantIdCounts)
    .map((values) => values.sort(compareNumbers).join(','))
    .filter((values, index, array) => filterDuplicates(values, index, array))
}

/**
 * Gets value counts from an array of values (e.g., { item1: 3, item2: 1, ... }).
 */
function getValueCounts<T = string | number>(values: T[]) {
  const valueCounts: ValueCounts = {}

  values.forEach((value) => {
    valueCounts[`${value}`] = (valueCounts?.[`${value}`] ?? 0) + 1
  })

  return valueCounts
}

/**
 * .
 */
const getMatchingBundleDiscount =
  (
    allVariantGroups: Record<number, string[]>,
    unusedVariantIdCounts: ValueCounts
  ) =>
  (bundleDiscount: BundleDiscount) => {
    const quantity = bundleDiscount.productQuantity

    if (!allVariantGroups[quantity]) {
      allVariantGroups[quantity] = getAllVariantGroups(
        unusedVariantIdCounts,
        quantity
      )
    }

    // Check if bundle contains any of the possible variant variations
    const matchingVariantGroup = allVariantGroups[quantity].find(
      (variantGroup) => bundleDiscount.variantGroups.includes(variantGroup)
    )

    if (!matchingVariantGroup) {
      return null
    }

    return {
      ...bundleDiscount,
      variantIds: matchingVariantGroup
        .split(',')
        .map((variantId) => Number(variantId)),
    }
  }

/**
 * Gets discount amounts for bundle discounts.
 */
const getBundleDiscountAmounts =
  (variantLineItems: VariantLineItem[]) =>
  (matchedBundleDiscount: BundleDiscountWithVariants) => {
    // Get variant line items from bundle variant IDs
    const matchedVariantLineItems = matchedBundleDiscount.variantIds
      .map((variantId) => variantLineItems.find(({ id }) => id === variantId))
      .filter(Boolean) as VariantLineItem[]

    const totalPrice = matchedVariantLineItems.reduce(
      (total, { price }) => total + price,
      0
    )

    return {
      id: matchedBundleDiscount.id,
      amount: totalPrice * (matchedBundleDiscount.discountValuePercent / 100),
    }
  }

/**
 * Gets the next active bundle discount.
 */
const getNextActiveBundleDiscount = (
  bundleDiscounts: BundleDiscount[],
  variantLineItems: VariantLineItem[],
  unusedVariantIds: number[]
): BundleDiscountWithVariants | null => {
  const minimumProductQuantity = Math.min(
    ...bundleDiscounts.map(({ productQuantity }) => productQuantity)
  )

  // Check if there are not enough unused variants to potentially form a bundle
  if (unusedVariantIds.length < minimumProductQuantity) {
    return null
  }

  // Find all possible bundle discounts from given variants
  const allVariantGroups: Record<number, string[]> = {}
  const unusedVariantIdCounts = getValueCounts(unusedVariantIds)
  const matchedBundleDiscounts = bundleDiscounts
    .map(getMatchingBundleDiscount(allVariantGroups, unusedVariantIdCounts))
    .filter(Boolean) as BundleDiscountWithVariants[]

  if (matchedBundleDiscounts.length < 2) {
    return matchedBundleDiscounts[0] ?? null
  }

  // Calculate discount amounts for found bundle discounts and sort them by amount in descending order
  const discountAmounts = matchedBundleDiscounts.map(
    getBundleDiscountAmounts(variantLineItems)
  )
  discountAmounts.sort(
    ({ amount: amount1 }, { amount: amount2 }) => amount2 - amount1
  )

  // Return the bundle with the largest discount amount
  return (
    matchedBundleDiscounts.find(({ id }) => id === discountAmounts[0].id) ??
    matchedBundleDiscounts[0]
  )
}

/**
 * Gets all active bundle discounts for given variant IDs.
 */
const getActiveBundleDiscounts = (
  bundleDiscounts: BundleDiscount[],
  variantLineItems: VariantLineItem[],
  limit = 1000
): BundleDiscountWithVariants[] => {
  const variantIds = variantLineItems
    .map(({ id, quantity }) => new Array(quantity).fill(null).map(() => id))
    .flat(1)
  const unusedVariantIds = [...variantIds]
  const activeBundleDiscounts: BundleDiscountWithVariants[] = []
  let count = 0

  // Find the next active bundle discount
  let activeBundleDiscount = getNextActiveBundleDiscount(
    bundleDiscounts,
    variantLineItems,
    unusedVariantIds
  )

  while (activeBundleDiscount) {
    activeBundleDiscounts.push(activeBundleDiscount)

    // Remove variant IDs from unused variants
    activeBundleDiscount.variantIds.forEach((variantId) => {
      const index = unusedVariantIds.indexOf(variantId)

      if (index > -1) {
        unusedVariantIds.splice(index, 1)
      }
    })

    // Limit the total count
    count += 1

    if (count >= limit) {
      break
    }

    // Find the next active bundle discount
    activeBundleDiscount = getNextActiveBundleDiscount(
      bundleDiscounts,
      variantLineItems,
      unusedVariantIds
    )
  }

  return activeBundleDiscounts
}

/**
 * Gets quantity discount items for variant line items.
 */
export const getQuantityDiscountItems = (
  quantityDiscounts: QuantityDiscount[],
  variantLineItems: VariantLineItem[]
): DiscountItem[] => {
  const totalQuantity = variantLineItems.reduce(
    (total, { quantity }) => total + quantity,
    0
  )
  const activeQuantityDiscount = getActiveQuantityDiscount(
    quantityDiscounts,
    totalQuantity
  )

  if (!activeQuantityDiscount) {
    return []
  }

  const totalPrice = variantLineItems.reduce(
    (total, { price, quantity }) => total + price * quantity,
    0
  )

  return [
    {
      id: activeQuantityDiscount.id,
      type: SanityDiscountType.QUANTITY,
      title: activeQuantityDiscount.title,
      amount: totalPrice * (activeQuantityDiscount.discountValuePercent / 100),
      quantity: 1,
      discountValuePercent: activeQuantityDiscount.discountValuePercent,
    },
  ]
}

/**
 * Gets bundle discount items for variant line items.
 */
export const getBundleDiscountItems = (
  bundleDiscounts: BundleDiscount[],
  variantLineItems: VariantLineItem[]
): DiscountItem[] => {
  const discountItems: DiscountItem[] = []

  // Get active bundle discounts
  const activeBundleDiscounts = getActiveBundleDiscounts(
    bundleDiscounts,
    variantLineItems
  )

  activeBundleDiscounts.forEach((activeBundleDiscount) => {
    // Do not add duplicate bundles (use quantity instead)
    if (discountItems.some(({ id }) => id === activeBundleDiscount.id)) {
      return
    }

    // Get the total price of bundle variants
    const bundleItemPrice = variantLineItems
      .filter(({ id }) => activeBundleDiscount.variantIds.includes(id))
      .reduce((total, { price }) => total + price, 0)
    const discountAmount =
      bundleItemPrice * (activeBundleDiscount.discountValuePercent / 100)

    const discountQuantity = activeBundleDiscounts.filter(
      ({ id }) => id === activeBundleDiscount.id
    ).length

    discountItems.push({
      id: activeBundleDiscount.id,
      type: SanityDiscountType.BUNDLE,
      title: activeBundleDiscount.title,
      amount: discountAmount,
      quantity: discountQuantity,
      discountValuePercent: activeBundleDiscount.discountValuePercent,
    })
  })

  return discountItems
}

/**
 * Gets discounts items for that apply to given variants.
 */
export const getDiscountItems = (
  discounts: DiscountsByType,
  variantLineItems: VariantLineItem[]
) => {
  const quantityDiscountItems = getQuantityDiscountItems(
    discounts.quantityDiscounts,
    variantLineItems
  )

  // Filter bundle discounts that do not stack with quantity discounts
  const bundleDiscounts = discounts.bundleDiscounts.filter(
    (bundleDiscount) =>
      !bundleDiscount.doNotStackWithQuantityDiscounts ||
      quantityDiscountItems.length === 0
  )
  const bundleDiscountItems = getBundleDiscountItems(
    bundleDiscounts,
    variantLineItems
  )

  return [...quantityDiscountItems, ...bundleDiscountItems]
}

/**
 * Gets discount items that apply to the cart.
 */
export const getCartDiscountItems = (
  discounts: DiscountsByType,
  lineItems: LineItem[]
): DiscountItem[] => {
  const variantLineItems: VariantLineItem[] = lineItems.map(
    ({ id, price, quantity }) => ({ id, price, quantity })
  )

  return getDiscountItems(discounts, variantLineItems)
}
