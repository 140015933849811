import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'

import {
  PublicSiteSettings,
  SanityProductCount,
  SanitySiteFragment,
} from '@data/sanity/queries/types/site'
import { SanityVideo } from '@data/sanity/queries/types/video'
import { isBrowser } from '@lib/helpers'

type MegaNavUpdateState = boolean | 'toggle'

interface SiteContextMegaNavigation {
  isOpen: boolean
  activeId?: string
}

interface SiteContextVideoModal {
  isOpen: boolean
  video?: SanityVideo
}

interface SiteContextMobileMenu {
  isOpen: boolean
}

interface SiteContextProps {
  isPageTransition: boolean
  megaNavigation: SiteContextMegaNavigation
  mobileMenu: SiteContextMobileMenu
  videoModal: SiteContextVideoModal
  settings: PublicSiteSettings | null
  togglePageTransition: (newState: boolean) => void
  toggleMegaNavigation: (updateState: MegaNavUpdateState, id?: string) => void
  toggleMobileMenu: (isOpen: boolean) => void
  toggleVideoModal: (isOpen: boolean, video?: SanityVideo) => void
  getProductCount: (collectionSlug?: string) => number
}

const initialSiteContext: SiteContextProps = {
  isPageTransition: false,
  megaNavigation: {
    isOpen: false,
  },
  mobileMenu: {
    isOpen: false,
  },
  videoModal: {
    isOpen: false,
  },
  settings: null,
  togglePageTransition: () => null,
  toggleMegaNavigation: () => null,
  toggleMobileMenu: () => null,
  toggleVideoModal: () => null,
  getProductCount: () => 0,
}

interface SiteContextProviderProps {
  site: SanitySiteFragment
  children: ReactNode
}

export const SiteContext = createContext<SiteContextProps>(initialSiteContext)

export const SiteContextProvider = ({
  site,
  children,
}: SiteContextProviderProps) => {
  // State variables
  const [isPageTransition, setIsPageTransition] = useState<boolean>(
    initialSiteContext.isPageTransition
  )
  const [mobileMenu, setMobileMenu] = useState<SiteContextMobileMenu>(
    initialSiteContext.mobileMenu
  )
  const [megaNavigation, setMegaNavigation] =
    useState<SiteContextMegaNavigation>(initialSiteContext.megaNavigation)
  const [productCounts, setProductCounts] = useState<SanityProductCount[]>(
    site.productCounts
  )
  const [videoModal, setVideoModal] = useState<SiteContextVideoModal>(
    initialSiteContext.videoModal
  )
  const [settings, setSettings] = useState<PublicSiteSettings>(site.settings)

  // Update callbacks
  const togglePageTransition = useCallback(
    (newState: boolean) => setIsPageTransition(newState),
    []
  )

  const toggleMegaNavigation = useCallback(
    (updateState: MegaNavUpdateState, id?: string) => {
      const getActiveId = (newState: boolean, id?: string) => {
        if (newState) {
          return id
        }
      }

      const isOpen =
        updateState === 'toggle' ? !megaNavigation.isOpen : updateState
      const activeId = getActiveId(isOpen, id)

      if (
        megaNavigation.isOpen !== isOpen ||
        megaNavigation.activeId !== activeId
      ) {
        setMegaNavigation({ isOpen, activeId })
      }
    },
    [megaNavigation]
  )

  const toggleMobileMenu = useCallback(
    (isOpen: boolean) => {
      if (mobileMenu.isOpen !== isOpen) {
        setMobileMenu({
          isOpen,
        })
      }

      if (isBrowser) {
        document.body.classList.toggle('overflow-hidden', isOpen)
      }
    },
    [mobileMenu.isOpen]
  )

  const toggleVideoModal = useCallback(
    (isOpen: boolean, video?: SanityVideo) => {
      setVideoModal({ isOpen, video })
    },
    []
  )

  const getProductCount = useCallback(
    (collectionSlug?: string) =>
      productCounts?.find(({ slug }) => slug === collectionSlug)?.count ?? 0,
    [productCounts]
  )

  // Watch changes in site values (when switching language)
  useEffect(() => setProductCounts(site.productCounts), [site.productCounts])
  useEffect(() => setSettings(site.settings), [site.settings])

  return (
    <SiteContext.Provider
      value={{
        isPageTransition,
        megaNavigation,
        mobileMenu,
        videoModal,
        settings,
        togglePageTransition,
        toggleMegaNavigation,
        toggleMobileMenu,
        toggleVideoModal,
        getProductCount,
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}
