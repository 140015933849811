import cx from 'classnames'

interface DrawerBackdropProps {
  isOpen: boolean
  onClick?: () => void
}

const DrawerBackdrop = ({ isOpen, onClick }: DrawerBackdropProps) => (
  <div
    className={cx(
      'fixed inset-0 z-80 bg-black bg-opacity-40 pointer-events-none',
      'transition-opacity duration-150 ease-linear',
      {
        'opacity-0': !isOpen,
        'pointer-events-auto opacity-100 backdrop-blur-[6px]': isOpen,
      }
    )}
    onClick={onClick}
    onBeforeInput={onClick}
    role="presentation"
  />
)

export default DrawerBackdrop
