import { gql } from '@apollo/client'

import {
  MAILING_ADDRESS_FRAGMENT,
  ORDER_FRAGMENT,
  ORDER_PRODUCT_FRAGMENT,
} from './fragments'

export const GET_CUSTOMER = gql`
  ${MAILING_ADDRESS_FRAGMENT}

  query getCustomer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      defaultAddress {
        id
      }
      addresses(first: 25) {
        edges {
          node {
            ...MailingAddressFragment
          }
        }
      }
    }
  }
`

export const GET_CUSTOMER_ORDERS = gql`
  ${ORDER_FRAGMENT}

  query getCustomerOrders(
    $customerAccessToken: String!
    $orderLimit: Int!
    $afterCursor: String
  ) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      orders(first: $orderLimit, after: $afterCursor, reverse: true) {
        edges {
          node {
            ...OrderFragment
          }
          cursor
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`

export const GET_CUSTOMER_PRODUCTS = gql`
  ${ORDER_PRODUCT_FRAGMENT}

  query getCustomerProducts($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      orders(first: 100) {
        edges {
          node {
            ...OrderProductFragment
          }
        }
      }
    }
  }
`
