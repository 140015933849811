import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  SanityProductFragment,
  SanityProductVariantFragment,
  SanityProductVariantOption,
} from '@data/sanity/queries/types/product'
import { parseOptionalParameter, usePrevious } from './helpers'
import { getFallbackVariant } from './product'
import { SiteContext } from './site'
import {
  UrlParameter,
  colorUrlParameterName,
  useUrlParameters,
  variantUrlParameterName,
} from './url'

export type PartialProductVariantOption = Pick<
  SanityProductVariantOption,
  'name' | 'value'
>

interface ProductVariantSelection {
  setVariantUrlParameter: (newValue: number) => void
  setSelectedOptions: Dispatch<
    SetStateAction<PartialProductVariantOption[] | undefined>
  >
  selectedOptions?: PartialProductVariantOption[]
  selectedVariant?: SanityProductVariantFragment
}

interface ProductVariantSelectionContextProviderProps {
  product: SanityProductFragment
  useFallbackVariant?: boolean
  children: ReactNode
}

export const ProductVariantSelectionContext =
  createContext<ProductVariantSelection>({
    setVariantUrlParameter: () => null,
    setSelectedOptions: () => null,
  })

export const ProductVariantSelectionContextProvider = ({
  product,
  useFallbackVariant,
  children,
}: ProductVariantSelectionContextProviderProps) => {
  const { isPageTransition } = useContext(SiteContext)

  // Find fallback variant
  const fallbackVariantId = useMemo(
    () => getFallbackVariant(product)?.id ?? null,
    [product]
  )
  const fallbackVariant = useMemo(
    () => product.variants?.find((variant) => variant.id === fallbackVariantId),
    [product.variants, fallbackVariantId]
  )

  const [selectedVariant, setSelectedVariant] = useState<
    SanityProductVariantFragment | undefined
  >(() => (useFallbackVariant ? fallbackVariant : undefined))
  const [selectedOptions, setSelectedOptions] = useState<
    PartialProductVariantOption[] | undefined
  >()

  // Manage URL parameters
  const [currentUrlParameters, setCurrentUrlParameters] = useUrlParameters([
    {
      name: variantUrlParameterName,
      value:
        useFallbackVariant && fallbackVariant ? `${fallbackVariant.id}` : null,
    },
    {
      name: colorUrlParameterName,
      value: null,
    },
  ])
  const previousUrlParameters = usePrevious(currentUrlParameters)
  const urlParameters = useMemo(
    () =>
      isPageTransition && previousUrlParameters
        ? previousUrlParameters
        : currentUrlParameters,
    [currentUrlParameters, previousUrlParameters, isPageTransition]
  )
  const urlParameterVariant = useMemo(() => {
    const variantUrlParameterValue = parseOptionalParameter<string>(
      urlParameters.find(({ name }) => name === variantUrlParameterName)?.value
    )
    const variantUrlParameterId = variantUrlParameterValue
      ? Number(variantUrlParameterValue)
      : null

    return product.variants?.find(({ id }) => id === variantUrlParameterId)
  }, [product.variants, urlParameters])
  const urlParameterColor = useMemo(() => {
    const colorUrlParameterValue = parseOptionalParameter<string>(
      urlParameters.find(({ name }) => name === colorUrlParameterName)?.value
    )

    return colorUrlParameterValue
  }, [urlParameters])

  // Change selected color when color URL parameter changes
  useEffect(() => {
    if (!urlParameterColor) {
      return
    }

    setSelectedOptions((currentSelectedOptions) => [
      ...(currentSelectedOptions?.filter((option) => option.name !== 'Color') ??
        []),
      {
        name: 'Color',
        value: urlParameterColor,
      },
    ])
  }, [product, urlParameterColor])

  // Change selected variant when variant URL parameter changes
  useEffect(() => {
    if (!urlParameterVariant) {
      return
    }

    // Update selected options, which will then update selected variant using effect hook
    setSelectedOptions(
      urlParameterVariant.options.map(({ name, value }) => ({
        name,
        value,
      }))
    )
  }, [urlParameterVariant])

  // Select variant based on selected options
  useEffect(() => {
    if (!selectedOptions || selectedOptions.length === 0) {
      return
    }

    // Find first variant that matches all selected options
    const newVariant = product.variants?.find((variant) =>
      selectedOptions.every((selectedOption) =>
        variant.options?.some(
          (option) =>
            option.name === selectedOption.name &&
            option.value === selectedOption.value
        )
      )
    )

    if (newVariant) {
      setSelectedVariant(newVariant)
    }
  }, [product, selectedOptions])

  const setVariantUrlParameter = useCallback(
    (newValue: number) => {
      const isValidVariant = product.variants?.some(({ id }) => id === newValue)
      // Remove all previous URL parameters (to prevent potential overlap)
      const newUrlParameters: UrlParameter[] = [
        ...urlParameters.filter(
          ({ name }) =>
            name !== variantUrlParameterName && name !== colorUrlParameterName
        ),
      ]

      if (isValidVariant) {
        newUrlParameters.push({
          name: variantUrlParameterName,
          value: `${newValue}`,
        })
      }

      setCurrentUrlParameters(newUrlParameters)
    },
    [urlParameters, setCurrentUrlParameters, product.variants]
  )

  return (
    <ProductVariantSelectionContext.Provider
      value={{
        setVariantUrlParameter,
        setSelectedOptions,
        selectedOptions,
        selectedVariant,
      }}
    >
      {children}
    </ProductVariantSelectionContext.Provider>
  )
}
