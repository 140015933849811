import { createContext, ReactNode, Dispatch, SetStateAction } from 'react'

import { SanitySchoolItem } from '@data/sanity/queries/types/school'
import { useLocalStorageState } from './helpers'
import { Locale } from './language'

interface SchoolContextProps {
  schools: SanitySchoolItem[]
  schoolId: string
  setSchoolId: Dispatch<SetStateAction<string>>
}

export const SchoolContext = createContext<SchoolContextProps>({
  schools: [],
  schoolId: '',
  setSchoolId: () => null,
})

interface SchoolContextProviderProps {
  locale: Locale
  schools: SanitySchoolItem[]
  children: ReactNode
}

export const SchoolContextProvider = ({
  locale,
  schools,
  children,
}: SchoolContextProviderProps) => {
  const [schoolId, setSchoolId] = useLocalStorageState<string>(
    getSchoolIdStorageKey(locale),
    ''
  )

  return (
    <SchoolContext.Provider
      value={{
        schools,
        schoolId,
        setSchoolId,
      }}
    >
      {children}
    </SchoolContext.Provider>
  )
}

/**
 * Gets school ID local storage key.
 */
const getSchoolIdStorageKey = (locale: Locale) => `school_id_${locale}`
