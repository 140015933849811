import { SanityContentQuote } from '@data/sanity/queries/types/content'

import Quote from '@components/quote'

interface QuoteCardProps {
  node: Pick<SanityContentQuote, 'author' | 'alignment'> & {
    text: string
  }
}

const QuoteCard = ({ node }: QuoteCardProps) => {
  const { text, author, alignment } = node
  return (
    <Quote author={author} alignment={alignment}>
      {text}
    </Quote>
  )
}

QuoteCard.displayName = 'QuoteCard'

export default QuoteCard
