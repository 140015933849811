import groq from 'groq'

const imageDimensionsFragment = groq`
  width,
  height,
  aspectRatio
`

export const imageFragment = groq`
  asset,
  crop,
  hotspot,
  customRatio,
  alt,
  fixedHeight,
  "id": asset->assetId,
  "filename": asset->originalFilename,
  "type": asset->mimeType,
  "lqip": asset->metadata.lqip,
  "dimensions": asset->metadata.dimensions {
    ${imageDimensionsFragment}
  }
`
