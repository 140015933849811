import groq from 'groq'

import { feeProductVariantFragment } from './fee'
import { imageFragment } from './image'
import { linkPageFragment } from './link'
import { videoFragment } from './video'

const buttonStyleFragment = groq`
  variant,
  size,
  color,
  icon,
  iconAlignment,
  isFullWidth
`

export const contentFragment = groq`
  ...,
  markDefs[] {
    ...,
    _type == "link" => {
      url,
      isButton,
      isButton == true => {
        "buttonStyles": buttonStyle {
          ${buttonStyleFragment}
        },
      },
      page->{
        ${linkPageFragment}
      }
    },
  },
  _type == "figure" => {
    ${imageFragment}
  },
  _type == 'quote' => {
    text,
    author,
    textAlignment,
  },
  _type == "videoEmbed" => {
    type,
    youtubeVideoUrl
  },
  _type == "videoButton" => {
    text,
    video {
      ${videoFragment}
    },
    style {
      ${buttonStyleFragment}
    }
  },
  _type == "addToCartButton" => {
    text,
    style {
      ${buttonStyleFragment}
    },
    productVariant->{
      price,
      inStock,
      "id": variantID,
      "fees": *[
        _type == "product" &&
        productID == ^.productID &&
        !wasDeleted &&
        !isDraft
      ][0].fees[]->{
        ${feeProductVariantFragment}
      },
    }
  },
`
