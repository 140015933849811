import { createContext, ReactNode, useMemo } from 'react'

import { SanitySiteFragment } from '@data/sanity/queries/types/site'
import {
  HasAuthStrings,
  HasShopFormStrings,
  SanityAuthStrings,
  SanityShopFormStrings,
  SanitySiteStrings,
} from '@data/sanity/queries/types/strings'

interface StringsContextProviderProps {
  site: SanitySiteFragment
  children: ReactNode
}

type ShopFormStringsContextProps = HasAuthStrings & HasShopFormStrings

interface ShopFormStringsContextProviderProps
  extends HasAuthStrings,
    HasShopFormStrings {
  children: ReactNode
}

const emptySiteStrings: SanitySiteStrings = {
  loadingPageTitle: '',
  languageSwitch: '',
  cartLink: '',
  featuredProducts: '',
  skipToContent: '',
  goHomeLabel: '',
  mobileMenuLabel: '',
  headerShopMenuLogoutLabel: '',
  headerShopMenuNoShopLabel: '',
  headerShopSelectorPlaceholder: '',
  mobileMenuShopSelectorPlaceholder: '',
  mobileMenuShopSelectorLabel: '',
  productLowStock: '',
  productOutOfStock: '',
  productPriceLabel: '',
  productPriceFromLabel: '',
  productColorOptionLabel: '',
  productDiscountText: '',
  productIncreaseQuantity: '',
  productDecreaseQuantity: '',
  productEnterQuantity: '',
  productShopSelectorPlaceholder: '',
  productShopSelectorLabel: '',
  productOptionChooseColorMessage: '',
  productOptionChooseSizeMessage: '',
  cartTitle: '',
  cartClose: '',
  cartEmpty: '',
  cartVatSectionTitle: '',
  cartVatIdInputPlaceholder: '',
  cartVatIdError: '',
  cartInvalidVatIdError: '',
  cartCommentSectionTitle: '',
  cartShippingTypeLabel: '',
  cartShippingTypeSchoolPickup: '',
  cartShippingTypeShipToAddress: '',
  cartCommentInputPlaceholder: '',
  cartDiscount: '',
  cartSubtotal: '',
  cartTotal: '',
  cartSubmit: '',
  cartSchoolInputLabel: '',
  cartSchoolInputPlaceholder: '',
  cartSchoolIdError: '',
  cartAddToCartErrorMessage: [],
  cartCommentLabel: '',
  cartSchoolLabel: '',
  cartShopIdAttributeName: '',
  cartProductNameAttributeName: '',
  cartFreetextAttributeName: '',
  cartFreetextColorAttributeName: '',
  cartAssetFilenameAttributeName: '',
  cartAssetColorAttributeName: '',
  cartAssetTitleAttributeName: '',
  carouselCounterText: '',
  carouselLeftArrowLabel: '',
  carouselRightArrowLabel: '',
  carouselDotLabel: '',
  buttonAddToCart: '',
  buttonRemove: '',
  buttonAccept: '',
  buttonLearnMore: '',
  buttonTryAgain: '',
  buttonLoadMore: '',
  buttonUnavailable: '',
  buttonWaiting: '',
  buttonLoading: '',
  buttonAdding: '',
  buttonUpdating: '',
  buttonSubmitting: '',
  buttonEdit: '',
  buttonDelete: '',
  buttonCancel: '',
  buttonGoBack: '',
  buttonNext: '',
  emailAddress: '',
  emailMissing: '',
  emailInvalid: '',
  productWaitlistSubmit: '',
  productWaitlistSuccess: '',
  productWaitlistError: '',
  yes: '',
  no: '',
  colorDark: '',
  colorLight: '',
}

const emptyAuthStrings: SanityAuthStrings = {
  signupFirstName: '',
  signupFirstNamePlaceholder: '',
  signupFirstNameMissing: '',
  signupLastName: '',
  signupLastNamePlaceholder: '',
  signupLastNameMissing: '',
  signupPhoneNumber: '',
  signupPhoneNumberPlaceholder: '',
  signupPhoneNumberMissing: '',
  signupEmail: '',
  signupEmailPlaceholder: '',
  signupEmailMissing: '',
  signupPassword: '',
  signupPasswordPlaceholder: '',
  signupPasswordMissing: '',
  signupErrorMessage: [],
  signupAccountActivationMessage: [],
  signupSubmit: '',
  loginErrorMessage: [],
  loginFailedMessage: [],
  loginSubmit: '',
  passwordRecoveryErrorMessage: [],
  passwordRecoverySuccessMessage: [],
  passwordRecoverySubmit: '',
}

const emptyShopFormStrings: SanityShopFormStrings = {
  shopFormStep1Name: '',
  shopFormStep2Name: '',
  shopFormStep3Name: '',
  shopFormShopSectionTitle: '',
  shopFormShopSectionDescription: '',
  shopFormName: '',
  shopFormNamePlaceholder: '',
  shopFormNameMissing: '',
  shopFormSchool: '',
  shopFormSchoolPlaceholder: '',
  shopFormSchoolMissing: '',
  shopFormUserSectionTitle: '',
  shopFormLoginLink: '',
  shopFormStep1ErrorMessage: [],
  shopFormStep1Submit: '',
  shopFormPrintSectionTitle: '',
  shopFormTeamPrintSectionDescription: '',
  shopFormYearPrintSectionDescription: '',
  shopFormSkipStep2Button: '',
  shopFormLogoUploadSectionTitle: '',
  shopFormLogoUploadSectionDescription: [],
  shopFormOptimizedLogoUploadSectionTitle: '',
  shopFormOptimizedLogoUploadSectionDescription: [],
  shopFormImageUploadSectionTitle: '',
  shopFormImageUploadSectionDescription: [],
  shopFormOptimizedImageUploadSectionTitle: '',
  shopFormOptimizedImageUploadSectionDescription: [],
  shopFormUploadPrint: '',
  shopFormUploadingPrint: '',
  shopFormUploadPrintTypeError: '',
  shopFormUploadPrintSizeError: '',
  shopFormUploadPrintError: '',
  shopFormPrintListEmpty: '',
  shopFormPrintTitleLabel: '',
  shopFormPrintTitlePlaceholder: '',
  shopFormOriginalPrintTitle: '',
  shopFormOptimizedPrintTitle: '',
  shopFormRemovePrintLink: '',
  shopFormStep2Submit: '',
  shopFormReadyMessage: '',
  shopFormTeamShop: '',
  shopFormYearShop: '',
  shopFormUrlSectionTitle: '',
  shopFormUrlSectionDescription: '',
  shopFormUrlCopyLinkButton: '',
  shopFormUrlCopyLinkButtonCopied: '',
  shopFormReviewSectionTitle: '',
  shopFormLogoReviewSectionTitle: '',
  shopFormLogoReviewSectionDescription: [],
  shopFormOptimizedLogoReviewSectionTitle: '',
  shopFormOptimizedLogoReviewSectionDescription: [],
  shopFormImageReviewSectionTitle: '',
  shopFormImageReviewSectionDescription: [],
  shopFormOptimizedImageReviewSectionTitle: '',
  shopFormOptimizedImageReviewSectionDescription: [],
  shopFormPrintEditLink: '',
}

export const StringsContext = createContext<SanitySiteStrings>(emptySiteStrings)

export const StringsContextProvider = ({
  site,
  children,
}: StringsContextProviderProps) => {
  const strings = useMemo(
    () => ({
      ...emptySiteStrings,
      ...site.siteStrings,
    }),
    [site.siteStrings]
  )

  return (
    <StringsContext.Provider value={strings}>
      {children}
    </StringsContext.Provider>
  )
}

export const ShopFormStringsContext =
  createContext<ShopFormStringsContextProps>({
    authStrings: emptyAuthStrings,
    shopFormStrings: emptyShopFormStrings,
  })

/**
 * The shop creation strings context provider.
 */
export const ShopFormStringsContextProvider = ({
  authStrings,
  shopFormStrings,
  children,
}: ShopFormStringsContextProviderProps) => (
  <ShopFormStringsContext.Provider
    value={{
      authStrings,
      shopFormStrings,
    }}
  >
    {children}
  </ShopFormStringsContext.Provider>
)

/**
 * Trims underscores from a string.
 */
export const trimUnderscores = (key: string): string =>
  key.replace(/^\_+|\_+$/g, '')
