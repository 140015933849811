import { getSchoolByIdQuery } from '@data/sanity/queries/school'
import { SanityIdQueryParameter } from '@data/sanity/queries/types/parameters'
import { SanitySchoolByIdQuery } from '@data/sanity/queries/types/school'
import { fetchQuery } from './client'

/**
 * Fetches a school by its ID.
 */
export const getSchoolById = async (id: string) =>
  fetchQuery<SanitySchoolByIdQuery, SanityIdQueryParameter>(
    getSchoolByIdQuery,
    { id }
  )
