import { useContext, useEffect } from 'react'
import axios, { AxiosResponse } from 'axios'
import {
  ContentName,
  ContentType,
  ID,
  ItemPrice,
  Quantity,
  Title,
  CustomProperties,
  Value,
  Currency,
  NumItems,
  Email,
  FirstName,
  LastName,
  CustomData,
} from 'facebook-nodejs-business-sdk'

import {
  SanityProductVariantFragment,
  SanityProductVariantQuery,
} from '@data/sanity/queries/types/product'
import { Locale } from '@lib/language'
import { Cart } from './cart'
import { DiscountItem } from './discount'
import { ProductVariantSelectionContext } from './product-variant-selection-context'

export enum FacebookEvent {
  VIEW_CONTENT = 'ViewContent',
  COMPLETE_REGISTRATION = 'CompleteRegistration',
  ADD_TO_CART = 'AddToCart',
  INITIATE_CHECKOUT = 'InitiateCheckout',
}

export interface FacebookEventContent {
  id?: ID
  itemPrice?: ItemPrice
  quantity?: Quantity
  title?: Title
}

export interface FacebookEventCustomData {
  contentName?: ContentName
  contentType?: ContentType
  customProperties?: CustomProperties
  contentIds?: Array<ID>
  contents?: Array<FacebookEventContent>
  value?: Value
  currency?: Currency
  numItems?: NumItems
}

export interface FacebookEventUserData {
  email?: Email
  firstName?: FirstName
  lastName?: LastName
}

export type FacebookEventData = FacebookEventCustomData & FacebookEventUserData

/**
 * Triggers a Facebook event.
 */
export const triggerFacebookEvent = async (
  eventName: FacebookEvent,
  locale: Locale,
  data?: FacebookEventData
) => {
  try {
    const payload = JSON.stringify({
      eventName,
      ...data,
    })

    await axios.post<CustomData, AxiosResponse<CustomData>, string>(
      '/api/facebook',
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Locale': locale,
        },
      }
    )
  } catch (error) {
    console.error(error)
  }
}

/**
 * Triggers a view content Facebook event.
 */
export const triggerViewContentFacebookEvent = async (
  locale: Locale,
  productTitle: string,
  variant?: SanityProductVariantFragment
) =>
  triggerFacebookEvent(FacebookEvent.VIEW_CONTENT, locale, {
    contentType: 'product',
    contentName: productTitle,
    contentIds: [variant?.id.toString() ?? ''],
    customProperties: {
      options: variant?.options.map((option) => option.value),
    },
  })

/**
 * Triggers an initiate checkout Facebook event.
 */
export const triggerInitiateCheckoutFacebookEvent = async (
  locale: Locale,
  cart: Cart,
  cartDiscountItems: DiscountItem[],
  currencyCode?: string
) =>
  triggerFacebookEvent(FacebookEvent.INITIATE_CHECKOUT, locale, {
    contentIds: cart.lineItems.map(({ id }) => id.toString()),
    contents: cart.lineItems.map(({ id, price, quantity, product }) => ({
      id: id.toString(),
      itemPrice: price / 100,
      quantity: quantity,
      title: product.title,
    })),
    customProperties: {
      automaticDiscount: {
        title: cart.automaticDiscount.title,
        amount: cart.automaticDiscount.amount / 100,
      },
      discount: {
        title: cartDiscountItems.map(({ title }) => title).join(', '),
        amount:
          cartDiscountItems.reduce(
            (total, { amount, quantity }) => total + amount * quantity,
            0
          ) / 100,
      },
    },
    value: cart.total / 100,
    currency: currencyCode,
    numItems: cart.lineItems.length,
  })

/**
 * Triggers an add to cart Facebook event.
 */
export const triggerAddToCartFacebookEvent = async (
  locale: Locale,
  variant: SanityProductVariantQuery
) =>
  triggerFacebookEvent(FacebookEvent.ADD_TO_CART, locale, {
    contentType: 'product',
    contentName: variant.product.title,
    contentIds: [variant.id.toString()],
    customProperties: {
      options: variant.options.map((option) => option.value),
    },
  })

/**
 * View content Facebook event hook.
 */
export const useViewContentFacebookEvent = (
  locale: Locale,
  productTitle: string,
  facebookEvents?: boolean
) => {
  const { selectedVariant } = useContext(ProductVariantSelectionContext)

  useEffect(() => {
    if (!facebookEvents) {
      return
    }

    triggerViewContentFacebookEvent(locale, productTitle, selectedVariant)
  }, [selectedVariant, locale, facebookEvents, productTitle])
}
