import {
  CartSettings,
  CookieSettings,
  Discount,
  FooterSettings,
  GeneralSettings,
  ProductVariantSelection,
  PromoSettings,
  SeoSettings,
  SocialLink,
} from '@data/sanity/schema'
import { SanityNewsletterBlock } from './blocks'
import { SanityBlogSettings } from './blog'
import { SanityContentFragment } from './content'
import { SanityImageFragment } from './image'
import { SanityLinkFragment, SanityLinkReference } from './link'
import { SanityHasSlug } from './page'
import { SanityProductFragment } from './product'
import { SanitySchoolItem } from './school'
import { SanitySiteStrings } from './strings'
import { SanityPrintShopBase } from './shop'
import { Keyed } from '@lib/helpers'

export type SanityPromo = Pick<
  PromoSettings,
  'enabled' | 'display' | 'text'
> & {
  link?: SanityLinkReference
}

export type SanityMenuLinkFragment = SanityLinkFragment & {
  dropdownItems: Array<SanityLinkFragment>
}

export type SanityMenuFeaturedLinkFragment = SanityMenuLinkFragment & {
  featured?: Array<SanityProductFragment>
}

export type SanityHeaderDesktopMenu = SanityHasSlug & {
  items: Array<SanityMenuFeaturedLinkFragment>
}

export type SanityHeaderMobileMenu = SanityHasSlug & {
  items: Array<SanityMenuLinkFragment>
}

export interface SanityHeaderFragment {
  menuDesktopLeft?: SanityHeaderDesktopMenu
  menuDesktopRight?: SanityHeaderDesktopMenu
  menuMobilePrimary?: SanityHeaderMobileMenu
  menuMobileSecondary?: SanityHeaderMobileMenu
  promo?: SanityPromo
}

export interface SanityFooterBlockMenu {
  items: Array<SanityLinkFragment>
}

export interface SanityFooterBlock1 {
  content?: Array<SanityContentFragment>
  newsletter: SanityNewsletterBlock
}

export interface SanityFooterBlock2 {
  content?: Array<SanityContentFragment>
}

export interface SanityFooterBlock3 {
  menu: SanityFooterBlockMenu
}

export type SanityFooterSocialLink = Keyed<
  Pick<SocialLink, 'socialNetwork' | 'url'>
>

export interface SanityFooterFragment {
  blocks?: [SanityFooterBlock1, SanityFooterBlock2, SanityFooterBlock3]
  social?: Array<SanityFooterSocialLink>
  copyright?: FooterSettings['copyright']
  paymentMethods?: SanityImageFragment[]
}

export interface SanityCartAdditionalInfoItem {
  icon?: SanityImageFragment
  title?: string
  description?: string
}

export type SanityCart = Pick<
  CartSettings,
  | 'storeURL'
  | 'taxRate'
  | 'message'
  | 'openInSeparatePage'
  | 'showVatId'
  | 'showComment'
> & {
  terms?: Array<SanityContentFragment>
  shipping: {
    schoolPickup?: boolean
    orderProcessingDay?: number
    deliveryTimeInDays?: number
  }
  additionalInfo?: Array<SanityCartAdditionalInfoItem>
}

export type SanityProductCount = SanityHasSlug & {
  count: number
}

export type SanityCookieConsent = Pick<
  CookieSettings,
  'enabled' | 'message'
> & {
  link?: SanityLinkReference
}

export type SanitySeo = Pick<
  SeoSettings,
  | 'siteTitle'
  | 'metaTitle'
  | 'metaDesc'
  | 'shareTitle'
  | 'shareDesc'
  | 'shareGraphic'
>

export interface SanityIdentity {
  logo?: SanityImageFragment
  invertedLogo?: SanityImageFragment
}

export type PublicSiteSettings = Pick<
  GeneralSettings,
  | 'siteURL'
  | 'klaviyoAccountID'
  | 'gtmContainerId'
  | 'analyticsId'
  | 'facebookEvents'
  | 'facebookPixelId'
  | 'facebookDomainVerification'
  | 'facebookTestEventCode'
  | 'stampedApiKey'
  | 'partnerAdsTracking'
  | 'pageAnimation'
>

export type AllSiteSettings = PublicSiteSettings &
  Pick<GeneralSettings, 'klaviyoPrivateKey' | 'stampedStoreHash'>

export enum SanityDiscountType {
  QUANTITY = 'quantity',
  BUNDLE = 'bundle',
}

export type SanityDiscountProduct = Pick<
  ProductVariantSelection['selection'],
  'id'
> & {
  variantIds: Array<
    ProductVariantSelection['selection']['variants'][number]['id']
  >
}

type SanityPartialDiscount = Pick<
  Discount,
  '_id' | 'title' | 'discountValuePercent'
>

export type SanityQuantityDiscount = SanityPartialDiscount & {
  type: SanityDiscountType.QUANTITY
  minimumQuantity: number
  maximumQuantity: number
}

export type SanityBundleDiscount = SanityPartialDiscount & {
  type: SanityDiscountType.BUNDLE
  products: Array<SanityDiscountProduct>
  doNotStackWithQuantityDiscounts?: boolean
}

export type SanityDiscount = SanityQuantityDiscount | SanityBundleDiscount

export type SanityDiscountQuery = Array<SanityDiscount>

export interface SanitySiteFragment {
  publicLocales: Array<GeneralSettings['locale']>
  settings: PublicSiteSettings
  identity?: SanityIdentity
  cart: SanityCart
  blogSettings: SanityBlogSettings | null
  productCounts: Array<SanityProductCount>
  cookieConsent?: SanityCookieConsent
  header?: SanityHeaderFragment
  footer?: SanityFooterFragment
  seo?: SanitySeo
  siteStrings?: SanitySiteStrings
  discounts: Array<SanityDiscount>
  schools: Array<SanitySchoolItem>
  shops: Array<SanityPrintShopBase>
}
