import {
  AttributeInput,
  CartFragmentFragment,
} from '@data/shopify/storefront/types'
import { getProductVariants, shippableProductTypes } from '@lib/sanity/product'
import { Cart, CartFormValues, LineItem, ShippingType } from '@lib/cart'
import { Locale } from '@lib/language'
import { CartFragmentFragmentLine } from './graphql/cart'
import { parseShopifyGlobalId } from './client'

export enum CartLineItemAttribute {
  ASSET_FILENAME = 'asset_filename',
  ASSET_URL = 'url',
  CART_ID = 'cart_id',
  CART_COMPLETED = 'cart_completed',
  FREETEXT = 'freetext',
  FREETEXT_COLOR = 'freetext_color',
  IMAGE_ASSET_COLOR = 'image_asset_color',
  IMAGE_ASSET_FILE_ID = 'image_asset_file_id',
  IMAGE_ASSET_TITLE = 'image_asset_title',
  IMAGE_ASSET_URL = 'image_asset_url',
  LOGO_ASSET_COLOR = 'logo_asset_color',
  LOGO_ASSET_FILE_ID = 'logo_asset_file_id',
  LOGO_ASSET_TITLE = 'logo_asset_title',
  LOGO_ASSET_URL = 'logo_asset_url',
  PARTNER_ADS_CLICK_ID = 'x-pa-pacid',
  PARTNER_ADS_CLICK_ID2 = 'x-pacid',
  PARTNER_ADS_ID = 'x-pa-id',
  PRODUCT_NAME = 'product_name',
  PRODUCT_VARIANT_ID = 'product_variant_id',
  SHOP_ID = 'shop_id',
  SCHOOL_ID = 'school_id',
  SCHOOL_NAME = 'school_name',
  SHIPPING_TYPE = 'shipping_type',
  TAX_EXEMPT = 'tax_exempt',
  TAX_VAT_ID = 'vat_id',
}

export enum CartLineItemBooleanAttribute {
  NO = 'no',
  YES = 'yes',
}

/**
 * Gets tax-related cart attributes.
 */
export const getTaxCartAttributes = (
  cartFormValues: CartFormValues,
  taxExempt: boolean
) => {
  const customAttributes: AttributeInput[] = []

  if (cartFormValues.vatId) {
    // Set VAT ID checkout attribute
    customAttributes.push({
      key: `_${CartLineItemAttribute.TAX_VAT_ID}`,
      value: cartFormValues.vatId,
    })

    // Set tax exemption checkout attribute
    customAttributes.push({
      key: `_${CartLineItemAttribute.TAX_EXEMPT}`,
      value: taxExempt
        ? CartLineItemBooleanAttribute.YES
        : CartLineItemBooleanAttribute.NO,
    })
  }

  return customAttributes
}

/**
 * Gets shipping type attributes.
 */
export const getShippingTypeAttributes = (
  shippingType: ShippingType
): AttributeInput[] => [
  {
    key: `_${CartLineItemAttribute.SHIPPING_TYPE}`,
    value: shippingType,
  },
]

/**
 * Checks if all line items have correct shipping type attributes.
 */
export const validateLineItemShippingTypeAttributes = (
  lineItems: LineItem[],
  shippingType: ShippingType
) => {
  const lineItemProductTypes = lineItems.map(({ product }) => product.type)
  const hasShippableProducts = lineItemProductTypes.some((productType) =>
    shippableProductTypes.includes(productType)
  )

  if (!hasShippableProducts) {
    // Check if line items have no shipping type attributes
    return lineItems.every(({ attributes }) => {
      const lineItemShippingType = attributes?.find(
        ({ key }) => key === `_${CartLineItemAttribute.SHIPPING_TYPE}`
      )?.value

      return !lineItemShippingType
    })
  }

  // Check if line items have specific shipping type attribute
  return lineItems.every(({ attributes }) => {
    const lineItemShippingType = attributes?.find(
      ({ key }) => key === `_${CartLineItemAttribute.SHIPPING_TYPE}`
    )?.value

    return lineItemShippingType === shippingType
  })
}

/**
 * Parses Shopify line items.
 */
const parseShopifyCartLineItems = async (
  lineItems: CartFragmentFragmentLine[],
  locale: Locale
): Promise<LineItem[]> => {
  // Read product variants in cart
  const cartVariantIds = lineItems
    .map((shopifyCartLine) =>
      shopifyCartLine.merchandise.id
        ? parseShopifyGlobalId(`${shopifyCartLine.merchandise.id}`)
        : null
    )
    .filter(Boolean) as number[]
  const productVariants = await getProductVariants(locale, cartVariantIds)

  return lineItems
    .map((shopifyCartLine) => {
      const variantId = shopifyCartLine.merchandise.id
        ? parseShopifyGlobalId(`${shopifyCartLine.merchandise.id}`)
        : null
      const variant = variantId
        ? productVariants.find(({ id }) => id === variantId)
        : null

      if (!variant) {
        return
      }

      return {
        lineId: shopifyCartLine.id,
        merchandiseId: shopifyCartLine.merchandise.id,
        quantity: shopifyCartLine.quantity,
        attributes: shopifyCartLine.attributes?.map(({ key, value }) => ({
          key,
          value,
        })),
        ...variant,
      }
    })
    .filter(Boolean) as LineItem[]
}

/**
 * Parses Shopify cart.
 */
export const parseShopifyCart = async (
  locale: Locale,
  shopifyCart?: CartFragmentFragment
): Promise<Cart | undefined> => {
  if (!shopifyCart) {
    return
  }

  const lineItems = await parseShopifyCartLineItems(
    shopifyCart.lines.edges.map((edge) => edge.node),
    locale
  )

  const subTotal = shopifyCart.estimatedCost.subtotalAmount
    ? Number(shopifyCart.estimatedCost.subtotalAmount.amount) * 100
    : 0

  const automaticDiscount = shopifyCart.lines.edges.reduce(
    (result, edge) => {
      const discountAllocation = edge.node.discountAllocations[0]

      if (
        discountAllocation &&
        'title' in discountAllocation &&
        'discountedAmount' in discountAllocation
      ) {
        return {
          title: discountAllocation.title,
          amount:
            result.amount +
            parseFloat(discountAllocation.discountedAmount.amount) * 100,
        }
      }

      return result
    },
    {
      title: '',
      amount: 0,
    }
  )

  const total = subTotal - automaticDiscount.amount

  return {
    id: shopifyCart.id,
    note: shopifyCart.note ?? '',
    lineItems,
    automaticDiscount,
    discountCodes: shopifyCart.discountCodes.map(({ code }) => code),
    subTotal,
    total,
    webUrl: `${shopifyCart.checkoutUrl}`,
  }
}

/**
 * Gets whether cart is completed from cart attributes.
 */
export const getIsCartCompleted = (cartFragment: CartFragmentFragment | null) =>
  cartFragment?.attributes?.find(
    ({ key }) => key === `_${CartLineItemAttribute.CART_COMPLETED}`
  )?.value === CartLineItemBooleanAttribute.YES
