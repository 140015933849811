import { useContext } from 'react'

import { shippableProductTypes } from '@lib/sanity/product'
import { CartContext, ShippingType } from '@lib/cart'
import { StringsContext } from '@lib/strings'

import Radio, { RadioItem, RadioItemLayout } from '@components/radio'

interface ShippingTypeFieldProps {
  className?: string
  hasSchoolPickup?: boolean
}

const ShippingTypeField = ({
  className,
  hasSchoolPickup,
}: ShippingTypeFieldProps) => {
  const strings = useContext(StringsContext)
  const { cart, shippingType, setShippingType } = useContext(CartContext)

  const hasShippableProducts = cart.lineItems.some(({ product }) =>
    shippableProductTypes.includes(product.type)
  )

  // Hide shipping type field when there are no shippable products
  if (!hasShippableProducts) {
    return null
  }

  const shippingTypeOptions: RadioItem[] = []

  if (hasSchoolPickup) {
    shippingTypeOptions.push({
      value: ShippingType.PICKUP_SCHOOL,
      label: strings.cartShippingTypeSchoolPickup,
    })
  }

  shippingTypeOptions.push({
    value: ShippingType.SHIP_TO_ADDRESS,
    label: strings.cartShippingTypeShipToAddress,
  })

  return (
    <div className={className}>
      <div className="text-sm">{strings.cartShippingTypeLabel}</div>

      <Radio
        className="mt-2"
        layout={RadioItemLayout.VERTICAL}
        value={shippingType}
        items={shippingTypeOptions}
        onChange={(value) => setShippingType(value as ShippingType)}
      />
    </div>
  )
}

export default ShippingTypeField
