import { useContext } from 'react'

import { StringsContext } from '@lib/strings'

const CartEmpty = () => {
  const strings = useContext(StringsContext)

  return (
    <div className="my-auto pb-28 text-center">
      <p className="text-pageText">{strings.cartEmpty}</p>
    </div>
  )
}

export default CartEmpty
