import { ButtonHTMLAttributes, ReactNode, useContext } from 'react'

import { SanityVideo } from '@data/sanity/queries/types/video'
import { SiteContext } from '@lib/site'

import Button, { ButtonProps } from './button'

export type VideoButtonProps = {
  video: SanityVideo
  children?: ReactNode
} & ButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>

const VideoButton = ({
  video,
  children,
  className,
  variant,
  size,
  color,
  icon,
  iconAlignment,
}: VideoButtonProps) => {
  const { toggleVideoModal } = useContext(SiteContext)

  const openModal = () => toggleVideoModal(true, video)

  return (
    <Button
      onClick={openModal}
      variant={variant}
      size={size}
      color={color}
      icon={icon}
      iconAlignment={iconAlignment}
      className={className}
    >
      {children}
    </Button>
  )
}

export default VideoButton
