import {
  useRef,
  useEffect,
  HTMLAttributes,
  KeyboardEvent,
  useContext,
  ReactNode,
} from 'react'

import { wrap } from '@lib/helpers'

import { RadioGroupContext, RadioItemContext } from '@components/radio-group'

interface RadioItemProps {
  value: string
  title?: string
  children: ReactNode
}

const RadioItem = ({
  value,
  title,
  children,
  className,
  onMouseEnter,
  onMouseLeave,
}: RadioItemProps & HTMLAttributes<HTMLButtonElement>) => {
  const groupContext = useContext(RadioGroupContext)
  const itemContext = useContext(RadioItemContext)
  const isChecked = groupContext.value === value
  const itemRef = useRef<HTMLButtonElement>(null)
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }

    if (isChecked) {
      itemRef.current?.focus({ preventScroll: true })
    }
  }, [isChecked])

  const handleClick = () => {
    const { onChange } = groupContext

    if (onChange) {
      onChange(value)
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    const { items, onChange } = groupContext
    const index = itemContext
    let flag = false

    if (!onChange || !items) {
      return
    }

    // listen for one of our key presses
    switch (event.code) {
      case 'Space':
      case 'Enter': {
        onChange(value)
        flag = true
        break
      }

      case 'ArrowUp':
      case 'ArrowLeft': {
        const previousIndex = wrap(index - 1, items.length)
        onChange(items[previousIndex].props.value)
        flag = true
        break
      }

      case 'ArrowDown':
      case 'ArrowRight': {
        const nextIndex = wrap(index + 1, items.length)
        onChange(items[nextIndex].props.value)
        flag = true
        break
      }

      default: {
        break
      }
    }

    // if one of our keys were pressed (flagged), prevent default behavior
    if (flag) {
      event.stopPropagation()
      event.preventDefault()
    }
  }
  return (
    <button
      type="button"
      ref={itemRef}
      role="radio"
      title={title}
      tabIndex={isChecked ? 0 : -1}
      aria-checked={isChecked}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  )
}

export default RadioItem
