import {
  useContext,
  MouseEvent,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { useForm } from 'react-hook-form'

import { SanityContentFragment } from '@data/sanity/queries/types/content'
import { CartContext } from '@lib/cart'
import { getCheckoutUrlStorageKey } from '@lib/checkout'
import { DiscountContext } from '@lib/discount'
import { triggerInitiateCheckoutFacebookEvent } from '@lib/facebook'
import { ErrorMessages } from '@lib/helpers'
import { LanguageContext, Locale } from '@lib/language'
import { SchoolContext } from '@lib/school'
import { serializers } from '@lib/serializers'
import { ShopContext } from '@lib/shop'
import { SiteContext } from '@lib/site'
import { StringsContext } from '@lib/strings'

import Button, { ButtonColor, ButtonVariant } from '@components/buttons/button'
import Checkbox from '@components/checkbox'

/**
 * Adds Google Analytics linker param to URL.
 */
const addLinkerParamToUrl = async (url: string) =>
  new Promise<string>((resolve) => {
    if (!window.ga) {
      resolve(url)
      return
    }

    window.ga(() => {
      const linkerParam = window.ga?.getAll()?.[0]?.get('linkerParam')
      const searchParams = new URLSearchParams(linkerParam)

      const newUrl = new URL(url)
      searchParams.forEach((value, key) => newUrl.searchParams.set(key, value))
      resolve(newUrl.toString())
    })
  })

/**
 * Adds locale param to URL.
 */
const addLocaleParamToUrl = (url: string, locale: Locale) => {
  const newUrl = new URL(url)
  newUrl.searchParams.set('locale', locale)

  return newUrl.toString()
}

/**
 * Formats checkout URL by replacing host and adding linker param.
 */
const formatCheckoutUrl = async (
  checkoutUrl: string,
  locale: Locale,
  storeUrl?: string
) => {
  let formattedCheckoutUrl = `${checkoutUrl}`

  // Update checkout URL to use custom domain name
  if (storeUrl) {
    formattedCheckoutUrl = formattedCheckoutUrl.replace(
      /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/g,
      storeUrl
    )
  }

  formattedCheckoutUrl = addLocaleParamToUrl(formattedCheckoutUrl, locale)
  formattedCheckoutUrl = await addLinkerParamToUrl(formattedCheckoutUrl)

  return formattedCheckoutUrl
}

interface CartSubmitProps {
  vatId: string
  comment: string
  setErrorMessages: Dispatch<SetStateAction<ErrorMessages>>
  storeUrl?: string
  terms?: Array<SanityContentFragment>
  className?: string
}

const CartSubmit = ({
  vatId,
  comment,
  setErrorMessages,
  storeUrl,
  terms,
  className,
}: CartSubmitProps) => {
  const { register, watch } = useForm()
  const { currencyCode } = useContext(ShopContext)
  const {
    cart,
    isCartUpdating,
    isCartSubmitting,
    shopSchoolId,
    submitCart,
    toggleCart,
  } = useContext(CartContext)
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)
  const { cartDiscountItems } = useContext(DiscountContext)
  const { settings } = useContext(SiteContext)
  const { schoolId } = useContext(SchoolContext)

  const acceptTermsRegister = register('acceptTerms')
  const hasAgreed = watch('acceptTerms')

  const triggerFacebookEvent = useCallback(async () => {
    if (!settings?.facebookEvents) {
      return
    }

    await triggerInitiateCheckoutFacebookEvent(
      locale,
      cart,
      cartDiscountItems ?? [],
      currencyCode
    )
  }, [cart, cartDiscountItems, currencyCode, locale, settings?.facebookEvents])

  // Handles redirection after cart submit
  const goToCheckout = useCallback(
    async (checkoutUrl: string) => {
      await triggerFacebookEvent()

      const formattedCheckoutUrl = await formatCheckoutUrl(
        checkoutUrl,
        locale,
        storeUrl
      )

      // Save checkout URL
      localStorage.setItem(
        getCheckoutUrlStorageKey(locale),
        formattedCheckoutUrl
      )

      // Redirect to checkout
      window.location.href = formattedCheckoutUrl
    },
    [locale, storeUrl, triggerFacebookEvent]
  )

  // Handles cart submit and validation
  const handleCartSubmit = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()

      const { errors, checkoutUrl } = await submitCart({
        vatId,
        comment,
        schoolId: shopSchoolId ?? schoolId,
      })
      const errorList = Object.entries(errors)

      setErrorMessages((messages) => ({
        ...messages,
        vatId: errors.vatId,
        schoolId: errors.schoolId,
      }))

      if (!checkoutUrl || errorList.length > 0) {
        return
      }

      await goToCheckout(checkoutUrl)
      toggleCart(false)
    },
    [
      comment,
      goToCheckout,
      schoolId,
      setErrorMessages,
      shopSchoolId,
      submitCart,
      toggleCart,
      vatId,
    ]
  )

  return (
    <div className={className}>
      {terms && (
        <Checkbox
          id={`cart-acceptTerms`}
          formRegister={acceptTermsRegister}
          className="mb-6 mx-auto"
        >
          <BlockContent
            renderContainerOnSingleChild
            className="rc rc-checkbox"
            blocks={terms}
            serializers={serializers}
          />
        </Checkbox>
      )}
      <div className="flex justify-center">
        <Button
          variant={ButtonVariant.PRIMARY}
          color={ButtonColor.YELLOW}
          icon="ArrowRight"
          disabled={isCartUpdating || isCartSubmitting || (terms && !hasAgreed)}
          onClick={handleCartSubmit}
        >
          {isCartUpdating && strings.buttonUpdating}
          {isCartSubmitting && strings.buttonSubmitting}
          {!isCartUpdating && !isCartSubmitting && strings.cartSubmit}
        </Button>
      </div>
    </div>
  )
}

export default CartSubmit
