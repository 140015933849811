import cx from 'classnames'
import { ButtonHTMLAttributes, forwardRef, useId } from 'react'

import {
  SanityContentBlockButtonColor,
  SanityContentBlockButtonIconAlignment,
  SanityContentBlockButtonSize,
  SanityContentBlockButtonVariant,
} from '@data/sanity/queries/types/content'

import Icon, { IconName } from '@components/icon'

export enum ButtonVariant {
  LINK = 'link',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum ButtonSize {
  DEFAULT = 'default',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

export enum ButtonColor {
  DEFAULT = 'default',
  WHITE = 'white',
  BLACK = 'black',
  YELLOW = 'yellow',
  PINK = 'pink',
  RED = 'red',
}

export enum ButtonIconAlignment {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum ButtonIconSize {
  DEFAULT = 'default',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

type VariantColorClassMap = Record<
  ButtonVariant,
  Record<ButtonColor | 'default', string | string[]>
>

export type SizeClassMap = Record<ButtonSize, string | string[]>

export interface ButtonProps {
  variant?: ButtonVariant
  size?: ButtonSize
  color?: ButtonColor
  icon?: IconName
  iconSize?: ButtonIconSize
  iconAlignment?: ButtonIconAlignment
  isActive?: boolean
}

export const getBaseClasses = (variant?: ButtonVariant) =>
  cx('inline-flex items-center disabled:opacity-50', {
    'justify-center rounded-full text-center border transition-all duration-300 font-medium':
      variant !== ButtonVariant.LINK,
  })

export const colorClasses: VariantColorClassMap = {
  [ButtonVariant.PRIMARY]: {
    [ButtonColor.WHITE]: ['border-white bg-white text-black'],
    [ButtonColor.BLACK]: ['border-black bg-black text-white'],
    [ButtonColor.YELLOW]: [
      'border-yellow bg-yellow text-black',
      'hover:bg-yellow-dark hover:border-yellow-dark',
    ],
    [ButtonColor.PINK]: [
      'border-pink bg-pink text-black',
      'hover:bg-pink-dark hover:border-pink-dark',
    ],
    [ButtonColor.RED]: [
      'border-red bg-red text-white',
      'hover:bg-red-dark hover:border-red-dark',
    ],
    [ButtonColor.DEFAULT]: [
      'bg-btn-primary-bg text-btn-primary-text border-btn-primary-border',
      'hover:bg-btn-primary-bg-hover hover:text-btn-primary-text-hover hover:border-btn-primary-border-hover',
    ],
  },
  [ButtonVariant.SECONDARY]: {
    [ButtonColor.WHITE]: [
      'bg-transparent border-white text-white',
      'hover:bg-white hover:text-black',
    ],
    [ButtonColor.BLACK]: [
      'bg-transparent border-black text-black',
      'hover:bg-black hover:text-white',
    ],
    [ButtonColor.YELLOW]: [
      'bg-transparent border-yellow text-yellow',
      'hover:bg-yellow hover:text-black',
    ],
    [ButtonColor.PINK]: [
      'bg-transparent border-pink text-pink',
      'hover:bg-pink hover:text-black',
    ],
    [ButtonColor.RED]: [
      'bg-transparent border-red text-red',
      'hover:bg-red hover:text-white',
    ],
    [ButtonColor.DEFAULT]: [
      'bg-btn-secondary-bg border border-btn-secondary-border text-btn-secondary-text',
      'hover:bg-btn-secondary-bg-hover hover:text-btn-secondary-text-hover hover:border-btn-secondary-border-hover',
    ],
  },
  [ButtonVariant.LINK]: {
    [ButtonColor.WHITE]: ['text-white'],
    [ButtonColor.BLACK]: ['text-black'],
    [ButtonColor.YELLOW]: ['text-yellow'],
    [ButtonColor.PINK]: ['text-pink'],
    [ButtonColor.RED]: ['text-red'],
    [ButtonColor.DEFAULT]: ['text-current'],
  },
}

const sizeClasses: SizeClassMap = {
  [ButtonSize.DEFAULT]: '',
  [ButtonSize.SMALL]: 'text-base',
  [ButtonSize.NORMAL]: 'text-xl',
  [ButtonSize.LARGE]: 'text-3xl',
}

const spacingClasses: SizeClassMap = {
  [ButtonSize.DEFAULT]: '',
  [ButtonSize.SMALL]: 'px-4 py-1',
  [ButtonSize.NORMAL]: 'px-12 py-4',
  [ButtonSize.LARGE]: 'px-12 py-4',
}

export const iconSizeClasses: SizeClassMap = {
  [ButtonSize.DEFAULT]: '',
  [ButtonIconSize.SMALL]: 'text-xl',
  [ButtonIconSize.NORMAL]: 'text-2xl',
  [ButtonIconSize.LARGE]: 'text-3xl',
}

const activeClasses: VariantColorClassMap = {
  [ButtonVariant.PRIMARY]: {
    [ButtonColor.WHITE]: 'border-white bg-white text-black',
    [ButtonColor.BLACK]: 'border-black bg-black text-white',
    [ButtonColor.YELLOW]: 'border-yellow-dark bg-yellow-dark text-black',
    [ButtonColor.PINK]: 'border-pink-dark bg-pink-dark text-black',
    [ButtonColor.RED]: 'border-red-dark bg-red-dark text-white',
    [ButtonColor.DEFAULT]:
      'bg-btn-primary-bg-hover text-btn-primary-text-hover border-btn-primary-border-hover',
  },
  [ButtonVariant.SECONDARY]: {
    [ButtonColor.WHITE]: 'border-white bg-white text-black',
    [ButtonColor.BLACK]: 'border-black bg-black text-white',
    [ButtonColor.YELLOW]: 'border-yellow bg-yellow text-black',
    [ButtonColor.PINK]: 'border-pink bg-pink text-black',
    [ButtonColor.RED]: 'border-red bg-red text-white',
    [ButtonColor.DEFAULT]:
      'bg-btn-secondary-bg-hover text-btn-secondary-text-hover border-btn-secondary-border-hover',
  },
  [ButtonVariant.LINK]: colorClasses[ButtonVariant.LINK],
}

export const getButtonVariant = (variant?: SanityContentBlockButtonVariant) => {
  if (!variant) {
    return
  }

  return variant as ButtonVariant
}

export const getButtonSize = (size?: SanityContentBlockButtonSize) => {
  if (!size) {
    return ButtonSize.DEFAULT
  }

  return size as ButtonSize
}

export const getButtonColor = (color?: SanityContentBlockButtonColor) => {
  if (!color) {
    return
  }

  return color as ButtonColor
}

export const getButtonIconAlignment = (
  iconAlignment?: SanityContentBlockButtonIconAlignment
) => {
  if (!iconAlignment) {
    return
  }

  return iconAlignment as ButtonIconAlignment
}

/**
 * Merge classes that match a given regular expression.
 * Example: `mergeClassesByRegExps('bg-one text-one bg-two', [/^bg-.+$/])` will return `'text-one bg-two'`.
 */
const mergeClassesByRegExps = (classes: string, groupRegExps: RegExp[]) => {
  const newClasses = classes.split(' ')
  const redundantClasses: string[] = []

  groupRegExps.forEach((groupRegExp) => {
    const filteredClasses = newClasses.filter((newClass) =>
      groupRegExp.test(newClass)
    )

    if (filteredClasses.length >= 2) {
      // Add matching classes to the redundant class list (except the last one)
      filteredClasses.slice(0, -1).forEach((filteredClass) => {
        redundantClasses.push(filteredClass)
      })
    }

    return
  })

  return newClasses
    .filter((newClass) => !redundantClasses.includes(newClass))
    .join(' ')
}

export const getButtonStyles = ({
  variant = ButtonVariant.LINK,
  size = ButtonSize.NORMAL,
  color = ButtonColor.DEFAULT,
  isActive,
}: ButtonProps) => {
  return mergeClassesByRegExps(
    cx(
      getBaseClasses(variant),
      colorClasses[variant][color],
      isActive ? activeClasses[variant][color] : '',
      variant !== ButtonVariant.LINK ? spacingClasses[size] : '',
      sizeClasses[size]
    ),
    [/^bg-.+$/]
  )
}

export interface ButtonIconProps {
  name: IconName
  alignment?: ButtonIconAlignment
  className?: string
}

export const ButtonIcon = ({
  name,
  alignment = ButtonIconAlignment.RIGHT,
  className,
}: ButtonIconProps) => {
  const id = useId()

  return (
    <span
      className={cx(
        {
          'order-first mr-3': alignment === 'left',
          'ml-4': alignment === 'right',
        },
        className
      )}
    >
      <Icon id={`button-icon-${id}`} name={name} />
    </span>
  )
}

const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      children,
      className,
      disabled,
      onClick,
      onBeforeInput,
      id,
      style,
      type,
      'aria-label': ariaLabel,
      variant,
      size,
      color,
      icon,
      iconSize,
      iconAlignment,
      isActive,
    }: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>,
    ref
  ) => {
    return (
      <button
        type={type}
        id={id}
        ref={ref}
        className={cx(
          getButtonStyles({ variant, size, color, isActive }),
          className
        )}
        onClick={onClick}
        disabled={disabled}
        style={style}
        onBeforeInput={onBeforeInput}
        aria-label={ariaLabel}
      >
        {children}
        {icon && (
          <ButtonIcon
            name={icon}
            alignment={iconAlignment}
            className={cx(iconSize ? iconSizeClasses[iconSize] : {})}
          />
        )}
      </button>
    )
  }
)

Button.displayName = 'Button'

export default Button
