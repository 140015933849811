import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { debounce } from 'throttle-debounce'

import { Locale } from './language'
import { ShopContext } from './shop'

export type Keyed<T> = { _key: string } & T

export interface ErrorMessages {
  [key: string]: string
}

/**
 * Debounced callback hook.
 */
export function useDebounceCallback(callback: any, delay: number) {
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(debounce(delay, callbackRef.current), [delay])
}

/**
 * Previous value hook.
 */
export function usePrevious<T>(value: T) {
  const previousValue = useRef<T>()

  useEffect(() => {
    previousValue.current = value
  }, [value])

  return previousValue.current
}

/**
 * Client-side mount hook.
 */
export function useHasMounted() {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  return hasMounted
}

/**
 * Local storage state variable hook.
 * Syncs state to local storage so that it persists through a page refresh.
 */
export function useLocalStorageState<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }

    try {
      // Read value from local storage
      const item = window.localStorage.getItem(key)

      if (item) {
        return JSON.parse(item)
      }
    } catch (error) {
      console.log(error)
    }

    return initialValue
  })

  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    (value) => {
      try {
        const newValue = value instanceof Function ? value(storedValue) : value

        setStoredValue(newValue)

        // Save value to local storage
        window.localStorage.setItem(key, JSON.stringify(newValue))
      } catch (error) {
        console.log(error)
      }
    },
    [key, storedValue]
  )

  return [storedValue, setValue] as const
}

/**
 * Checks if an object is found in another array of objects.
 */
export function hasObject(records?: any[], values?: any[] | any) {
  if (!records) {
    return false
  }

  return records.some((record) => {
    for (const key in record) {
      if (
        values &&
        key in values &&
        record[key] != (values as Record<string, unknown>)[key]
      ) {
        return false
      }
    }

    return true
  })
}

/**
 * Keeps a number within a range.
 */
export function clampRange(value: number, min = 0, max = 1) {
  return value < min ? min : value > max ? max : value
}

/**
 * Wraps a number around minimum and maximum value.
 */
export function wrap(value: number, length: number) {
  if (value < 0) {
    value = length + (value % length)
  }

  if (value >= length) {
    return value % length
  }

  return value
}

/**
 * Formats a value by adding thousands separators.
 */
const addThousandSeparators = (value: string, thousandSeparator: string) => {
  if (!thousandSeparator) {
    return value
  }

  return value.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
}

/**
 * Gets price from value in minor units and adds tax. Adds trailing zeros if needed.
 */
export const getPrice = (
  minorUnits: number,
  taxRate: number,
  hasTrailingZeros = false,
  thousandSeparator = ','
) => {
  const price = (minorUnits / 100) * (1 + taxRate)

  if (!hasTrailingZeros && price % 1 === 0) {
    return addThousandSeparators(`${price}`, thousandSeparator)
  }

  const parts = price.toFixed(2).split('.')
  parts[0] = addThousandSeparators(parts[0], thousandSeparator)

  return `${parts.join('.')}`
}

/**
 * Gets formatted price
 */
export const useGetFormattedPrice = () => {
  const { currency, taxRate } = useContext(ShopContext)

  return useCallback(
    (minorUnits: number) =>
      currency ? `${getPrice(minorUnits, taxRate)} ${currency}.` : '',
    [currency, taxRate]
  )
}

export const isBrowser = typeof window !== 'undefined'

/**
 * Window size hook that listens to resize event.
 */
export function useWindowSize() {
  function getSize() {
    return {
      width: isBrowser ? window.innerWidth : 0,
      height: isBrowser ? window.innerHeight : 0,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isBrowser) {
      return
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

/**
 * Parses optional page request parameter.
 */
export function parseOptionalParameter<T = string>(
  parameter?: T | T[] | null
): T | undefined {
  if (!parameter || (Array.isArray(parameter) && parameter.length === 0)) {
    return
  }

  return parseRequiredParameter<T>(parameter)
}

/**
 * Parses optional page request parameter array.
 */
export function parseOptionalParameters<T = string>(
  parameter?: T | T[] | null
): T[] | undefined {
  if (!parameter || (Array.isArray(parameter) && parameter.length === 0)) {
    return
  }

  return parseRequiredParameters<T>(parameter)
}

/**
 * Parses required page request parameter.
 */
export function parseRequiredParameter<T = string>(parameter: T | T[]): T {
  return Array.isArray(parameter) ? parameter[0] : parameter
}

/**
 * Parses required page request parameter array.
 */
export function parseRequiredParameters<T = string>(parameter: T | T[]): T[] {
  return Array.isArray(parameter) ? parameter : [parameter]
}

/**
 * Converts an enum into an object.
 */
export function enumToObject<T>(
  enumeration: T
): { [P in keyof T]: T[P] }[keyof T] {
  return enumeration as unknown as T[keyof T]
}

/**
 * Parses JSON string into an object.
 */
export const parseJson = (json: string): Record<string, unknown> => {
  try {
    return JSON.parse(json)
  } catch (_) {
    return {}
  }
}

/**
 * Compares numbers for sorting.
 */
export const compareNumbers = (number1: number, number2: number) =>
  number1 - number2

/**
 * Compares strings for sorting.
 */
export const compareStrings = (string1: string, string2: string) =>
  string1.localeCompare(string2)

/**
 * Filters duplicates from an array.
 */
export function filterDuplicates<T>(value: T, index: number, array: T[]) {
  return array.indexOf(value) === index
}

/**
 * Determines if 2 variables are equal using JSON representation.
 */
export const isEqual = (variable1: unknown, variable2: unknown) =>
  JSON.stringify(variable1) === JSON.stringify(variable2)

/**
 * Determines is user agent matches Apple Safari.
 */
export const isMobileSafari = () => {
  if (!isBrowser) {
    return false
  }

  return (
    !!navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
    !!navigator.userAgent.match(/AppleWebKit/)
  )
}

/**
 * Generates all combinations from multiple arrays.
 * E.g., getAllCombinations(['a', 'b'], ['1', '2']) returns [['a', '1'], ['a', '2'], ['b', '1'], ['b', '2']].
 */
export const getAllCombinations = (...arrays: string[][]): string[][] => {
  const initialValue: string[][] = [[]]

  return [...arrays].reduce(
    (resultArrays, array) =>
      resultArrays
        .map((resultArray) =>
          array.map((arrayValue) => resultArray.concat(arrayValue))
        )
        .reduce(
          (newResultArrays, arraysItem) => newResultArrays.concat(arraysItem),
          []
        ),
    initialValue
  )
}

/**
 * Gets formatted date by date string and locale.
 */
export const getFormattedDate = (
  date: string,
  locale: Locale,
  formatOptions?: Intl.DateTimeFormatOptions
) => {
  const dateTimeFormat = new Intl.DateTimeFormat(
    locale,
    formatOptions ?? {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
  )

  return dateTimeFormat.format(new Date(date))
}

/**
 * Gets the first existing value from an array of values.
 */
export function getFirstValue<T = string>(
  values: (T | undefined)[]
): T | undefined {
  return values.filter(Boolean)[0]
}

/**
 * Generates a random string of given length.
 */
export const getRandomString = (length = 8) => {
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

  return Array(length)
    .fill(null)
    .map(() => charset.charAt(Math.floor(Math.random() * charset.length)))
    .join('')
}

/**
 * Copies given text to the clipboard.
 */
export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text)

    return true
  } catch (error) {
    console.error(error)

    return false
  }
}
