const googleTagManager = (containerId, nonce) => {
  function a(w, d, s, l, i) {
    w[l] = w[l] || []
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : ''
    j.async = true
    j.defer = true
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
    j.setAttribute('nonce', nonce)
    f.parentNode.insertBefore(j, f)
  }
  a(window, document, 'script', 'dataLayer', containerId)
}

export default googleTagManager
